import React from "react";
import CalculationRow from "./CalculationChartRow";

const years = ["1 Year", "2 Years", "3 Years", "4 Years", "5 Years"]
const yearsForStartUp = ["Year 1", "Year 2", "Year 3", "Year 4", "Year 5"]
const statuses = ["Ok", "Require Approval", "Approval Given"]

const CalculationChart = (props) => {
    const handleChange = (value, name, year, tableName) => {
        props.handleChangeInPriceTable(value, name, year, tableName);
    }

    const grantApproval = (name, year, tableName, approval) => {
        props.grantApproval(name, year, tableName, approval);
    }

    return (
        <>
            <div className="2xl:m-4 lg:m-0">
                <div className="grid grid-cols-1 gap-2">
                    <label className="text-lg font-bold  text-[#757575]">{props.title}</label>
                </div>
                <div className="2xl:pr-24 lg:pr-0 pr-0 inputs-lists">
                    <div className="grid grid-cols-6 gap-4 2xl:text-base lg:text-[13px] text-[#757575] pt-4  tbl-th-styling">
                        <label></label>

                        <label>Entrance Fee</label>
                        <label>Annual Fee</label>
                        <label>Included Industry Group</label>
                        <label>Price of New Industry Group</label>
                    </div>
                    {(props?.tableData && props.tableData.map((value, index) => {
                        // if(value.tableName === 'SystemCalculatedTable'){
                        // }
                        return (
                            <React.Fragment key={index}>
                            <CalculationRow
                                keyId={props.title + index}
                                rowTitle={props.membershipTypeId === 3 ? yearsForStartUp[index] : years[index]}
                                yearIndex={index}
                                tableName={value.tableName}
                                disabled={props.disabled}
                                showButtons={props.showButtons}
                                status={value.status ? value.status : null}
                                showStatus={props.showStatus}
                                feeApprove={"RequiredAprroval"}
                                entranceFee={value.entranceFee}
                                annualFee={value.annualFee}
                                includedChapter={value.includedChapter}
                                newChapterPrice={value.newChapterPrice}
                                handleChangeInPriceTable={(value, name, year, tableName) => handleChange(value, name, year, tableName)}
                                grantApproval={(name, year, tableName, approval) => grantApproval(name, year, tableName, approval)}
                                isDeleted={value.isDeleted === true}
                                data={value}
                            />
                            </React.Fragment>
                        )
                    }))}
                    {/*<CalculationRow
                        rowTitle="1st Year"
                        disabled={props.disabled}
                        status={props.status1}
                        feeApprove={props.feeApprove}
                    />
                    <CalculationRow
                        rowTitle="2nd Year"
                        disabled={props.disabled}
                        status={props.status2}
                        feeApprove={props.feeApprove}
                    />
                    <CalculationRow
                        rowTitle="3rd Year"
                        disabled={props.disabled}
                        status={props.status3}
                        feeApprove={props.feeApprove}
                    />
                    <CalculationRow
                        rowTitle="4th Year"
                        disabled={props.disabled}
                        status={props.status4}
                        feeApprove={props.feeApprove}
                    />
                    <CalculationRow
                        rowTitle="5th Year"
                        disabled={props.disabled}
                        status={props.status5}
                        feeApprove={props.feeApprove}
                    /> */}
                </div>
            </div>
        </>
    );
}
export default CalculationChart;