import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MobileBannerPreview from '../components/bannerManagement/MobileBannerPreview';
import ForgetPassword from '../pages/authentication/ForgetPassword';
import Login from '../pages/authentication/Login'
import ResetPassword from '../pages/authentication/ResetPassword';
import UnAuthorized from '../pages/authentication/UnAuthorized';
import Layout from '../shared/Layout'

const LayoutNavigation = (props) => {

    

    return (
        <Router>
            <React.Suspense fallback={null}>
                <Routes>
                    <Route exact name="Login" path="/Login" element={<Login authDetails={props.authDetails} />} />
                    {
                        (window.location.pathname.toLocaleLowerCase() !== "/login" && window.location.pathname.toLocaleLowerCase() !== "/resetpassword" && window.location.pathname.toLocaleLowerCase() !== "/forgetpassword" && window.location.pathname.toLocaleLowerCase() !== "/") ?
                            <>
                                <Route name="Layout" path="/" element={window.location.href === "http://localhost:3000/BannerPreview/" ? <MobileBannerPreview key={'MobileLayout'} /> : <Layout key={'Layout'} componentRoutes={props.componentRoutes} />} >
                                    {
                                        (props.authDetails?.roleId && props.authDetails.roleId > 0) ?
                                            <>
                                                {props.componentRoutes && props?.componentRoutes.map((route, idx) => {
                                                    return (
                                                        <React.Fragment key={idx}>
                                                            {
                                                                (route.roles.indexOf(props.authDetails.roleId) !== -1 ?
                                                                    <>

                                                                        {route.component && (
                                                                            <Route
                                                                                key={route.title}
                                                                                path={route.path}
                                                                                element={<route.component authDetails={route.authDetails} route={Route}  {...props} />} />
                                                                        )}
                                                                    </>
                                                                    : <>
                                                                        {route.component && (
                                                                            <Route
                                                                                key={idx}
                                                                                path={'authentication/UnAuthorized'}
                                                                                element={<route.component authDetails={route.authDetails} route={Route}  {...props} />} />
                                                                        )
                                                                        }
                                                                    </>)
                                                            }
                                                        </React.Fragment>)
                                                    // route.component && (
                                                    //     <Route
                                                    //         key={idx}
                                                    //         path={route.path}
                                                    //         element={<route.component authDetails={route.authDetails} route={Route}  {...props} />} />
                                                    // )
                                                })}
                                            </>
                                            :
                                            <>
                                                <Route exact name="Login" path="/Login" element={<Login authDetails={props.authDetails} />} />
                                                {/* {redirectToLogin()} */}
                                            </>
                                    }
                                </Route>
                            </>
                            :
                            <>
                                <Route exact name="Login" path="/Login" element={<Login authDetails={props.authDetails} />} />
                                {/* {redirectToLogin()} */}
                            </>
                    }

                    <Route exact name="ResetPassword" path="/ResetPassword" element={<ResetPassword authDetails={props.authDetails} />} />
                    <Route exact name="ForgetPassword" path="/ForgetPassword" element={<ForgetPassword authDetails={props.authDetails} />} />
                    {(props.authDetails?.roleId && props.authDetails.roleId > 0) ?
                        <Route path='*' exact={true} element={<UnAuthorized authDetails={props.authDetails} />} />
                        : null}
                </Routes>
            </React.Suspense>
        </Router>
    )
}

export default LayoutNavigation;