import React from "react";
import moment from "moment";

function Fees(props) {
  return (
    <div>
      <div className="grid gap-8 py-4 grid-cols-1">
        <div className="relative justify-center detailedBlock p-4 pt-8 fees">
          <div className="flex flex-row items-center justify-between title pl-24">
            <div className="icon">Fees Collected</div>
          </div>
          <div className="text-[20px] pl-24">{`Jan ${moment(props.dashboardDetails.currentDate).format("YYYY")} - Dec ${moment(props.dashboardDetails.currentDate).format("YYYY")}`}</div>
          <div className="grid gap-0 grid-cols-5 items-center text-center py-4 border-b border-[#606060] pl-24">
            <div className="inner-number col-span-2 text-left text-[20px]">
              Membership Fee
            </div>
            <div className="py-2 border-[#606060] flex justify-center align-center relative">
              <div className="border-l border-[#606060] h-[40px] absolute left-0 top-[50%]  translate-y-[-50%]"></div>
              <div>
                <div className="inner-number">{`$${props.dashboardDetails.membershipOrdinaryFee}`}</div>
                <div>Ordinary Member</div>
              </div>
            </div>
            <div className="py-2 border-[#606060] flex justify-center align-center relative">
              <div className="border-l border-[#606060] h-[40px] absolute left-0 top-[50%] translate-y-[-50%]"></div>
              <div>
                <div className="inner-number">{`$${props.dashboardDetails.membershipAssociateFee}`}</div>
                <div>Associate Member</div>
              </div>
            </div>
            <div className="py-2 border-[#606060] flex justify-center align-center relative">
              <div className="border-l border-[#606060] h-[40px] absolute left-0 top-[50%] translate-y-[-50%]"></div>
              <div>
                <div className="inner-number">{`$ ${props.dashboardDetails.membershipTotalAmount}`}</div>
                <div>Total Amount</div>
              </div>
            </div>
          </div>
          <div className="grid gap-0 grid-cols-5 items-center text-bold text-center py-4 border-b border-[#606060] pl-24">
            <div className="col-span-2 text-left space-y-4 ">
              <div className="inner-number text-[20px]"> Sponsorship</div>
              
            </div>
            <div className="py-2 border-[#606060] flex justify-center align-center relative">
              <div className="border-l border-[#606060] h-[40px] absolute left-0 top-[50%]  translate-y-[-50%]"></div>
              <div>
                <div className="inner-number">{`$${props.dashboardDetails.sponsorshipIndividualFee}`}</div>
                <div>Individual</div>
              </div>
            </div>
            <div className="py-2 border-[#606060] flex justify-center align-center relative">
              <div className="border-l border-[#606060] h-[40px] absolute left-0 top-[50%] translate-y-[-50%]"></div>
              <div>
                <div className="inner-number">{`$${props.dashboardDetails.sponsorshipCorporateFee}`}</div>
                <div>Corporate</div>
              </div>
            </div>
            <div className="py-2 border-[#606060] flex justify-center align-center relative">
              <div className="border-l border-[#606060] h-[40px] absolute left-0 top-[50%] translate-y-[-50%]"></div>
              <div>
                <div className="inner-number">{`$${props.dashboardDetails.sponsorshipTotalAmount}`}</div>
                <div>Total Amount</div>
              </div>
            </div>
          </div>
          {props.dashboardDetails.adminDashboardEventDetailList && props.dashboardDetails.adminDashboardEventDetailList.length > 0 ?

            <>
              {props.dashboardDetails.adminDashboardEventDetailList.map((event, key) => {
                return (
                  <div className="grid gap-0 grid-cols-5 items-center text-center py-4 border-b border-[#606060] pl-24" key={key}>
                    <div className="col-span-2 text-left space-y-4 ">
                      {key === 0 ? <div className="inner-number text-[20px]">Events</div> : null}
                      <div>{event.eventName}</div>
                    </div>
                    <div className="py-2 border-[#606060] flex justify-center align-center relative">
                      <div className="border-l border-[#606060] h-[40px] absolute left-0 top-[50%]  translate-y-[-50%]"></div>
                      <div>
                        <div className="inner-number">{event.pendingPaymentBooking}</div>
                        <div>Pending Payment</div>
                      </div>
                    </div>
                    <div className="py-2 border-[#606060] flex justify-center align-center relative">
                      <div className="border-l border-[#606060] h-[40px] absolute left-0 top-[50%] translate-y-[-50%]"></div>
                      <div>
                        <div className="inner-number">{event.paidBooking}</div>
                        <div>Paid</div>
                      </div>
                    </div>
                    <div className="py-2 border-[#606060] flex justify-center align-center relative">
                      <div className="border-l border-[#606060] h-[40px] absolute left-0 top-[50%] translate-y-[-50%]"></div>
                      <div>
                        <div className="inner-number">{`$${event.bookingTotalPaidAmount}`}</div>
                        <div>Total Amount</div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </> : null}

        </div>
      </div>
    </div>
  );
}

export default Fees;
