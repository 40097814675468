import React from "react";
import DropdownSelect from "../../../dropdown/Dropdown";
import TextInput from "../../../UIComponent/TextInput";
import moment from "moment";
import Validation from "../../../../utils/validation/ValidationText";
import Selector from "../../../dropdown/commonDropdown/Selector";
import ButtonLoader from "../../../common/buttonLoader/ButtonLoader";
import { APIURL } from "../../../../services/axiosServices/ApiEndPoints";

function OrganisationBasicInformations(props) {

  function subtractYears(numOfYears, date = new Date()) {
    date.setFullYear(date.getFullYear() - numOfYears);
    return date;
  }

  return (
    <div className="w-full">
      <div className="2xl:px-10 2xl:py-10 2xl:pt-7 lg:px-7 lg:py-7 lg:pt-5">
        <div className="grid grid-cols-12 gap-6 w-full ">

          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Company UEN/Business Registration No.{" "}<span className="text-[#c00000]">*</span>
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <input
                  // className={`w-full outline-none appearance-none ${props.disableUenNumber ? "bg-[#f2f2f2]" : null}`}
                  className={`w-full outline-none appearance-none ${props.organizationBasicInformation.organisationInformationId > 0 ? 'bg-[#f2f2f2]' : null}`}
                  placeholder=""
                  type="text"
                  name="companyUEN"
                  identity="BasicInformation"
                  value={props.organizationBasicInformation.companyUEN}
                  onChange={(event) => props.handleChange(event, "BasicInformation")}
                  onBlur={(e) => props.validate("companyUEN", props.organizationBasicInformation)}
                  disabled={props.organizationBasicInformation.organisationInformationId > 0}
                  autoComplete={"off"}
                />
              </div>
            </div>
            <Validation error={props.validState.error.companyUEN} />
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Company Name <span className="text-[#c00000]">*</span>
                </h2>
                <div className="dropdwn-input flex items-center">
                  <TextInput
                    className={`${props.organizationBasicInformation.organisationInformationId > 0 ? 'bg-[#f2f2f2]' : null}`}
                    placeholder=""
                    type="text"
                    name="companyName"
                    value={props.organizationBasicInformation.companyName}
                    identity="BasicInformation"
                    handleChange={props.handleChange}
                    onBlur={(e) => props.validate("companyName", props.organizationBasicInformation)}
                    disabled={props.organizationBasicInformation.organisationInformationId > 0}
                    autoComplete={"off"}
                  />
                </div>
                <Validation error={props.validState.error.companyName} />
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Incorporation Date<span className="text-[#c00000]">*</span>
                </h2>
                <input
                  className="date-pickers w-full custom-input "
                  type="date"
                  name="incorporationDate"
                  identity="BasicInformation"
                  max={moment(new Date()).format("yyyy-MM-DD")}
                  // min={props.membershipSubscription.membershipTypeId === 3 ? subtractYears(4, new Date()) : null}
                  value={moment(props.organizationBasicInformation.incorporationDate).format("yyyy-MM-DD")}
                  onChange={(e) => props.handleChange(e, "BasicInformation")}
                  onBlur={(e) =>
                    props.validate(
                      "incorporationDate",
                      props.organizationBasicInformation
                    )
                  }
                ></input>
                <Validation error={props.validState.error.incorporationDate} />
              </div>
            </div>
          </div>
          {/* <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Job Function <span className="text-[#c00000]">*</span>
                </h2>
                <Selector
                  drpIdentity="Designation_BasicInfo"
                  options={props.designationList}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  onChange={props.handleChangeMultiDropdown}
                  allowSelectAll={true}
                  value={
                    props.organizationBasicInformation.selectedDesignation
                  }
                  disabled={props.organizationBasicInformation.organisationInformationId > 0}
                />
              </div>
            </div>
            <Validation error={props.validState.error.selectedDesignation} />
          </div>
          <div className="input-sec-texts 2xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="flield-sec">
              <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                Job Level <span className="text-[#c00000]">*</span>
              </h2>
              <DropdownSelect
                drpIdentity={"JobLevel"}
                optionArray={props.jobLevel}
                setFilterParameters={props.setFilterParameters}
                value={props.organizationBasicInformation.jobLevelId}
                disabled={props.organizationBasicInformation.organisationInformationId > 0}
              />
            </div>
            <Validation error={props.validState.error.jobLevelId} />
          </div> */}
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Attention To First Name
                  <span className="text-[#c00000]">*</span>
                </h2>

                <div className="flex items-center left-border-none">

                  <div className="w-[130px]">
                    <DropdownSelect
                      drpIdentity={"NamePrefix"}
                      optionArray={props.mrMrs}
                      setFilterParameters={props.setFilterParameters}
                      value={props.organizationBasicInformation.mrMrsId}
                    //disabled={props.organizationBasicInformation.organisationInformationId > 0 ? true : false}
                    />
                  </div>
                  <TextInput
                    placeholder=""
                    type="text"
                    name="firstName"
                    value={props.organizationBasicInformation.firstName}
                    identity="BasicInformation"
                    handleChange={props.handleChange}
                    onBlur={(e) =>
                      props.validate(
                        "firstName",
                        props.organizationBasicInformation
                      )
                    }
                  //disabled={props.organizationBasicInformation.organisationInformationId > 0 ? true : false}
                  //className={props.organizationBasicInformation.organisationInformationId > 0 ? `bg-[#f2f2f2]` : ""}
                  />
                </div>



                {/* <div className="dropdwn-input-email flex items-center">
                  <div className="w-[130px]">
                    <DropdownSelect
                      drpIdentity={"NamePrefix"}
                      optionArray={props.mrMrs}
                      setFilterParameters={props.setFilterParameters}
                      value={props.organizationBasicInformation.mrMrsId}
                    />

                  </div>
                  <TextInput
                    placeholder=""
                    type="text"
                    name="firstName"
                    value={props.organizationBasicInformation.firstName}
                    identity="BasicInformation"
                    handleChange={props.handleChange}
                    onBlur={(e) =>
                      props.validate(
                        "firstName",
                        props.organizationBasicInformation
                      )
                    }
                  />
                </div> */}
                <div>
                  <Validation error={props.validState.error.mrMrsId} />
                  <Validation error={props.validState.error.firstName} />
                </div>
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl theme-color font-bold 2xl:mb-1">
                  Attention To Last Name{" "}
                  <span className="text-[#c00000]">*</span>
                </h2>
                <TextInput
                  placeholder=""
                  type="text"
                  name="lastName"
                  value={props.organizationBasicInformation.lastName}
                  identity="BasicInformation"
                  handleChange={props.handleChange}
                  onBlur={(e) =>
                    props.validate(
                      "lastName",
                      props.organizationBasicInformation
                    )
                  }
                //disabled={props.organizationBasicInformation.organisationInformationId > 0 ? true : false}
                //className={props.organizationBasicInformation.organisationInformationId > 0 ? `bg-[#f2f2f2]` : ""}
                />
              </div>
            </div>
            <Validation error={props.validState.error.lastName} />
          </div>


          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl theme-color font-bold 2xl:mb-1">
                  Designation
                  <span className="text-[#c00000]">*</span>
                </h2>
                <TextInput
                  placeholder=""
                  type="text"
                  name="employmentTitile"
                  value={props.organizationBasicInformation.employmentTitile}
                  identity="BasicInformation"
                  handleChange={props.handleChange}
                  onBlur={(e) =>
                    props.validate(
                      "employmentTitile",
                      props.organizationBasicInformation
                    )
                  }
                />
              </div>
            </div>
            <Validation error={props.validState.error.employmentTitile} />
          </div>

          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Email Address <span className="text-[#c00000]">*</span>
                </h2>
                <TextInput
                  placeholder=""
                  type="email"
                  name="email"
                  value={props.organizationBasicInformation.email}
                  identity="BasicInformation"
                  handleChange={props.handleChange}
                  onBlur={(e) => props.validate("email", props.organizationBasicInformation)}
                  disabled={props.organizationBasicInformation.organisationInformationId > 0}
                  className={props.organizationBasicInformation.organisationInformationId > 0 ? `bg-[#f2f2f2]` : ""}
                />
              </div>
            </div>
            <Validation error={props.validState.error.email} />
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Mobile Number
                  <span className="text-[#c00000]">*</span>
                </h2>
                <TextInput
                  placeholder=""
                  type="text"
                  name="contactNo"
                  maxLength={20}
                  value={props.organizationBasicInformation.contactNo}
                  identity="BasicInformation"
                  handleChange={props.handleChange}
                  onBlur={(e) => props.validate("contactNo", props.organizationBasicInformation)}
                  autoComplete={"off"}
                  disabled={props.organizationBasicInformation.organisationInformationId > 0}
                  className={props.organizationBasicInformation.organisationInformationId > 0 ? `bg-[#f2f2f2]` : ""}
                />
              </div>
            </div>
            <Validation error={props.validState.error.contactNo} />
          </div>

          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  ACRA <span className="text-[#c00000]">*</span>
                </h2>
                <form className="custom-uploader custom-file-upload-btn flex justify-center items-center text-center btn btn-red-border w-min">
                  <label
                    htmlFor={`upload_File`}
                    className="text-uplod block text-sm text-[#313984]"
                  >
                    Upload File
                  </label>
                  <input
                    className="sr-only"
                    // key={`FileQuestion${key}`}
                    id={`upload_File`}
                    type="file"
                    onChange={(event) => props.onFileChange(event)}
                  />
                </form>
                <a className="text-[#313984] big-bullet-points font-bold mb-5 inline-block mobile-14"
                  onClick={() => window.open(APIURL + "Common/GetPDF?type=acra&&fileName=" + props.organizationBasicInformation.acraCertificatePath, '_blank')}
                >
                  {props.organizationBasicInformation.acraCertificatePath}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Save Button */}
      <div className="grid grid-cols-12 gap-6 pb-10">
        <div className="xl:col-span-12 lg:col-span-12 col-span-12 px-10">
          <a
            className="flex items-center justify-end"
          //href="/CreateCorporateMembers"
          >
            {
              props && props.isLoading === true ?
                (
                  <ButtonLoader />
                )
                :
                (
                  <button
                    className="w-32 px-7 py-3 btn-sm btn-red text-xl text-white font-semibold"
                    onClick={() => props.onSave()}
                  >
                    Save
                  </button>
                )
            }
          </a>
        </div>
      </div>
    </div>
  );
}

export default OrganisationBasicInformations;
