import moment from "moment";
import React from "react";
import TextInput from "../../../components/UIComponent/TextInput";
import Validation from "../../../utils/validation/ValidationText";
import ButtonLoader from "../../common/buttonLoader/ButtonLoader";
import DatePicker from "../../datePicker/DatePicker";
import DropdownSelect from "../../dropdown/Dropdown";
import MultiSelectDropdown from "../../dropdown/MultiSelectDropdown";
import Option from "../../dropdown/multiSelectDropdownOption/MultiSelectOption";
import { APIURL } from "../../../services/axiosServices/ApiEndPoints";

function OrganisationBasicInformation(props) {
  return (
    <div>
      <div className="w-full">
        <div className="2xl:px-10 2xl:py-10 2xl:pt-7 lg:px-7 lg:py-7 lg:pt-5">
          <div className="grid grid-cols-12 gap-6 w-full ">
            {/* <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                    Target Market
                  </h2>

                  <MultiSelectDropdown
                    drpIdentity="TargetMarket"
                    options={props.targetMarket}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{ Option, }}
                    onChange={props.handleChangeMultiDropdown}
                    allowSelectAll={true}
                    value={props.organizationBusinessDetails.selectedTargetMarketArr}
                  />
                  <Validation
                    error={props.validState.error.selectedTargetMarketArr}
                  />
                </div>
              </div>
            </div> */}

            {/* <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                    Authorized Cap
                  </h2>
                  <div className="flex items-center">
                    <div className="w-full outline-none appearance-none">
                      <DropdownSelect
                        drpIdentity={"AuthorizedCap"}
                        optionArray={props.authorizedCaps}
                        setFilterParameters={props.setFilterParameters}
                        value={props.organizationBusinessDetails.authorizedCapId}
                      />
                    </div>
                    <Validation
                      error={props.validState.error.authorizedCapId}
                    />
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                    Share Holding Local
                    <span className="text-[#c00000]">*</span>
                  </h2>
                  <div className="flex items-center">
                    <div className="w-full outline-none appearance-none">
                      <DropdownSelect
                        drpIdentity={"ShareHoldingLocal"}
                        optionArray={props.shareHoldingLocals}
                        setFilterParameters={props.setFilterParameters}
                        value={props.organizationBusinessDetails.shareHoldingLocalId}
                      onBlur={(e) =>
                        props.validate(
                          "corporateMemberRecordTypeId",
                          props.corporateSIISAccount
                        )
                      }
                      />
                    </div>
                    <Validation
                      error={props.validState.error.shareHoldingLocalId}
                    />
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                    Rev From Outside SG
                    <span className="text-[#c00000]">*</span>
                  </h2>
                  <div className="flex items-center">
                    <div className="w-full outline-none appearance-none">
                      <DropdownSelect
                        drpIdentity={"RevFromOutsideSG"}
                        optionArray={props.revFromOutsideSG}
                        setFilterParameters={props.setFilterParameters}
                        value={props.organizationBusinessDetails.revFromOutsideSGId}
                      onBlur={(e) =>
                        props.validate(
                          "corporateMemberRecordTypeId",
                          props.corporateSIISAccount
                        )
                      }
                      />
                    </div>
                    <Validation
                      error={props.validState.error.revFromOutsideSGId}
                    />
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                    Main Business <span className="text-[#c00000]">*</span>
                  </h2>
                  <div className="flex items-center">
                    <div className="w-full outline-none appearance-none">
                      <DropdownSelect
                        drpIdentity={"mainBusinesses"}
                        optionArray={props.mainBusinesses}
                        setFilterParameters={props.setFilterParameters}
                        value={props.organizationBusinessDetails.mainBusinessId}
                      />
                    </div>
                  </div>
                  <Validation error={props.validState.error.mainBusinessId} />
                </div>
                {props.organizationBusinessDetails.mainBusinessId === 16 ?
                  <div className="2xl:col-span-6 lg:col-span-6 col-span-12 ">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                        <h2 className="2xl:text-xl lg:text-md text-[#000000] font-bold 2xl:mb-3">
                          Others
                        </h2>
                        <TextInput
                          placeholder=""
                          type="text"
                          name="othermainBusiness"
                          value={props.organizationBusinessDetails.othermainBusiness}
                          handleChange={props.handleChange}
                          identity="othermainBusiness"
                        // validateField={props.validateField}
                        // validState={props.validState}
                        // disabled={props.isOrganisationModalEditable === true ? false : true}
                        />

                         <ValidationText error={props.validState.error.othercompanyBusinessFunctions} /> 
                      </div>
                    </div>
                  </div>
                  : null}
              </div>
            </div> */}
            <div className="input-sec-texts 2xl:col-span-6 lg:col-span-6 col-span-12">
              <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                Main Business  <span className="text-[#c00000]">*</span>
              </h2>
              <div className="lg:flex sm:flex md:flex block items-end gap-3 w-full">
                <div className={`flield-sec ${props.organizationBusinessDetails.mainBusinessId && props.mainBusinesses.find((x) => x.id === props.organizationBusinessDetails.mainBusinessId).name.includes('Other')? "2xl:w-[35%] w-[100%]" : "2xl:w-full w-full"} `}>
                  <DropdownSelect
                    drpIdentity={"mainBusinesses"}
                    optionArray={props.mainBusinesses}
                    setFilterParameters={props.setFilterParameters}
                    value={props.organizationBusinessDetails.mainBusinessId}
                  />
                </div>
                {
                // props.organizationBusinessDetails.mainBusinessId === 16
                props.organizationBusinessDetails.mainBusinessId && props.mainBusinesses.find((x) => x.id === props.organizationBusinessDetails.mainBusinessId).name.includes('Other')
                 ?
                  <div className="flield-sec 2xl:w-[65%] w-[100%]">
                    <TextInput
                      placeholder=""
                      type="text"
                      name="othermainBusiness"
                      value={props.organizationBusinessDetails.othermainBusiness}
                      handleChange={props.handleChange}
                      identity="othermainBusiness"
                    // validateField={props.validateField}
                    // validState={props.validState}
                    // disabled={props.isOrganisationModalEditable === true ? false : true}
                    />
                  </div>
                  : null}
              </div>
              <Validation error={props.validState.error.mainBusinessId} />
                  <Validation error={props.validState.error.othermainBusiness} />
              {/* <ValidationText error={props.validState.error.jobLevelId} />
              <ValidationText error={props.validState.error.otherJobLevel} /> */}
            </div>
            {/* <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                  <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                    Type of Company <span className="text-[#c00000]">*</span>
                  </h2>
                  <div className="flex items-center">
                    <div className="w-full outline-none appearance-none">
                      <DropdownSelect
                        drpIdentity={"typeOfcompanies"}
                        optionArray={props.typeOfcompanies}
                        setFilterParameters={props.setFilterParameters}
                        value={props.organizationBusinessDetails.typeOfcompanyId}
                      />
                    </div>
                  </div>
                  <Validation error={props.validState.error.typeOfcompanyId} />
                </div>
                {props.organizationBusinessDetails.typeOfcompanyId === 8 ?
                  <div className="2xl:col-span-6 lg:col-span-6 col-span-12 ">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                        <h2 className="2xl:text-xl lg:text-md text-[#000000] font-bold 2xl:mb-3">
                          Others
                        </h2>
                        <TextInput
                          placeholder=""
                          type="text"
                          name="othertypeOfcompany"
                          value={props.organizationBusinessDetails.othertypeOfcompany}
                          handleChange={props.handleChange}
                          identity="othertypeOfcompany"
                        // validateField={props.validateField}
                        // validState={props.validState}
                        // disabled={props.isOrganisationModalEditable === true ? false : true}
                        />

                         <ValidationText error={props.validState.error.othercompanyBusinessFunctions} /> 
                      </div>
                    </div>
                  </div>
                  : null}
              </div>
            </div> */}
            <div className="input-sec-texts 2xl:col-span-6 lg:col-span-6 col-span-12">
              <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                Type of Company  <span className="text-[#c00000]">*</span>
              </h2>
              <div className="lg:flex sm:flex md:flex block items-end gap-3 w-full">
                <div className={`flield-sec ${props.organizationBusinessDetails.typeOfcompanyId && props.typeOfcompanies.find((x) => x.id === props.organizationBusinessDetails.typeOfcompanyId).name.includes('Other') ? "2xl:w-[35%] w-[100%]" : "2xl:w-full w-full"} `}>
                  <DropdownSelect
                    drpIdentity={"typeOfcompanies"}
                    optionArray={props.typeOfcompanies}
                    setFilterParameters={props.setFilterParameters}
                    value={props.organizationBusinessDetails.typeOfcompanyId}
                  />
                </div>
                {
                // props.organizationBusinessDetails.typeOfcompanyId === 8
                props.organizationBusinessDetails.typeOfcompanyId && props.typeOfcompanies.find((x) => x.id === props.organizationBusinessDetails.typeOfcompanyId).name.includes('Other') ?
                  <div className="flield-sec 2xl:w-[65%] w-[100%]">
                    <TextInput
                      placeholder=""
                      type="text"
                      name="othertypeOfcompany"
                      value={props.organizationBusinessDetails.othertypeOfcompany}
                      handleChange={props.handleChange}
                      identity="othertypeOfcompany"
                    // validateField={props.validateField}
                    // validState={props.validState}
                    // disabled={props.isOrganisationModalEditable === true ? false : true}
                    />

                  </div>
                  : null}
              </div>
              <Validation error={props.validState.error.typeOfcompanyId} />
                  <Validation error={props.validState.error.othertypeOfcompany} />
              {/* <ValidationText error={props.validState.error.othercompanyBusinessFunctions} />  */}
            </div>
            {/* <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                  <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                    Company Business Function in Singapore <span className="text-[#c00000]">*</span>
                  </h2>
                  <div className="flex items-center">
                    <div className="w-full outline-none appearance-none">
                      <DropdownSelect
                        drpIdentity={"companyBusinessFunctions"}
                        optionArray={props.companyBusinessFunctions}
                        setFilterParameters={props.setFilterParameters}
                        value={props.organizationBusinessDetails.companyBusinessFunctionId}
                      />
                    </div>
                  </div>
                  <Validation error={props.validState.error.companyBusinessFunctionId} />
                </div>
                {props.organizationBusinessDetails.companyBusinessFunctionId === 13 ?
                  <div className="2xl:col-span-6 lg:col-span-6 col-span-12 ">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                        <h2 className="2xl:text-xl lg:text-md text-[#000000] font-bold 2xl:mb-3">
                          Others
                        </h2>
                        <TextInput
                          placeholder=""
                          type="text"
                          name="othercompanyBusinessFunctions"
                          value={props.organizationBusinessDetails.othercompanyBusinessFunctions}
                          handleChange={props.handleChange}
                          identity="othercompanyBusiness"
                        // validateField={props.validateField}
                        // validState={props.validState}
                        // disabled={props.isOrganisationModalEditable === true ? false : true}
                        />

                        <ValidationText error={props.validState.error.othercompanyBusinessFunctions} /> 
                      </div>
                    </div>
                  </div>
                  : null}
              </div>
            </div> */}

            <div className="input-sec-texts 2xl:col-span-6 lg:col-span-6 col-span-12">
              <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                Company Business Function in Singapore <span className="text-[#c00000]">*</span>
              </h2>
              <div className="lg:flex sm:flex md:flex block items-end gap-3 w-full">
                <div className={`flield-sec ${props.organizationBusinessDetails.companyBusinessFunctionId && props.companyBusinessFunctions.find((x) => x.id === props.organizationBusinessDetails.companyBusinessFunctionId).name.includes('Other') ? "2xl:w-[35%] w-[100%]" : "2xl:w-full w-full"} `}>
                  <DropdownSelect
                    drpIdentity={"companyBusinessFunctions"}
                    optionArray={props.companyBusinessFunctions}
                    setFilterParameters={props.setFilterParameters}
                    value={props.organizationBusinessDetails.companyBusinessFunctionId}
                  />
                </div>
                {
                // props.organizationBusinessDetails.companyBusinessFunctionId === 13 
                props.organizationBusinessDetails.companyBusinessFunctionId && props.companyBusinessFunctions.find((x) => x.id === props.organizationBusinessDetails.companyBusinessFunctionId).name.includes('Other')
                ?
                  <div className="flield-sec 2xl:w-[65%] w-[100%]">
                    <TextInput
                      placeholder=""
                      type="text"
                      name="othercompanyBusinessFunctions"
                      value={props.organizationBusinessDetails.othercompanyBusinessFunctions}
                      handleChange={props.handleChange}
                      identity="othercompanyBusiness"
                    // validateField={props.validateField}
                    // validState={props.validState}
                    // disabled={props.isOrganisationModalEditable === true ? false : true}
                    />
                  </div>
                  : null}
              </div>
              <Validation error={props.validState.error.companyBusinessFunctionId} />
                  <Validation error={props.validState.error.othercompanyBusinessFunctions} />
              {/* <Validation error={props.validState.error.companyBusinessFunctionId} /> */}
              {/* <ValidationText error={props.validState.error.othercompanyBusinessFunctions} /> */}
            </div>

            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                  <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                    Which countries do you currently have an overseas Presence in?
                  </h2>
                  <div className="flex items-center">
                    <div className="w-full outline-none appearance-none">
                      <MultiSelectDropdown
                        drpIdentity="overseasPresenceCountries"
                        options={props.countriesWithPresence}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{ Option }}
                        onChange={props.handleChangeMultiDropdown}
                        allowSelectAll={true}
                        value={props.organizationBusinessDetails.selectedOverseasPresenceCountries}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                  <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                    Where is your Global Headquarters, if applicable
                  </h2>
                  <div className="flex items-center">
                    <div className="w-full outline-none appearance-none">
                      <DropdownSelect
                        drpIdentity={"globalHeadQuartersCountry"}
                        optionArray={props.globalHeadQuartersCountry}
                        setFilterParameters={props.setFilterParameters}
                        value={props.organizationBusinessDetails.globalHeadQuartersCountryId}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                  <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                    Where is your Regional Headquarters, if applicable
                  </h2>
                  <div className="flex items-center">
                    <div className="w-full outline-none appearance-none">
                      <MultiSelectDropdown
                        drpIdentity="regionalHeadquarters"
                        options={props.countriesWithPresence}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{ Option }}
                        onChange={props.handleChangeMultiDropdown}
                        allowSelectAll={true}
                        value={props.organizationBusinessDetails.selectedRegionalHeadquarters}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                  <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                    Where is your Manufacturing Facilities, if applicable
                  </h2>
                  <div className="flex items-center">
                    <div className="w-full outline-none appearance-none">
                      <MultiSelectDropdown
                        drpIdentity="manufacturingFacilities"
                        options={props.countriesWithPresence}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{ Option }}
                        onChange={props.handleChangeMultiDropdown}
                        allowSelectAll={true}
                        value={props.organizationBusinessDetails.selectedManufacturingFacilities}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                  <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                    Importing/Exporting Countries
                  </h2>
                  <div className="flex items-center">
                    <div className="w-full outline-none appearance-none">
                      <MultiSelectDropdown
                        drpIdentity="importingExportingCountries"
                        options={props.countriesWithPresence}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{ Option }}
                        onChange={props.handleChangeMultiDropdown}
                        allowSelectAll={true}
                        value={props.organizationBusinessDetails.selectedImportingExportingCountries}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                  <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                    Are your products targeted for B2B or B2C?
                  </h2>
                  <div className="flex items-center">
                    <div className="w-full outline-none appearance-none">
                      <DropdownSelect
                        drpIdentity={"productsTarget"}
                        optionArray={props.productsTarget}
                        setFilterParameters={props.setFilterParameters}
                        value={props.organizationBusinessDetails.productsTargetedId}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                    Paid-Up Capital
                    <span className="text-[#c00000]">*</span>
                  </h2>
                  <div className="flex items-center">
                    <div className="w-full outline-none appearance-none">
                      <DropdownSelect
                        drpIdentity={"PaidUpCap"}
                        optionArray={props.paidUpCaps}
                        setFilterParameters={props.setFilterParameters}
                        value={props.organizationBusinessDetails.paidUpCapId}
                      // onBlur={(e) =>
                      //   props.validate(
                      //     "corporateMemberRecordTypeId",
                      //     props.corporateSIISAccount
                      //   )
                      // }
                      />
                    </div>
                  </div>
                  <Validation error={props.validState.error.paidUpCapId} />
                </div>
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                  <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                    What is your Primary SSIC code? <span className="text-[#c00000]">*</span>
                  </h2>
                  <div className="flex items-center">
                    <div className="w-full outline-none appearance-none">
                      <DropdownSelect
                        drpIdentity={"primarySSICcode"}
                        optionArray={props.primarySSICcode}
                        setFilterParameters={props.setFilterParameters}
                        value={props.organizationBusinessDetails.primarySSICcodeId}
                      />
                    </div>
                  </div>
                  <Validation error={props.validState.error.primarySSICcodeId} />
                </div>
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                  <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                    What are your Secondary SSIC code(s)?
                  </h2>
                  <div className="flex items-center">
                    <div className="w-full outline-none appearance-none">
                      <MultiSelectDropdown
                        drpIdentity="secondarySSICcode"
                        options={props.secondarySSICcode}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{ Option }}
                        onChange={props.handleChangeMultiDropdown}
                        allowSelectAll={true}
                        value={props.organizationBusinessDetails.selectedSecondarySSICcode}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {props.organizationBusinessDetails.isIndustryGroupFNB === true ?
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                SFA Cert <span className="text-[#c00000]">*</span>
                </h2>
                <form className="custom-uploader custom-file-upload-btn flex justify-center items-center text-center btn btn-red-border w-min">
                  <label
                    htmlFor={`upload_File1`}
                    className="text-uplod block text-sm text-[#313984]"
                  >
                   File Upload
                  </label>
                  <input
                    className="sr-only"
                    // key={`FileQuestion${key}`}
                    id={`upload_File1`}
                    type="file"
                    onChange={(event) => props.sfaCert(event)}
                  />
                </form>
                <a className="text-[#313984] big-bullet-points font-bold mb-5 inline-block mobile-14"
                  onClick={() => window.open(APIURL + "Common/GetPDF?type=sfacert&&fileName=" + props.organizationBusinessDetails.sfacertPath, '_blank')}
                >
                  {props.organizationBusinessDetails.sfacertPath}
                </a>
              </div>
            <Validation error={props.validState.error.sfaCertPatherror} />
            </div>
          </div>:null}

            {/* <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                    Share Holding Foreign
                    <span className="text-[#c00000]">*</span>
                  </h2>
                  <div className="flex items-center">
                    <div className="w-full outline-none appearance-none">
                      <DropdownSelect
                        drpIdentity={"ShareHoldingForeign"}
                        optionArray={props.shareHoldingForeign}
                        setFilterParameters={props.setFilterParameters}
                        value={props.organizationBusinessDetails.shareHoldingForeignId}
                      onBlur={(e) =>
                        props.validate(
                          "corporateMemberRecordTypeId",
                          props.corporateSIISAccount
                        )
                      }
                      />
                    </div>
                    <Validation
                      error={props.validState.error.shareHoldingForeignId}
                    />
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                    Staff Strength
                   
                  </h2>
                  <div className="flex items-center">
                    <div className="w-full outline-none appearance-none">
                      <DropdownSelect
                        drpIdentity={"StaffStrength"}
                        optionArray={props.companyEmploymentSize}
                        setFilterParameters={props.setFilterParameters}
                        value={props.organizationBusinessDetails.staffStrengthId}
                     
                      />
                    </div>
                    <Validation
                      error={props.validState.error.areaofExpertise}
                    />
                  </div>
                </div>
              </div>
            </div> */}
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                    Updated By
                    {/* <span className="text-[#c00000]">*</span> */}
                  </h2>
                  <div className="disabled-input flex items-center">
                    <DropdownSelect
                      disabled={true}
                      drpIdentity={"updatedById"}
                      optionArray={props._by}
                      setFilterParameters={props.setFilterParameters}
                      value={props.organizationBusinessDetails.updatedBy}
                    // onBlur={(e) => props.validate("updatedById", props.corporateSIISAccount)}
                    />
                    <Validation
                      error={props.validState.error.areaofExpertise}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                    Last Updated Date
                    {/* <span className="text-[#c00000]">*</span> */}
                  </h2>
                  <div className="disabled-input">
                    {props.organizationBusinessDetails.updatedAt === "" || props.organizationBusinessDetails.updatedAt === null ?
                      <TextInput
                        disabled={true}
                        placeholder=""
                        type="text"
                        name="linkedIn"
                        value="None"
                        identity="BasicInformation"
                      />
                      :
                      <DatePicker
                        isDisable={true}
                        name="updatedAt"
                        handleChange={props.handleChange}
                        identity="updatedAt"
                        value={moment(props.organizationBusinessDetails.updatedAt).format("yyyy-MM-DD")}
                      />
                    }
                  </div>
                  {/* <div className="disabled-input flex items-center">
                    
                    <DatePicker
                      name="updatedAt"
                      isDisable={true}
                      // handleChange={props.handleChange}
                      identity="updatedAt"
                      value={moment(props.organizationBusinessDetails.updatedAt).format("yyyy-MM-DD")}
                    />

                    <Validation
                      error={props.validState.error.areaofExpertise}
                    />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Save Button */}
        <div className="grid grid-cols-12 gap-6 pb-10">
          <div className="xl:col-span-12 lg:col-span-12 col-span-12 px-10">
            <a
              className="flex items-center justify-end"
            //href="/CreateCorporateMembers"
            >
              {
                props && props.isLoading === true ?
                  (
                    <ButtonLoader />
                  )
                  :
                  (
                    <button
                      className="w-32 px-7 py-3 btn-sm btn-red text-xl text-white font-semibold"
                      onClick={() => props.onSave()}
                    >
                      Save
                    </button>
                  )
              }
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
export default OrganisationBasicInformation;