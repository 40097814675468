import { CommonValidationMessages } from "../../../utils/Messages";

export const siisAccountInformationValRules = {
    accountStatus: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "account status"
            ),
        },
    ],
    companyDomain: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "domain"
            ),
        },
        {
            type: "emailDomain",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "valid domain"
            ),
        },
    ],
    // companyDomain: [
    //     {
    //         type: "emailDomain",
    //         message: CommonValidationMessages.FieldRequired.replace(
    //             "{0}",
    //             "proper format of domain"
    //         ),
    //     },
    // ],
};

export const engagementTrackingValRules = {
    corporateEngagementTypeId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "engagement type"
            ),
        },
    ],
    corporateEngagementRecordedById: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "recorded by"
            ),
        },
    ],

    engagementDate: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "engagement date"
            ),
        },
    ],
    engagementDescription: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "description"
            ),
        },
    ],
};

export const prospectInformationValRules = {
    source1Id: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "source 1"
            ),
        },
    ],

    source2Id: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "source 2"),
        },
    ],

    pic1Id: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "pic 1"
            ),
        },
    ],
};

export const financeCardValRules = {
    creditTermsId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "credit terms"),
        },
    ],
};

// export const financeCardValRules = {
//     renewalNotes: [
//         {
//             type: "require",
//             message: CommonValidationMessages.FieldRequired.replace(
//                 "{0}",
//                 "company name"
//             ),
//         },
//     ],
//     internalFlagId: [
//         {
//             type: "require",
//             message: CommonValidationMessages.FieldRequired.replace(
//                 "{0}",
//                 "company UEN"
//             ),
//         },
//     ],
// };

export const governmentProgrammesValRules = {
    renewalNotes: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "company name"
            ),
        },
    ],
    internalFlagId: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "company UEN"
            ),
        },
    ],
};

export const memberSubscriptionValRules = {
    membershipTypeId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "membership type"
            ),
        },
    ],
    billingCategoryId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "billing category"
            ),
        },
    ],
};

export const chapterInformationValRules = {
    workgroupMappingId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "Industry Group name"
            ),
        },
    ],
    // renewalNotes: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.FieldRequired.replace(
    //             "{0}",
    //             "company name"
    //         ),
    //     },
    // ],
    // internalFlagId: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.FieldRequired.replace(
    //             "{0}",
    //             "company UEN"
    //         ),
    //     },
    // ],
};

export const purchaseChapterValidationRule = {
    noOfAdditionalChapters: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "additional number of Industry Group"),
        },
    ],
    paymentVia: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "payment via"),
        },
    ],
};

export const organizationBasicInformationValRules = {
    companyUEN: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "company uen"),
        },
    ],
    mrMrsId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "name prefix"),
        },
    ],
    // jobLevelId: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.SelectRequired.replace("{0}", "Job Level"),
    //     },
    // ],
    companyName: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "company name"
            ),
        },
    ],
    incorporationDate: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "incorporating date"
            ),
        },
    ],
    firstName: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "representative's first name"
            ),
        },
    ],
    lastName: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "representative's last name"
            ),
        },
    ],
    employmentTitile: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "designation"
            ),
        },
    ],
    selectedDesignation: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "designation"
            ),
        },
    ],
    email: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "email address"
            ),
        },
    ],
    contactNo: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "business contact number"),
        },
        {
            type: 'maxLength',
            maxLength: 20,
            message: CommonValidationMessages.MaxLength.replace("{0}", "20"),
        },
        // {
        //     type: "number",
        //     message: CommonValidationMessages.FieldRequired.replace("{0}", "only digits"),
        // },
        // {
        //     type: "maxLength",
        //     maxLength: 10,
        //     message: CommonValidationMessages.FieldRequired.replace("{0}", "between 8 and 10 digits"),
        // },
        // {
        //     type: "minLength",
        //     minLength: 8,
        //     message: CommonValidationMessages.FieldRequired.replace("{0}", "between 8 and 10 digits"),
        // },
    ],
};
export const organizationBussinessInformationValRules = {
    primarySSICcodeId: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "primarySSICcode"),
        },
    ],
    // othermainBusiness: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.FieldRequired.replace("{0}", "othermainBusiness"),
    //     },
    // ],
    // othertypeOfcompany: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.FieldRequired.replace("{0}", "othertypeOfcompany"),
    //     },
    // ],
    // othercompanyBusinessFunctions: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.FieldRequired.replace("{0}", "othercompanyBusinessFunctions"),
    //     },
    // ],
    paidUpCapId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "paidUpCap"),
        },
    ],
    typeOfcompanyId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "typeOfcompany"),
        },
    ],
    mainBusinessId: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "mainBusiness"
            ),
        },
    ],
    companyBusinessFunctionId: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "companyBusinessFunction"
            ),
        },
    ],
    
};
export const organizationSmfValRules = {
    selectedProgram: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "interested program"),
        },
    ],
};
export const organizationProfileValRules = {
    orgGrossTurnoverId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "organisation gross turnover"),
        },
    ],
    // locationAreaId: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.SelectRequired.replace("{0}", "sglocationareaId"),
    //     },
    // ],
    // selectedSectorsArr: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.SelectRequired.replace("{0}", "business sectors"),
    //     },
    // ],
    // selectedExpertiseArr: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.FieldRequired.replace("{0}", "area of expertise"),
    //     },
    // ],
    businessContactNumber: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "business contact number"),
        },
        {
            type: 'maxLength',
            maxLength: 20,
            message: CommonValidationMessages.MaxLength.replace("{0}", "20"),
        },
    ],
    // mobileNumber: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.FieldRequired.replace("{0}", "mobile number"),
    //     },
    //     {
    //         type: 'maxLength',
    //         maxLength: 20,
    //         message: CommonValidationMessages.MaxLength.replace("{0}", "20"),
    //     },
    // ],
    businessEmail: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "business email"),
        },
    ],
    mailingAddress: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "mailing address line 1."),
        },
    ],
    // mailingAddress2: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.FieldRequired.replace("{0}", "mailing address line 2."),
    //     },
    // ],
    // mailingAddress3: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.FieldRequired.replace("{0}", "mailing address line 3."),
    //     },
    // ],
    countryId: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "country"),
        },
    ],
    city: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "city"),
        },
    ],
    postalCode: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "postal code"),
        },
        // {
        //     type: "number",
        //     message: CommonValidationMessages.FieldRequired.replace("{0}", "valid postal code"),
        // },
        {
            type: 'maxLength',
            maxLength: 20,
            message: CommonValidationMessages.MaxLength.replace("{0}", "20"),
        },
        // {
        //     type: "maxLength",
        //     maxLength: 6,
        //     message: CommonValidationMessages.FieldRequired.replace("{0}", "valid postal code"),
        // },
        // {
        //     type: "minLength",
        //     minLength: 6,
        //     message: CommonValidationMessages.FieldRequired.replace("{0}", "valid postal code"),
        // },
    ],
    // selectedCountriesArr: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.SelectRequired.replace("{0}", "countries with presence"),
    //     },
    // ],
    logoImage: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "company logo"),
        },
    ]


};

export const organizationBusinessDetailsValRules = {
    renewalNotes: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "company name"
            ),
        },
    ],
    internalFlagId: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "company UEN"
            ),
        },
    ],
};

export const sgTechInterestValRules = {
    renewalNotes: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "company name"
            ),
        },
    ],
    internalFlagId: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "company UEN"
            ),
        },
    ],
};

export const communicationValRules = {
    renewalNotes: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "company name"
            ),
        },
    ],
    internalFlagId: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "company UEN"
            ),
        },
    ],
};

export const organizationEmployeesValRules = {


    firstName: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "first name"),
        },
    ],
    lastName: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "last name"),
        },
    ],
    jobLevelId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "job level"),
        },
    ],
    emailText: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "email address"),
        },
    ],
    emailString: [
        {
            type: "email",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "valid email address"),
        },
    ],
    // otherJobLevel: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.FieldRequired.replace("{0}", "others"),
    //     },
    // ],
    selectedDesignation: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "designation"),
        },
    ],
    searchByEmail: [
        {
            type: "email",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "valid email address"),
        },
        {
            type: "maxLength",
            maxLength: 150,
            message: CommonValidationMessages.FieldRequired.replace("{0}", "less than 150 characters"),
        },

    ],

};

export const engagementvalidationRule = {
    // corporateEngagementTypeId: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.SelectRequired.replace(
    //             "{0}",
    //             "engagement type"
    //         ),
    //     },
    // ],

    // engagementDate: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.FieldRequired.replace(
    //             "{0}",
    //             "engagement date"
    //         ),
    //     },
    // ],
    // engagementDescription: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.FieldRequired.replace(
    //             "{0}",
    //             "description"
    //         ),
    //     },
    // ],
    // corporateEngagementRecordedById: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.SelectRequired.replace(
    //             "{0}",
    //             "recorded by"
    //         ),
    //     },
    // ],
    // corporateEngagementFollowUpById: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.SelectRequired.replace(
    //             "{0}",
    //             "follow up by"
    //         ),
    //     },
    // ],
};

export const organisationKeyRolesValRules = {
    mrMrs: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "name prefix"),
        },
    ],
    jobLevelId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "job level"),
        },
    ],
    firstName: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "first name"),
        },
    ],
    lastName: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "last name"),
        },
    ],
    emailText: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "email address"),
        },
    ],
    emailString: [
        {
            type: "email",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "valid email address"),
        },
        {
            type: "maxLength",
            maxLength: 150,
            message: CommonValidationMessages.FieldRequired.replace("{0}", "less than 150 characters"),
        },

    ],
    // otherJobLevel: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.FieldRequired.replace("{0}", "others"),
    //     },
    // ],
    selectedDesignation: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "designation"),
        },
    ],
    selectedOrganisationRole: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "organisation Role"
            ),
        },
    ],
    searchByEmail: [
        {
            type: "email",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "valid email address"),
        },
        {
            type: "maxLength",
            maxLength: 150,
            message: CommonValidationMessages.FieldRequired.replace("{0}", "less than 150 characters"),
        },

    ],
    // organisationRole: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.SelectRequired.replace("{0}", "organisation role"),
    //     },
    // ]
}

export const addEditCreditNoteValRules = {
    cancellationReason: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "cancellation reason"),
        },
    ],

};

export const addEditCreditNoteValRules2 = {
    cancellationReason: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "other cancellation reason"),
        },
    ],
    entranceRefund: [
        {
            type: "maxNumber",
            compareEle: "compareEntranceRefund",
            message: CommonValidationMessages.MaxAmount.replace("{0}", "entrance amount"),
        },
    ],
    annualRefund: [
        {
            type: "maxNumber",
            compareEle: "compareAnnualRefund",
            message: CommonValidationMessages.MaxAmount.replace("{0}", "annual amount"),
        },
    ],
    chapterRefund: [
        {
            type: "maxNumber",
            compareEle: "compareChapterRefund",
            message: CommonValidationMessages.MaxAmount.replace("{0}", "Industry Group amount"),
        },
    ],
    creditNoteMonth: [{
        type: "require",
        message: CommonValidationMessages.SelectRequired.replace("{0}", "effective date"),

    }]
};

export const validationRuleForGoGlobal = {
    companyTypeId: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "company type"
            ),
        },
    ],
    companyName: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "company name"
            ),
        },
    ],
    companyWebsite: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "company website"
            ),
        },
        {
            type: "isValidateURL",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "proper website url"),
        },
    ],
    // selectedRegion: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.SelectRequired.replace(
    //             "{0}",
    //             "region"
    //         ),
    //     },
    // ],
    selectedCountryWithPresence: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "country"
            ),
        },
    ],
    // selectedCity: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.SelectRequired.replace(
    //             "{0}",
    //             "city"
    //         ),
    //     },
    // ],
    selectedBusinessSector: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "primary sector(s) of main business activity"
            ),
        },
    ],
    selectedServiceProvided: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "list of services"
            ),
        },
    ],
    selectedPartnerType: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "type of business activity"
            ),
        },
    ],
    companyDescription: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "company description"
            ),
        },
    ],
    firstName: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "first name"
            ),
        },
    ],
    lastName: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "last name"
            ),
        },
    ],
    goGlobalContactEmail: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "go global contact email"
            ),
        },
    ],
    companyLogo: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "company logo"),
        }
    ],
    organisationUENLicenseNumberVATID: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "organisation UEN"),
        }
    ],
    businessContactNumber: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "business contact number"),
        }
    ],
    companyAddress: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "company address"),
        }
    ]
}

export const searchByEmailValRule = {
    searchByEmail: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "valid email address"
            ),
        },
        {
            type: "maxLength",
            maxLength: 150,
            message: CommonValidationMessages.FieldRequired.replace("{0}", "less than 150 characters"),
        },
    ],
}