import moment from "moment";
import React, { Component } from "react";
import SwalServices from "../../services/swalServices/SwalServices";
import { saveAs } from "file-saver";
import NetsuiteServices from "../../services/axiosServices/apiServices/NetsuiteServices";
import ButtonLoader from "../../components/common/buttonLoader/ButtonLoader";
import { isValidForm, validate } from "../../utils/validation/CommonValidator";
import { CommonValidationMessages } from "../../utils/Messages";
import ValidationText from "../../utils/validation/ValidationText";

export default class CreditNoteNetSuiteReport extends Component {
    constructor(props) {
        super(props);
        this.netsuiteService = new NetsuiteServices();
        this.swalServices = new SwalServices();
        this.state = {
            isLoading: false,
            creditNoteNetsuiteRequest: {
                startDate: "",
                endDate: "",
            },
            validState: {
                isValid: true,
                error: {},
            },
            validationRule: {
                startDate: [
                    {
                        type: "require",
                        message: CommonValidationMessages.SelectRequired.replace("{0}", "start date for netsuite reports"),
                    },
                ],
                endDate: [
                    {
                        type: "require",
                        message: CommonValidationMessages.SelectRequired.replace("{0}", "end date for netsuite reports"),
                    },
                    {
                        type: "minEndDate",
                        minEndDate: "startDate",
                        message: CommonValidationMessages.SelectRequired.replace("{0}", "greater end date")
                    }
                ]
            },
        };
    }

    validateField = (key) => {
        const newValidState = validate(
            key,
            this.state.creditNoteNetsuiteRequest,
            this.state.validationRule,
            this.state.validState
        );
        this.setState({ validState: newValidState });
    };

    isValidateAllFields = () => {
        const newValidState = isValidForm(
            this.state.creditNoteNetsuiteRequest,
            this.state.validationRule,
            this.state.validState
        );
        this.setState({ validState: newValidState });
        return newValidState.isValid;
    };


    generateCreditNoteNetSuitReport = () => {
        if (this.isValidateAllFields()) {
            this.setState({ isLoading: true });
            let request = {
                startDate: this.state.creditNoteNetsuiteRequest.startDate,
                endDate: this.state.creditNoteNetsuiteRequest.endDate,
            };
            this.netsuiteService.generateCreditNoteNetSuitReport(request).then((response) => {
                if (response.status === 200 && response.data !== null && response.data.type !== "application/json") {
                    let blob = new Blob([response.data])
                    saveAs(blob, `CreditNoteNetsuiteReport` + ".xlsx");
                    this.setState({ isLoading: false });
                } else if (response.status === 200 && response.data !== null) {
                    this.swalServices.Error("No Record Found");
                    this.setState({ isLoading: false });
                }
                else {
                    this.swalServices.Error(response.message);
                    this.setState({ isLoading: false });
                }

            });
        }
        else {
            this.swalServices.Alert('Please select date');
        }
    }
    handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = { ...this.state.creditNoteNetsuiteRequest }
        data[name] = value;
        this.setState({ creditNoteNetsuiteRequest: data });
    }

    render() {
        return (
            <div className="main-body-section finance-body-sec">
                <div className="bg-white">
                    <div className="grid grid-cols-12 gap-6 w-full items-center px-8 py-10">
                        <div className="col-span-3">
                            <label
                                className="block text-xl font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                htmlFor="default"
                            >
                                Start Date<span className="text-[#c00000]">*</span>
                            </label>
                            <div className="">
                                <input
                                    id="startDate"
                                    name="startDate"
                                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                    type="date"
                                    value={moment(this.state.creditNoteNetsuiteRequest.startDate).format("yyyy-MM-DD")}
                                    onChange={(event) => this.handleChange(event)}
                                    onBlur={() => this.validateField("startDate")}
                                //disabled
                                />
                                <ValidationText error={this.state.validState.error.startDate} />
                            </div>
                        </div>
                        <div className="col-span-3">
                            <label
                                className="block text-xl font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                htmlFor="default"
                            >
                                End Date<span className="text-[#c00000]">*</span>
                            </label>
                            <div className="">
                                <input
                                    id="endDate"
                                    name="endDate"
                                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                    type="date"
                                    value={moment(this.state.creditNoteNetsuiteRequest.endDate).format("yyyy-MM-DD")}
                                    onChange={(event) => this.handleChange(event)}
                                    onBlur={() => this.validateField("endDate")}
                                //disabled
                                />
                                <ValidationText error={this.state.validState.error.endDate} />
                            </div>
                        </div>
                        <div className="col-span-4">

                            {this.state.isLoading ? <div className="mt-8"> <ButtonLoader /></div> :
                                <button className="btn btn-red text-xl border text-white mt-8" onClick={() => this.generateCreditNoteNetSuitReport()}>
                                    Download NetSuite Report
                                </button>
                            }
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}
