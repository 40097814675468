import React from "react";

function CommonPagination(props) {
  return (
    <div>
      <nav
        className="flex items-center justify-around"
        role="navigation"
        aria-label="Navigation"
      >
        <div className="mr-2">
          <a
            className="btn bg-white border-slate-200 theme-color hover:text-[#313984] py-4 pagination-btn"
            href="#0"
          >
            <div className="flex items-center justify-between">
              <span className="mr-4">
                <svg
                  width="17px"
                  className="rotate-180"
                  height="17px"
                  viewBox="-5 0 23 23"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    id="icons"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <g
                      id="ui-gambling-website-lined-icnos-casinoshunter"
                      transform="translate(-484.000000, -159.000000)"
                    >
                      <g
                        id="square-filled"
                        transform="translate(50.000000, 120.000000)"
                      >
                        <path
                          d="M435.76062,39.2902857 L446.638635,49.7417043 L446.699713,49.7959169 C446.885605,49.9745543 446.985701,50.2044182 447,50.4382227 L447,50.5617773 C446.985701,50.7955818 446.885605,51.0254457 446.699713,51.2040831 L446.646163,51.2479803 L435.76062,61.7097143 C435.357854,62.0967619 434.704841,62.0967619 434.302075,61.7097143 C433.899308,61.3226668 433.899308,60.6951387 434.302075,60.3080911 L444.451352,50.5617773 L434.302075,40.6919089 C433.899308,40.3048613 433.899308,39.6773332 434.302075,39.2902857 C434.704841,38.9032381 435.357854,38.9032381 435.76062,39.2902857 Z"
                          id="right"
                        ></path>
                      </g>
                    </g>
                  </g>
                </svg>
              </span>
              <span className="text-xl">Previous</span>
            </div>
          </a>
        </div>
        <div className="">
          <ul className="flex items-center text-sm font-medium -space-x-px">
            <li>
              <span className="inline-flex items-center justify-center leading-5 h-[40px] w-[80px] text-xl bg-white border border-slate-200 theme-color shadow-sm">
                <span className="">{props.curruntPage}</span>
              </span>
            </li>

            <li>
              <span className="inline-flex items-center justify-center leading-5 px-2 py-2 text-xl mx-4 theme-color">
                Of
              </span>
            </li>
            <li>
              <a
                className="inline-flex items-center justify-center leading-5 px-2 py-2 text-xl text-[#313984] hover:text-[#aa0000]"
                href="#0"
              >
                <span className="w-5">{props.totalPages}</span>
              </a>
            </li>
          </ul>
        </div>
        <div className="ml-2">
          <a
            className="btn bg-white border-slate-200 theme-color hover:text-[#313984] py-4 pagination-btn"
            href="#0"
          >
            <div className="flex items-center justify-between">
              <span className="text-xl">Next</span>
              <span className="ml-4">
                <svg
                  width="17px"
                  height="17px"
                  viewBox="-5 0 23 23"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    id="icons"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <g
                      id="ui-gambling-website-lined-icnos-casinoshunter"
                      transform="translate(-484.000000, -159.000000)"
                    >
                      <g
                        id="square-filled"
                        transform="translate(50.000000, 120.000000)"
                      >
                        <path
                          d="M435.76062,39.2902857 L446.638635,49.7417043 L446.699713,49.7959169 C446.885605,49.9745543 446.985701,50.2044182 447,50.4382227 L447,50.5617773 C446.985701,50.7955818 446.885605,51.0254457 446.699713,51.2040831 L446.646163,51.2479803 L435.76062,61.7097143 C435.357854,62.0967619 434.704841,62.0967619 434.302075,61.7097143 C433.899308,61.3226668 433.899308,60.6951387 434.302075,60.3080911 L444.451352,50.5617773 L434.302075,40.6919089 C433.899308,40.3048613 433.899308,39.6773332 434.302075,39.2902857 C434.704841,38.9032381 435.357854,38.9032381 435.76062,39.2902857 Z"
                          id="right"
                        ></path>
                      </g>
                    </g>
                  </g>
                </svg>
              </span>
            </div>
          </a>
        </div>
      </nav>
    </div>
  );
}

export default CommonPagination;
