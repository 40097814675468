import React, { Component } from 'react'
import PrivilegesServices from '../../../services/axiosServices/apiServices/PrivilegesServices';
import SwalServices from '../../../services/swalServices/SwalServices';
import { Navigate } from 'react-router-dom';
import moment from 'moment';
import CKEditor from '../../../components/ckEditor/CKEditor';
import ValidationText from '../../../utils/validation/ValidationText';
import { isValidForm, validate } from "../../../utils/validation/CommonValidator";
import { CommonValidationMessages } from '../../../utils/Messages';

export default class CreatePrivilegesPost extends Component {
    constructor(props) {
        super(props)
        this.privilegeServices = new PrivilegesServices();
        this.swalServices = new SwalServices();
        this.state = {
            redirect: null,
            privileges: {
                privilegesId: 0,
                title: "",
                postDetails: "",
                image: "",
                redirectionLink: "",
                startDate: "",
                endDate: "",
                statusId: 0
            },
            data: "",
            editorLoaded: false,
            validationRule: {
                title: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.FieldRequired.replace('{0}', 'title'),
                    },
                ],
                redirectionLink: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.FieldRequired.replace('{0}', 'redirectionLink')
                    }
                ],
                startDate: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.FieldRequired.replace('{0}', 'startDate')
                    }
                ],
                endDate: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.FieldRequired.replace('{0}', 'endDate')
                    }
                ]
            },
            validState: {
                isValid: true,
                error: {}
            },
        }
    }

    handleChange(event) {
        const value = event.target.value;
        const name = event.target.name;
        let detail = this.state.privileges;
        detail[name] = value;
        this.setState({ privileges: { ...detail } });
    }

    back = () => {
        this.setState({ redirect: "/Privileges" });
    }

    save = (value) => {
        let detail = this.state.privileges;
        if (this.isValidateAllFields()) {
            detail.statusId = value;
            this.setState({privileges : {...detail}});
            this.addEditPrivileges();
            this.setState({ redirect: "/Privileges" });
        }
    }

    publish = (value) => {
        let detail = this.state.privileges;
        if (this.isValidateAllFields()) {
            detail.statusId = value;
            this.setState({privileges : {...detail}});
            this.addEditPrivileges();
            this.setState({ redirect: "/Privileges" });
        }
    }

    addEditPrivileges = () => {
        this.privilegeServices.addEditPrivileges(this.state.privileges).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                this.swalServices.Success("Success");
            } else {
                this.swalServices.Error(response.message);
            }
        })
    }

    getPrivilegesById = (id) => {
        this.privilegeServices.getPrivilegesById(id).then((response) => {
            
            if (response.statusCode === 200 && response.responseItem != null) {
                this.setState({ privileges: response.responseItem.responseContent });
            } else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        })
    }

    validateField = (key) => {
        const newValidState = validate(key, this.state.privileges, this.state.validationRule, this.state.validState);
        this.setState({ validState: newValidState });
    }

    isValidateAllFields = () => {
        const newValidState = isValidForm(this.state.privileges, this.state.validationRule, this.state.validState);
        this.setState({ validState: newValidState });
        return newValidState.isValid;
    }

    componentDidMount() {
        let params = new URLSearchParams(window.location.search);
        let privilegesId = params.get("");
        if (privilegesId > 0) {
            this.getPrivilegesById(privilegesId)
        }
        this.setState({ editorLoaded: true });
    }

    setData = (value) => {
        this.setState({ data: value });
    }

    render() {
        if (this.state.redirect != null) {
            return <Navigate to={this.state.redirect} />;
        }
        return (
            <div className='main-body-section'>
                <div className="bg-white">
                    <div className='heading-part px-7 py-4 border-b'>
                        <h4 className='small-title font-bold theme-color'>Post Information</h4>
                    </div>
                    <div className="px-14 py-4 pt-10">
                        <div className='mb-16'>
                            <div className='grid grid-cols-12 gap-6'>
                                <div className='xl:col-span-8'>
                                    <div className='grid grid-cols-12 gap-6'>
                                        <div className='xl:col-span-12'>
                                            <div className='grid grid-cols-12 gap-6 mb-10'>
                                                <div className='filter-types xl:col-span-7'>

                                                    <label className="filter-lable block font-medium mb-3 theme-color min-h-30" htmlFor="default">Title <span className='text-[#313984]'>*</span></label>
                                                    <input id="title" className="form-input rounded-none w-full shadow-red py-3 text-lg" type="text"
                                                        name="title"
                                                        value={this.state.privileges.title}
                                                        onChange={(event) => this.handleChange(event)}
                                                        onBlur={() => this.validateField("title")} />
                                                </div>
                                                <ValidationText error={this.state.validState.error.title} />
                                            </div>
                                            <div className='grid grid-cols-12 gap-6  mb-10'>
                                                <div className='post-details xl:col-span-12'>
                                                    <label className="filter-lable block font-medium mb-3 theme-color min-h-30" htmlFor="default">Post Details <span className='text-[#313984]'>*</span></label>
                                                    <CKEditor
                                                        name="postDetails"
                                                        onChange={(data) => this.setData(data)}
                                                        editorLoaded={this.state.editorLoaded}
                                                    />
                                                </div>
                                            </div>
                                            <div className='grid grid-cols-12 gap-6 mb-10'>
                                                <div className='filter-types xl:col-span-7'>
                                                    <label className="filter-lable block font-medium mb-3 theme-color min-h-30" htmlFor="default">Redirection Link</label>
                                                    <input id="redirectionLink" className="form-input rounded-none w-full shadow-red py-3 text-lg" type="text"
                                                        name="redirectionLink"
                                                        value={this.state.privileges.redirectionLink}
                                                        onChange={(event) => this.handleChange(event)}
                                                        onBlur={() => this.validateField("redirectionLink")}
                                                    />
                                                </div>
                                                <ValidationText error={this.state.validState.error.redirectionLink} />
                                            </div>
                                            <div className='grid grid-cols-12 gap-6'>
                                                <div className='xl:col-span-12'>
                                                    <div className='grid grid-cols-12 gap-6'>
                                                        <div className=' xl:col-span-5'>
                                                            <label className="block text-xl font-medium mb-6 theme-color min-h-30" htmlFor="default">Start Date<span className='text-[#313984]'>*</span></label>
                                                            <div className='grid grid-cols-12 gap-6 items-center w-full'>
                                                                <div className=' xl:col-span-10 w-full'>
                                                                    <input id="startDate" className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg" type="date"
                                                                        name="startDate"
                                                                        value={moment(this.state.privileges.startDate).format("yyyy-MM-DD")}
                                                                        onChange={(event) => this.handleChange(event)}
                                                                        onBlur={() => this.validateField("startDate")}
                                                                    />
                                                                </div>
                                                                <ValidationText error={this.state.validState.error.startDate} />
                                                            </div>
                                                        </div>
                                                        <div className=' xl:col-span-5'>
                                                            <label className="block text-xl font-medium mb-6 theme-color min-h-30" htmlFor="default">End Date <span className='text-[#313984]'>*</span></label>
                                                            <div className='grid grid-cols-12 gap-6 items-center w-full'>
                                                                <div className=' xl:col-span-10 w-full'>
                                                                    <input id="endDate" className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg" type="date"
                                                                        name="endDate"
                                                                        value={moment(this.state.privileges.endDate).format("yyyy-MM-DD")}
                                                                        onChange={(event) => this.handleChange(event)}
                                                                        onBlur={() => this.validateField("endDate")} />
                                                                </div>
                                                                <ValidationText error={this.state.validState.error.endDate} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                                <div className='xl:col-span-4'>
                                    <div className='grid grid-cols-12 gap-6'>
                                        <div className='xl:col-span-1'>
                                        </div>
                                        <div className='xl:col-span-11 pt-5'>
                                            <div className='img-logo-upload text-center'>
                                                <img src='../default-img.png' className='border border-slate-300' alt=""></img>
                                                <button className="px-10 mt-7 py-2 btn-red-border text-xl border text-[#313984]">
                                                    Upload Image
                                    </button>
                                                <p className='mt-5 px-14 text-center font-normal text-2xl theme-color'>
                                                    (Dimension: 814px by 460px Maximum Size: 1MB)
                                    </p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {/* Modal footer Button*/}
                <div className="flex flex-wrap justify-between space-x-10 mt-12">
                    <div>
                        <button className="w-32 px-7 py-2 btn-sm btn-gray text-lg text-white" onClick={() => this.back()} >Back</button>
                    </div>
                    <div>
                        <button id="Save" className="w-32 px-7 mr-16 py-2 btn-sm btn-lighblue text-lg text-white" onClick={() => this.save(1)} >Save</button>
                        <button id="Publish" className="w-32 px-7 py-2 btn-sm btn-red text-lg text-white" onClick={() => this.publish(6)} >Publish</button>
                    </div>
                </div>
            </div>
        )
    }
}


