//import packeages and components
import React, { Component } from "react";
import PartnerTable from "../../components/partnerships/PartnerTable";
import ProjectTable from "../../components/partnerships/ProjectTable";
import StrategicTable from "../../components/partnerships/StrategicTable";
import { Link, Navigate } from "react-router-dom";
import Selector from "../../components/dropdown/commonDropdown/Selector";
import ActionContainer from "../../components/UIComponent/ActionTableComponent/actionContainer";
import ActionDeleteButton from "../../components/UIComponent/ActionTableComponent/ActionDeleteButton";
import AddEditStrategicMemberModal from "../../components/partnerships/AddEditStrategicMemberModal";
import { CommonSuccessMessages, CommonValidationMessages } from "../../utils/Messages";
import moment from "moment";
import { isValidForm, validate } from "../../utils/validation/CommonValidator";
import { encryptAES } from "../../utils/Encryption";

//import api services
import PartnershipsServices from "../../services/axiosServices/apiServices/PartnershipsServices";
import SwalServices from "../../services/swalServices/SwalServices";

export default class Partnerships extends Component {
  constructor(props) {
    super(props);
    this.partnershipServices = new PartnershipsServices();
    this.swalServices = new SwalServices();
    this.state = {
      redirect: null,
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
        status: 0,
        dateFrom: "",
        dateTo: "",
        searchByProjectName: "",
        searchBySGTechOIC: "",
        filterByAgency: 0,
        filterByMember: 0,
        filterByChapter: 0,
        selectedAgency: [],
        selectedMember: [],
        selectedChapter: [],
      },
      paginationGovAction: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
        status: 0,
        dateFrom: "",
        dateTo: "",
        searchByProjectName: "",
        searchBySGTechOIC: "",
        filterByAgency: 0,
        filterByMember: 0,
        filterByChapter: 0,
      },
      paginationProject: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
        status: 0,
        dateFrom: "",
        dateTo: "",
        searchByProjectName: "",
        searchBySGTechOIC: "",

      },
      totalResultes: "",
      totalResultsGovPartner: 0,
      totalResultsProject: 0,
      isLoading: false,
      setAddEditStrategicMember: false,
      govtPartnersList: [],
      projectPartnerList: [],
      strategicMembersList: [],
      projectReview: [],
      agencyList: [],
      memberList: [],
      chapterList: [],

      // Akshay strategic member 18/08/2022 start
      strategicMemberDetails: {
        corporateMemberId: 0,
        individualMemberId: 0,
        companyName: "",
        firstName: "",
        lastName: "",
        designation: "",
        contactNo: "",
        email: "",
        projectId: 0,
      },
      companyForStrategicMember: [],
      strategicMemberList: [],
      companyOfficerByCompanyForStrategicMember: [],
      tempOfficer: [],
      officerArrStrategic: [],
      validationRulesStrategic: {
        corporateMemberId: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "company"),
          }
        ],
        // individualMemberId: [
        //   {
        //     type: "require",
        //     message: CommonValidationMessages.SelectRequired.replace("{0}", "officer"),
        //   }
        // ],
      },
      validStateStrategic: {
        isValid: true,
        error: {},
      },
      nameSearch: "",
      // end 
    };
  }

  // Methods Section
  componentDidMount() {
    // this.getGovernmentActionPartnerList();
    // this.getProjectPartnerList();
    // this.getStrategicMemberList();
    // // this.getAllProjectReviewList();
    // this.getAllAgencyDropdown();
    // this.getAllMemberDropdown();
    // this.getAllChapterDropdown();
    // this.getAllCompanyForStrategicMember();
    this.getAllAgencyDropdown();
  }

  // Strategic member akshay popup start
  reset = () => {
    let details = this.state.strategicMemberDetails;
    details.corporateMemberId = 0;
    details.companyName = "";
    this.setState({ strategicMemberDetails: details })
  }

  addStrategicMember = () => {
    if (this.isValidStrategicMember()) {
      let request = this.state.strategicMemberDetails;
      let member = this.state.companyForStrategicMember.find(
        (x) => x.value === request.corporateMemberId);
      //request.corporateMemberId = 
      request.companyName = member.label;
      request.projectId = 0;

      this.setState({ isLoading: true });
      this.partnershipServices.addStrategicMember(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.swalServices.Success("Strategic member inserted successfully.");
          this.getStrategicMemberList();
          this.setState({ setAddEditStrategicMember: false });
          this.reset();
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
    }
  };
  getAllCompanyForStrategicMember = () => {
    this.setState({ isLoading: true });
    this.partnershipServices.getAllCompanyForStrategicMember().then((response) => {
      if (response != null) {
        var dropdown = response.responseItem.responseContent;
        dropdown.forEach(function (item) { delete item["dropdownName"]; });
        dropdown = dropdown.map((p) => ({ value: p.id, label: p.name }));
        this.setState({ companyForStrategicMember: dropdown });
        // this.setState({ totalResultes: response.responseItem.responseContent.totalCount });
      }
      else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  getCompanyOfficerByCompanyForStrategicMember = (id, name) => {
    this.setState({ isLoading: true });
    let request = [id, this.state.nameSearch]
    this.partnershipServices.getCompanyOfficerByCompanyForStrategicMember(request).then((response) => {
      if (response != null) {
        this.setState({ companyOfficerByCompanyForStrategicMember: response.responseItem.responseContent });
        var tempOfficer = response.responseItem.responseContent;
        var officerArr = [];
        officerArr = tempOfficer.map((member) => ({
          id: member.individualMemberId,
          name: member.firstName + " " + member.lastName,
        }));
        this.setState({ officerArrStrategic: officerArr });
      }
      else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  setFilterParametersStrategic = (id, drpIdentity) => {
    let details = this.state.strategicMemberDetails;
    if (drpIdentity === "CompanyName") {
      details["corporateMemberId"] = id.value;
      this.validateStrategicMember("corporateMemberId");
      //this.getCompanyOfficerByCompanyForStrategicMember(id);
      this.setState({ strategicMemberDetails: details });
    }
    else if (drpIdentity === "CompanyOfficerName") {
      details["individualMemberId"] = id;
      this.validateStrategicMember("individualMemberId");
      // let tempStrategicMember = this.state.strategicMemberDetails;
      this.setState({ strategicMemberDetails: details });

      let member = this.state.companyOfficerByCompanyForStrategicMember.find(
        (x) => x.individualMemberId === id);

      this.setState({ strategicMemberDetails: member });
    }
  };

  validateStrategicMember = (key) => {
    const returnValidState = validate(key, this.state.strategicMemberDetails, this.state.validationRulesStrategic, this.state.validStateStrategic);
    this.setState({ validStateStrategic: returnValidState });
  }
  isValidStrategicMember = () => {
    const returnValidState = isValidForm(this.state.strategicMemberDetails, this.state.validationRulesStrategic, this.state.validStateStrategic);
    this.setState({ validStateStrategic: returnValidState });
    return returnValidState.isValid;
  }

  // end
  //API methods start 

  //Government action partner list method
  getGovernmentActionPartnerList = (paginationGovAction = this.state.paginationGovAction) => {
    this.setState({ isLoading: true });
    this.partnershipServices.getGovernmentActionPartnerList(paginationGovAction).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let details = response.responseItem.responseContent.itemList;

        const swapValue = (details) => {
          Object.keys(details).forEach(key => {
            if (details[key].isKeyAgency === true && details[key] !== null) {
              details[key].isKeyAgency = 'Yes';
            } else if (details[key].isKeyAgency === false) {
              details[key].isKeyAgency = 'No';
            }
            if (details[key].agencyName !== "" || details[key].agencyName !== null) {
              details[key].agencyName = <Link className="text-[#81A3B8] underline" to={`/AddEditGovernmentPartner/${encryptAES(details[key].agencyId)}`} state={details[key].agencyId} params={details[key].agencyId}>{details[key].agencyName}</Link>;
            }
            if (details[key].noOfProject !== 0 || details[key].noOfProject !== null) {
              details[key].noOfProject = (details[key].noOfProject > 0 ?
                <Link className="text-[#81A3B8] underline" to={`/ProjectReview/${encryptAES(details[key].agencyId)}`} >{details[key].noOfProject}</Link>
                : details[key].noOfProject);
            }
          });
        };
        swapValue(details);
        this.setState({ govtPartnersList: details });
        this.setState({ totalResultsGovPartner: response.responseItem.responseContent.totalCount }, () => {
          this.getProjectPartnerList();
        });
      }
      else {
        this.getProjectPartnerList();

        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  //Project partner list method
  getProjectPartnerList = (paginationProject = this.state.paginationProject) => {
    this.setState({ isLoading: true });
    this.partnershipServices.getAllGovernmentProject(paginationProject).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let newProjectPartnerList = response.responseItem.responseContent.itemList;
        newProjectPartnerList.forEach((key, index) => {
          if (key.projectStartDate != null && key.projectEndDate != null) {
            key.projectStartDate = moment(key.projectStartDate).format('DD MMM YYYY');
            key.projectEndDate = moment(key.projectEndDate).format('DD MMM YYYY');
          }
          if (key.projectStartDate === null && key.projectEndDate === null) {
            key.projectEndDate = ' - ';
            key.projectStartDate = ' - '
          }
          if (key.projectStartDate != null && key.projectEndDate === null) {
            key.projectStartDate = moment(key.projectStartDate).format('DD MMM YYYY');
            key.projectEndDate = 'On going';
          }
        });

        const swapValue = (details) => {
          Object.keys(details).forEach(key => {
            if (details[key].projectName !== 0 || details[key].projectName !== null) {
              details[key].projectName = <Link className="text-[#81A3B8] underline" to={`/AddEditProject/${encryptAES(details[key].projectId)}`} state={details[key].projectId} params={details[key].projectId}>{details[key].projectName}</Link>;
            }
          });
        };
        swapValue(newProjectPartnerList);
        this.setState({ projectPartnerList: newProjectPartnerList });
        this.setState({ totalResultsProject: response.responseItem.responseContent.totalCount }, () => {
          this.getStrategicMemberList();
        });
      }
      else {
        this.getStrategicMemberList();
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  // Strategic member list method
  getStrategicMemberList = (pagination = this.state.pagination) => {
    this.setState({ isLoading: true });
    this.partnershipServices.getStrategicMemberList(pagination).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let newGetStrategicMemberList = response.responseItem.responseContent.itemList;
        newGetStrategicMemberList.forEach((key, index) => {
          key.updatedAt = moment(key.updatedAt).format('DD MMM YYYY');
        });

        const swapValue = (details) => {
          Object.keys(details).forEach(key => {
            if (details[key].noOfProject !== 0 || details[key].noOfProject !== null) {
              details[key].noOfProject = (details[key].noOfProject > 0 ?
                <Link className="text-[#81A3B8] underline" to={`/ProjectReview/false/${encryptAES(details[key].corporateMemberId)}`} params={details[key].companyId}>{details[key].noOfProject}</Link>
                : details[key].noOfProject);
            }
            if (details[key].memberName !== null || details[key].memberName !== "")
              //details[key].memberName = `${details[key].memberName} (${details[key].companyName})`;
              details[key].memberName = `${details[key].companyName}`;
          });
        };
        swapValue(newGetStrategicMemberList);

        this.setState({
          strategicMembersList: newGetStrategicMemberList,
          totalResultes: response.responseItem.responseContent.totalCount
        }, () => {
          this.getAllMemberDropdown();

        });
        // this.setState({ totalResultes: response.responseItem.responseContent.totalCount });
      }
      else {
        this.getAllMemberDropdown();
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  //Get all agency name in dropdown 
  getAllAgencyDropdown = () => {
    this.partnershipServices.getAllAgencyDropdown().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let agencyNameList = response.responseItem.responseContent;
        agencyNameList = agencyNameList.map(p => ({
          value: p.id, label: p.name
        }));
        this.setState({ agencyList: agencyNameList }, () => {
          this.getGovernmentActionPartnerList();

        });
      }
      else {
        this.getGovernmentActionPartnerList();

        this.swalServices.Error(response.message);
      }
    });
  };

  //Get all member name in dropdown 
  getAllMemberDropdown = () => {
    this.partnershipServices.getAllMemberDropdown().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let memberNameList = response.responseItem.responseContent;
        memberNameList = memberNameList.map(p => ({
          value: p.id, label: p.name
        }));
        this.setState({ memberList: memberNameList }, () => {
          this.getAllChapterDropdown();

        });
      }
      else {
        this.getAllChapterDropdown();

        this.swalServices.Error(response.message);
      }
    });
  };

  //Get all chapter name in dropdown 
  getAllChapterDropdown = () => {
    this.partnershipServices.getAllChapterDropdown().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let chapterNameList = response.responseItem.responseContent;
        chapterNameList = chapterNameList.map(p => ({
          value: p.id, label: p.name
        }));
        this.setState({ chapterList: chapterNameList }, () => {
          this.getAllCompanyForStrategicMember();
        });
      }
      else {
        this.getAllCompanyForStrategicMember();
        this.swalServices.Error(response.message);
      }
    })
  }

  //Delete government action partners
  onDeleteGovernmentPartner = (value) => {
    let request = {
      id: value,
      deletedBy: 115,
      appId: 15,
    };
    this.swalServices.Confirm(CommonSuccessMessages.Confirm_Question.replace("{0}", "Government Partner"), "Do you want to delete this Government Partner?",
      CommonSuccessMessages.Yes_Text, CommonSuccessMessages.No_Text).then((response) => {
        if (response) {
          this.partnershipServices.deleteGovernmentAgency(request).then((response) => {
            if (response.statusCode === 200 && response.responseItem.responseContent.errorType === "ActiveProject") {
              this.swalServices.Error("You can't delete because it still attached to active project(s).");
            }
            else if (response.statusCode === 200) {
              this.swalServices.Success("Government partner deleted successfully.");
              this.getGovernmentActionPartnerList();
            } else {
              this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
          });
        }
      })
  };

  //Delete  project partner
  onDeleteProjectPartner = (value) => {
    let request = {
      id: value,
      deletedBy: 115,
      appId: 15
    }
    this.swalServices.Confirm(CommonSuccessMessages.Confirm_Question.replace("{0}", "Project"), "Do you want to delete this Project?",
      CommonSuccessMessages.Yes_Text, CommonSuccessMessages.No_Text).then((response) => {
        if (response) {
          this.partnershipServices.deleteGovernmentProject(request).then((response) => {
            if (response.statusCode === 200) {
              this.swalServices.Success("Project delete successfully.");
              this.getProjectPartnerList();
            } else {
              this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
          });
        }
      });
  };

  //Delete strategic member 
  onDeleteStrategicMember = (value) => {
    let request = {
      id: value,
      deletedBy: 115,
      appId: 15
    }
    this.swalServices.Confirm(CommonSuccessMessages.Confirm_Question.replace("{0}", "Strategic Member"), "Do you want to delete this Strategic Member?",
      CommonSuccessMessages.Yes_Text, CommonSuccessMessages.No_Text).then((response) => {
        if (response) {
          this.partnershipServices.deleteGovernmentStrategicMember(request).then((response) => {
            if (response.statusCode === 200 && response.responseItem.responseContent.errorType === "ActiveProject") {
              this.swalServices.Error("You can't delete because it still attached to active project(s).");
            }
            else if (response.statusCode === 200) {
              this.swalServices.Success("Strategic member delete successfully.");
              this.getStrategicMemberList();
            } else {
              this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
          });
        }
      });
  };
  //API methods end

  //Internal method declaration section start
  setPagination = (newPagination, tableIdentity) => {
    if (tableIdentity === "GovPartners") {
      this.setState({ paginationGovAction: newPagination });
      this.getGovernmentActionPartnerList(this.state.paginationGovAction);
    }
    if (tableIdentity === "Projects") {
      this.setState({ paginationProject: newPagination });
      this.getProjectPartnerList(this.state.paginationProject);
    }
    if (tableIdentity === "StrategicMember") {
      this.setState({ pagination: newPagination });
      this.getStrategicMemberList(this.state.pagination);
    }
  };

  actions1 = (element, index, value) => {
    return element !== "agencyId" ? null : (
      <td>
      <ActionContainer>
        {/* <ActionEditButton
          value={value}
          id={index}
          index={index}
          // onActionClick={this.onEdit.bind(this)}
          text="Edit"
        /> */}
        <ActionDeleteButton
          value={value}
          id={index}
          index={index}
          onActionClick={this.onDeleteGovernmentPartner}
        // text="Delete"
        />
      </ActionContainer>
      </td>
    );
  };

  actions2 = (element, index, value) => {
    return element !== "projectId" ? null : (
      <td>
      <ActionContainer>
        {/* <ActionEditButton
          value={value}
          id={index}
          index={index}
          // onActionClick={this.onEdit.bind(this)}
          text="Edit"
        /> */}
        <ActionDeleteButton
          value={value}
          id={index}
          index={index}
          onActionClick={this.onDeleteProjectPartner}
        // text="Delete"
        />
      </ActionContainer>
      </td>
    );
  };

  actions3 = (element, index, value) => {
    return element !== "strategicMemberId" ? null : (
      <td>
      <ActionContainer>

        <ActionDeleteButton
          value={value}
          id={index}
          index={index}
          onActionClick={this.onDeleteStrategicMember.bind(this)}
        // text="Delete"
        />
      </ActionContainer>
      </td>
    );
  };

  actions4 = (element, index, value) => {
    return element !== "numberOfProject" ? null : (
      <Link to="/ProjectReview" className="pt-2 text-base ">
        <p>001</p>
      </Link>

    );
  };
  actions5 = (element, index, value) => {
    let details = this.state.govtPartnersList;
    let agencynameList = details.agencyName;
    return element !== "agencyName" ? null : (
      <Link className="pl-4 text-[#757575] text-base mt-4" to="/AddEditGovernmentPartner">
        {agencynameList}
      </Link>
    );
  };

  handleChangeInDropdown(selected, drpIdentity) {
    if (drpIdentity === "AgencyFilter") {
      var details = this.state.paginationGovAction;
      // details.selectedAgency = selected.value;
      details.filterByAgency = selected.value;
      this.setState({ paginationGovAction: details });
    }
    else if (drpIdentity === "MemberFilter") {
      var details1 = this.state.pagination;
      details1.filterByMember = selected.value;
      this.setState({ pagination: details1 });
    }
    else if (drpIdentity === "ChapterFilter") {
      var details2 = this.state.pagination;
      details2.filterByChapter = selected.value;
      this.setState({ pagination: details2 });
    }
  };

  filterByParameters = () => {
    let detail = this.state.pagination;
    detail.pageNo = 1;
    detail.pageSize = 10;
    this.setState({ pagination: { ...detail } }, () =>
      this.getGovernmentActionPartnerList()
    );
  };

  searchByName = (value, identity) => {
    let detail = this.state.paginationProject;
    if (identity === "searchByProjectName") {
      detail.pageNo = 1;
      detail.pageSize = 10;
      detail.searchText = value;
      this.setState({ paginationProject: { ...detail } });
    }
    let govAction = this.state.paginationGovAction;
    if (identity === "GovAction") {
      govAction.searchBySGTechOIC = value;
      this.setState({ paginationGovAction: govAction });
    }
  };

  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} state={this.state.getResourcePagesList} />;
    }
    return (
      <div className="main-body-section">
        {/* Goverment Actions Partner section start */}
        <div className="custom-card shadow-lg">
          <div className="filter-info grid grid-cols-12 gap-6">
            <div className="xl:col-span-6 lg:col-span-6 col-span-12">
              <div className="grid grid-cols-12 gap-6">
                <div className="filter-types xl:col-span-6 lg:col-span-6 col-span-12 ">
                  <label
                    className=" filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                    htmlFor="default"
                  >
                    Filter by Agency
                  </label>
                  <Selector
                    options={this.state.agencyList}
                    // isMulti
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    // components={{
                    //   Option,
                    // }}
                    onChange={this.handleChangeInDropdown.bind(this)}
                    allowSelectAll={false}
                    // value={this.state.pagination.FilterByAgency}
                    // value={this.state.agencyList.find(item => item.value === this.state.pagination.FilterByAgency.id)}
                    drpIdentity="AgencyFilter"
                  />
                </div>
                <div className="filter-types xl:col-span-6 lg:col-span-6 col-span-12 xl:pr-12 lg:pr-0">
                  <label
                    className="filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                    htmlFor="default"
                  >
                    Search by SMF OIC
                  </label>
                  <input
                    id="eventName"
                    name="sgTechOic"
                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                    type="text"
                    onChange={(e) => this.searchByName(e.target.value, "GovAction")}
                  />
                </div>
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12">
              <div className=" patner-search flex items-center justify-end xl:pt-11 lg:pt-10">
                <div className="search mr-10">
                  <button
                    className="btn btn-red text-xl text-white font-bold"
                    onClick={() => {
                      this.getGovernmentActionPartnerList();
                    }}
                  >
                    Search
                  </button>
                </div>
                <div className="">
                  <div className="flex items-center justify-end">
                    <Link to="/AddEditGovernmentPartner">
                      <button
                        className="text-xl font-bold btn btn-red-border border text-[#313984] text-red"
                      >
                        Add New Government Partner
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-8">
          <PartnerTable
            govtPartnersList={this.state.govtPartnersList}
            onDeleteGovernmentPartner={this.onDeleteGovernmentPartner.bind(this)}
            isLoading={this.state.isLoading}
          />
        </div>
        {/* Government Actions Partner section End */}  

        {/* Strategic member section start */}
        <div className="custom-card shadow-lg">
          <div className="filter-info grid grid-cols-12 gap-6">
            <div className="xl:col-span-6 lg:col-span-6 col-span-12">
              <div className="grid grid-cols-12 gap-6">
                <div className="filter-types xl:col-span-6 lg:col-span-6 col-span-12 ">
                  <label
                    className=" filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                    htmlFor="default"
                  >
                    Filter by Member
                  </label>
                  <Selector
                    options={this.state.memberList}
                    // isMulti
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    // components={{
                    //   Option,
                    // }}
                    onChange={this.handleChangeInDropdown.bind(this)}
                    allowSelectAll={false}
                    value={this.state.pagination.FilterByMember}
                    drpIdentity="MemberFilter"
                  />
                </div>
                <div className="xl:col-span-6 lg:col-span-6 col-span-12 xl:pr-12 lg:pr-0">
                  <label
                    className="filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                    htmlFor="default"
                  >
                    Filter by Industry Group
                  </label>
                  <Selector
                    options={this.state.chapterList}
                    // isMulti
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    // components={{
                    //   Option,
                    // }}
                    onChange={this.handleChangeInDropdown.bind(this)}
                    allowSelectAll={false}
                    value={this.state.pagination.optionSelected}
                    drpIdentity="ChapterFilter"
                  />
                </div>
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12">
              <div className=" patner-search flex items-center justify-end xl:pt-11 lg:pt-10">
                <div className="search mr-10">
                  <button
                    className="btn btn-red text-xl text-white font-bold"
                    onClick={() => {
                      this.getStrategicMemberList();
                    }}
                  >
                    Search
                  </button>
                </div>
                <div className="">
                  <div className="flex items-center justify-end">
                    <button className="text-xl font-bold btn btn-red-border border text-[#313984] text-red"
                      onClick={(e) => { e.stopPropagation(); this.setState({ setAddEditStrategicMember: true }); }}
                    >
                      Assign Strategic Member
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-8">
          <StrategicTable
            strategicMembersList={this.state.strategicMembersList}
            onDeleteStrategicMember={this.onDeleteStrategicMember.bind(this)}
            isLoading={this.state.isLoading}
          />
        </div>       
        {/* Strategic member section END */}
        {/* Add Edit Strategic Member Modal Start */}
        <div className="membership-task-list-process-sec">
          <AddEditStrategicMemberModal
            setAddEditStrategicMember={this.state.setAddEditStrategicMember}
            setOpenModal={() => this.setState({ setAddEditStrategicMember: false })}
            setFilterParameters={this.setFilterParametersStrategic.bind(this)}
            strategicMemberDetails={this.state.strategicMemberDetails}
            validState={this.state.validStateStrategic}
            officerArr={this.state.officerArrStrategic}
            companyForStrategicMember={this.state.companyForStrategicMember}
            addStrategicMember={this.addStrategicMember.bind(this)}
            onClose={(e) => {
              e.stopPropagation();
              this.setState({ setAddEditStrategicMember: false });
            }}
            projectId={null}
          />
        </div>
        {/* Add Edit Strategic Member Modal End */}
        {/* Project Partner section start */}
        <div className="custom-card shadow-lg">
          <div className=" filter-info grid grid-cols-12 gap-6">
            <div className="xl:col-span-6 lg:col-span-6 col-span-12">
              <div className="grid grid-cols-12 gap-6">
                <div className="filter-types xl:col-span-6 lg:col-span-6 col-span-12 ">
                  <label
                    className=" filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                    htmlFor="default"
                  >
                    Search by Project Name
                  </label>
                  <input
                    id="eventName"
                    name="eventName"
                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                    type="text"
                    onChange={(event) => this.searchByName(event.target.value, "searchByProjectName")}
                  />
                </div>
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 ">
              <div className=" patner-search flex items-center justify-end xl:pt-11 lg:pt-10">
                <div className="search mr-10">
                  <button
                    className="btn btn-red text-xl text-white font-bold"
                    onClick={() => this.getProjectPartnerList()}
                  >
                    Search
                  </button>
                </div>
                <div className="">
                  <div className="flex items-center justify-end">
                    <Link to="/AddEditProject">
                      <button className="text-xl font-bold btn btn-red-border border text-[#313984] text-red">
                        Add New Project
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-8">
          <ProjectTable
            projectPartnerList={this.state.projectPartnerList}
            onDeleteProjectPartner={this.onDeleteProjectPartner.bind(this)}
            isLoading={this.state.isLoading}
          />
        </div>
        {/* Project Partner section end */}
      </div>
    );
  }
}