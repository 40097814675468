import React from "react";
import SectionWithNoBorder from "../../../../components/UIComponent/SectionTitleNoBorder";
import SwalServices from "../../../../services/swalServices/SwalServices";
import MembershipCampaignServices from "../../../../services/axiosServices/apiServices/MembershipCampaignServices";
import { Component } from "react-image-crop";
import Row3Input from "../../../../components/membership/membershipPricing/Row3Input";
import moment from "moment";
import DropdownSelect from "../../../../components/dropdown/Dropdown";
import {
  isValidForm,
  validate,
} from "../../../../utils/validation/CommonValidator";
import { CommonValidationMessages } from "../../../../utils/Messages";
import ValidationText from "../../../../utils/validation/ValidationText";
import { Navigate } from "react-router-dom";
import { checkParameters } from "../../../../utils/Encryption";

export default class AddEditMembershipCampaign extends Component {
  constructor(props) {
    super(props);
    this.MembershipCampaignServices = new MembershipCampaignServices();
    this.swalServices = new SwalServices();
    this.state = {
      redirect: null,
      paymentYear: [
        {
          id: 1,
          name: 1,
        },
        {
          id: 2,
          name: 2,
        },
        {
          id: 3,
          name: 3,
        },
        {
          id: 4,
          name: 4,
        },
        {
          id: 5,
          name: 5,
        },
      ],
      tempDate: "",
      membershipCampaignDetails: {
        membershipCampaignId: 0,
        campaignStartDate: "",
        campaignEndDate: "",
        campaignMinimumPaymentTerm: 0,
        isNewMember: false,
        isRenewalMember: false,
        campaignOrdinaryEntranceFee: 0.0,
        campaignOrdinaryAnnualFee: 0.0,
        campaignOrdinaryRevenue1M: 0.0,
        campaignOrdinaryRevenue10M: 0.0,
        campaignOrdinaryRevenue30M: 0.0,
        campaignOrdinaryRevenue50M: 0.0,
        campaignOrdinaryRevenueMax: 0.0,
        campaignOrdinaryFreeChapter: 0.0,
        campaignOrdinaryChapterPrice: 0.0,
        campaignAssociateEntranceFee: 0.0,
        campaignAssociateAnnualFee: 0.0,
        campaignAssociateRevenue1M: 0.0,
        campaignAssociateRevenue10M: 0.0,
        campaignAssociateRevenue30M: 0.0,
        campaignAssociateRevenue50M: 0.0,
        campaignAssociateRevenueMax: 0.0,
        campaignAssociateFreeChapter: 0.0,
        campaignAssociateChapterPrice: 0.0,
        startUpEntrance1stYearFee: 0.0,
        startUpEntrance2ndYearFee: 0.0,
        startUpEntrance3rdYearFee: 0.0,
        startUpEntrance4thYearFee: 0.0,
        startUpEntrance5thYearFee: 0.0,
        startUpAnnual1stYearFee: 0.0,
        startUpAnnual2ndYearFee: 0.0,
        startUpAnnual3rdYearFee: 0.0,
        startUpAnnual4thYearFee: 0.0,
        startUpAnnual5thYearFee: 0.0,
        startUpFreeChapter: 0.0,
        startUpChapterPrice: 0.0,
        campaignAddtionalPromotion: '',
        campaignInternalNotes: '',
        validMembershipStartEndCampaign: false,
      },
      validationRule: {
        campaignStartDate: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "campaign start date"
            ),
          },
        ],
        campaignEndDate: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "campaign end date"
            ),
          },
        ],
        campaignMinimumPaymentTerm: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "minimum payment terms"
            ),
          },
        ],
        campaignOrdinaryEntranceFee: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        // campaignOrdinaryAnnualFee: [
        //   {
        //     type: "number",
        //     message: CommonValidationMessages.FieldRequired.replace(
        //       "{0}",
        //       "only numeric values"
        //     ),
        //   },
        // ],
        campaignOrdinaryRevenue1M: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        campaignOrdinaryRevenue10M: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        campaignOrdinaryRevenue30M: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        campaignOrdinaryRevenue50M: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        campaignOrdinaryRevenueMax: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        campaignOrdinaryFreeChapter: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        campaignOrdinaryChapterPrice: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        campaignAssociateEntranceFee: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        // campaignAssociateAnnualFee: [
        //   {
        //     type: "number",
        //     message: CommonValidationMessages.FieldRequired.replace(
        //       "{0}",
        //       "only numeric values"
        //     ),
        //   },
        // ],
        campaignAssociateRevenue1M: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        campaignAssociateRevenue10M: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        campaignAssociateRevenue30M: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        campaignAssociateRevenue50M: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        campaignAssociateRevenueMax: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        campaignAssociateFreeChapter: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        campaignAssociateChapterPrice: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        startUpEntrance1stYearFee: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        startUpEntrance2ndYearFee: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        startUpEntrance3rdYearFee: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        startUpEntrance4thYearFee: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        startUpEntrance5thYearFee: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        startUpAnnual1stYearFee: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        startUpAnnual2ndYearFee: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        startUpAnnual3rdYearFee: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        startUpAnnual4thYearFee: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        startUpAnnual5thYearFee: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        startUpFreeChapter: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        startUpChapterPrice: [
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
      },
      validState: {
        isValid: true,
        error: {},
      },
    };
  }

  setFilterParameters = (id, drpIdentity) => {
    let detail = this.state.membershipCampaignDetails;
    if (drpIdentity === "Year") {
      detail["campaignMinimumPaymentTerm"] = id;
      this.validateField("campaignMinimumPaymentTerm");
    }
    this.setState({ membershipCampaignDetails: { ...detail } });
  };

  validateField = (key) => {
    const newValidState = validate(
      key,
      this.state.membershipCampaignDetails,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
  };

  isValidateAllFields = () => {
    const newValidState = isValidForm(
      this.state.membershipCampaignDetails,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  };
  handleChange(event) {
    let value = event.target.value;
    var num = 0;
    value = parseFloat(value);
    if (!isNaN(value)) {
      num = value.toFixed(0);
    }
    // var num = isNaN(value) ? parseFloat(value).toFixed(0) : 0;
    const name = event.target.name;
    let detail = this.state.membershipCampaignDetails;

    if (name === "startUpEntrance1stYearFee") {
      detail[name] = num;
      detail.startUpEntrance2ndYearFee = num;
      detail.startUpEntrance3rdYearFee = num;
      detail.startUpEntrance4thYearFee = num;
      detail.startUpEntrance5thYearFee = num;
    } else if (name === "startUpAnnual1stYearFee") {
      detail[name] = num;
      detail.startUpAnnual2ndYearFee = num;
      detail.startUpAnnual3rdYearFee = num;
      detail.startUpAnnual4thYearFee = num;
      detail.startUpAnnual5thYearFee = num;
    } else {
      detail[name] = num;
    }

    this.setState({ membershipCampaignDetails: { ...detail } });
  }

  handleChange2(event) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.membershipCampaignDetails;
    detail[name] = value;

    //Prasad
    if (detail.campaignEndDate <= detail.campaignStartDate) {
      detail.validMembershipStartEndCampaign = true;
    }
    else if (detail.campaignEndDate > detail.campaignStartDate) {
      detail.validMembershipStartEndCampaign = false;
      console.warn("on Cahnge End Zatu Date :", detail.campaignEndDate);
    }
    let d = new Date(detail.campaignStartDate);
    d.setDate(d.getDate() + 1);
    this.setState({ tempDate: moment(d).format("yyyy-MM-DD") })

    this.setState({ membershipCampaignDetails: { ...detail } });
  }

  handleChangeCheckBox = (e) => {
    const { name, checked } = e.target;
    let det = this.state.membershipCampaignDetails;
    det[name] = checked;
    this.setState({ membershipCampaignDetails: { ...det } });
  };

  addEditMembershipCampaign = () => {

    let dateValid = this.validateCampaignDate();
    let isValid = this.isValidateAllFields();
    if (isValid && dateValid) {
      this.MembershipCampaignServices.addEditMembershipCampaign(
        this.state.membershipCampaignDetails
      ).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.swalServices.Success("Campaign inserted successfully.");
          this.setState({ redirect: "/MembershipCampaign" });
          //redirect='/AddEditMembershipCampaign'
        } else {
          this.swalServices.Error(response.message);
        }
      });
    }
    else {
      if (dateValid === false) {
        this.swalServices.Alert("End date should be at least 1 day later than start date");
      }
    }
  };

  getMembershipCampaign = (id) => {
    this.MembershipCampaignServices.getMembershipCampaign(id).then(
      (response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.setState({
            membershipCampaignDetails: response.responseItem.responseContent,
          });
        } else {
          this.swalServices.Error(response.message);
        }
        let detail = this.state.membershipCampaignDetails;
        let d = new Date(detail.campaignStartDate);
        d.setDate(d.getDate() + 1);
        this.setState({ tempDate: moment(d).format("yyyy-MM-DD") })

      }
    );
  };

  componentDidMount() {
    let params = new URLSearchParams(window.location.search);
    let membershipCampaignId = params.get("");
    if (membershipCampaignId && (membershipCampaignId = checkParameters(membershipCampaignId, "Number"))) {
      if (membershipCampaignId > 0) {
        this.getMembershipCampaign(membershipCampaignId);
      }
    }

  }

  validateCampaignDate = () => {
    const valid = { ...this.state.validationRule };
    valid["isValid"] = false;
    const campaignItem = { ...this.state.membershipCampaignDetails };
    const current = new Date();
    const startDate = new Date(campaignItem.campaignStartDate);
    const endDate = new Date(campaignItem.campaignEndDate);
    if (startDate < endDate) {
      valid["isValid"] = true;
      return true;
    }
    //const date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;
    // const date = moment(
    //   `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`
    // ).format("yyyy-MM-DD");
    // if (date <= campaignItem.campaignStartDate) {
    //   valid["isValid"] = true;
    //   return false;
    // }
    // if (campaignItem.campaignStartDate < campaignItem.campaignEndDate) {
    //   valid["isValid"] = true;
    //   return true;
    // }
    return false;
  };

  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <div className="main-body-section px-5">
        <div className=" shadow-lg mb-10 bg-white">
          <h2 className="border-b border-[#757575]-300 text-[20px] text-[#757575] font-bold w-full p-4 pl-8 ">
            New Campaign
          </h2>

          <div className="grid 2xl:grid-cols-5 lg:grid-cols-5 gap-4 h-[40px]  w-full items-center mb-20 p-4 pl-8 pt-8 flex-wrap">
            <div className=" tab-font-date">
              <label className="text-base text-[#757575] font-bold">
                Campaign Start Date
              </label>
              <input
                id="campaignStartDate"
                name="campaignStartDate"
                className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                type="date"
                // value={this.state.membershipCampaignDetails.campaignStartDate}
                value={moment(
                  this.state.membershipCampaignDetails.campaignStartDate
                ).format("yyyy-MM-DD")}
                onChange={(event) => this.handleChange2(event)}
                onBlur={() => this.validateField("campaignStartDate")}
              />
              <ValidationText
                error={this.state.validState.error.campaignStartDate}
              />
            </div>
            <div className="tab-font-date">
              <label className="text-base text-[#757575] font-bold">
                Campaign End Date
              </label>
              <input
                id="campaignEndDate"
                name="campaignEndDate"
                className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                type="date"
                min={this.state.tempDate}
                value={moment(
                  this.state.membershipCampaignDetails.campaignEndDate
                ).format("yyyy-MM-DD")}
                onBlur={() => this.validateField("campaignEndDate")}
                onChange={(event) => this.handleChange2(event)}
              />
              <ValidationText
                error={this.state.validState.error.campaignEndDate}
              />
              {
                this.state.membershipCampaignDetails.validMembershipStartEndCampaign === true && this.state.membershipCampaignDetails.campaignEndDate !== "" ?
                  <p className="text-[#313984]">Please select date higher than campaign start date.</p>
                  : null
              }
            </div>
            <div className="2xl:pr-5 lg:pr-0 tab-font-date">
              <label className="text-base text-[#757575] font-bold">
                Payment Term
              </label>
              <DropdownSelect
                drpIdentity={"Year"}
                optionArray={this.state.paymentYear}
                setFilterParameters={this.setFilterParameters}
                value={
                  this.state.membershipCampaignDetails
                    .campaignMinimumPaymentTerm
                }
              />
              <div>
                <ValidationText
                  error={this.state.validState.error.campaignMinimumPaymentTerm}
                />
              </div>
            </div>

            <label className="flex items-center">
              <input
                type="checkbox"
                className="form-checkbox"
                name="isNewMember"
                id="isNewMember"
                onChange={(event) => this.handleChangeCheckBox(event)}
                checked={this.state.membershipCampaignDetails.isNewMember}
                value={this.state.membershipCampaignDetails.isNewMember}
              />
              <span
                htmlFor="isNewMember"
                className="text-base text-[#757575] font-bold ml-4"
              >
                New Member
              </span>
            </label>

            <label className="flex items-center">
              <input
                type="checkbox"
                className="form-checkbox"
                name="isRenewalMember"
                id="isRenewalMember"
                onChange={(event) => this.handleChangeCheckBox(event)}
                checked={this.state.membershipCampaignDetails.isRenewalMember}
                value={this.state.membershipCampaignDetails.isRenewalMember}
              />
              <span
                htmlFor="isRenewalMember"
                className="text-base text-[#757575] font-bold ml-4"
              >
                Renewal Member
              </span>
            </label>
          </div>

          <div className="grid grid-cols-2 gap-4 m-10 pt-8">
            <div className="border-r  border-[#757575] pr-10">
              <SectionWithNoBorder
                column1=""
                column2="Manufacturing Members"
                column3="Manufacturing Services Members"
              >
                <Row3Input
                  className="text-base text-[#757575] font-bold"
                  caption="Entrance Fee"
                  col1value={
                    this.state.membershipCampaignDetails
                      .campaignOrdinaryEntranceFee
                  }
                  col2value={
                    this.state.membershipCampaignDetails
                      .campaignAssociateEntranceFee
                  }
                  col1FieldName="campaignOrdinaryEntranceFee"
                  col2FieldName="campaignAssociateEntranceFee"
                  col1InputType="Money"
                  col2InputType="Money"
                  handleChange={this.handleChange.bind(this)}
                  validateField={this.validateField.bind(this)}
                  validState={this.state.validState}
                  error1={
                    this.state.validState.error.campaignOrdinaryEntranceFee
                  }
                  error2={
                    this.state.validState.error.campaignAssociateEntranceFee
                  }
                />
                <div className="">
                  <div className="bg-[#f0f0f0]">
                    <h2 className=" border-[#757575] text-xl text-[#757575] font-bold w-full p-4 pl-4 ">
                      Annual fee
                    </h2>
                  </div>

                  {/* <Row3Input
                  className="text-base text-[#757575] font-bold"
                  caption="Annual Fee"
                  col1value={
                    this.state.membershipCampaignDetails
                      .campaignOrdinaryAnnualFee
                  }
                  col2value={
                    this.state.membershipCampaignDetails
                      .campaignAssociateAnnualFee
                  }
                  col1FieldName="campaignOrdinaryAnnualFee"
                  col2FieldName="campaignAssociateAnnualFee"
                  col1InputType="Money"
                  col2InputType="Money"
                  handleChange={this.handleChange.bind(this)}
                  validateField={this.validateField.bind(this)}
                  validState={this.state.validState}
                  error1={this.state.validState.error.campaignOrdinaryAnnualFee}
                  error2={
                    this.state.validState.error.campaignAssociateAnnualFee
                  }
                /> */}
                  <Row3Input
                    className="text-base text-[#757575] font-bold"
                    caption="Workforce Size from 1 to 50"
                    col1value={
                      this.state.membershipCampaignDetails
                        .campaignOrdinaryRevenue1M
                    }
                    col2value={
                      this.state.membershipCampaignDetails
                        .campaignAssociateRevenue1M
                    }
                    col1FieldName="campaignOrdinaryRevenue1M"
                    col2FieldName="campaignAssociateRevenue1M"
                    col1InputType="Money"
                    col2InputType="Money"
                    handleChange={this.handleChange.bind(this)}
                    validateField={this.validateField.bind(this)}
                    validState={this.state.validState}
                    error1={
                      this.state.validState.error.campaignOrdinaryRevenue1M
                    }
                    error2={
                      this.state.validState.error.campaignAssociateRevenue1M
                    }
                  />
                  <Row3Input
                    className="text-base text-[#757575] font-bold"
                    caption="Workforce Size from 51 to 100"
                    col1value={
                      this.state.membershipCampaignDetails
                        .campaignOrdinaryRevenue10M
                    }
                    col2value={
                      this.state.membershipCampaignDetails
                        .campaignAssociateRevenue10M
                    }
                    col1FieldName="campaignOrdinaryRevenue10M"
                    col2FieldName="campaignAssociateRevenue10M"
                    col1InputType="Money"
                    col2InputType="Money"
                    handleChange={this.handleChange.bind(this)}
                    validateField={this.validateField.bind(this)}
                    validState={this.state.validState}
                    error1={
                      this.state.validState.error.campaignOrdinaryRevenue10M
                    }
                    error2={
                      this.state.validState.error.campaignAssociateRevenue10M
                    }
                  />
                  <Row3Input
                    className="text-base text-[#757575] font-bold"
                    caption="Workforce Size from 101 to 200"
                    col1value={
                      this.state.membershipCampaignDetails
                        .campaignOrdinaryRevenue30M
                    }
                    col2value={
                      this.state.membershipCampaignDetails
                        .campaignAssociateRevenue30M
                    }
                    col1FieldName="campaignOrdinaryRevenue30M"
                    col2FieldName="campaignAssociateRevenue30M"
                    col1InputType="Money"
                    col2InputType="Money"
                    handleChange={this.handleChange.bind(this)}
                    validateField={this.validateField.bind(this)}
                    validState={this.state.validState}
                    error1={
                      this.state.validState.error.campaignOrdinaryRevenue30M
                    }
                    error2={
                      this.state.validState.error.campaignAssociateRevenue30M
                    }
                  />
                  <Row3Input
                    className="text-base text-[#757575] font-bold"
                    caption="Workforce Size from 201 to 400"
                    col1value={
                      this.state.membershipCampaignDetails
                        .campaignOrdinaryRevenue50M
                    }
                    col2value={
                      this.state.membershipCampaignDetails
                        .campaignAssociateRevenue50M
                    }
                    col1FieldName="campaignOrdinaryRevenue50M"
                    col2FieldName="campaignAssociateRevenue50M"
                    col1InputType="Money"
                    col2InputType="Money"
                    handleChange={this.handleChange.bind(this)}
                    validateField={this.validateField.bind(this)}
                    validState={this.state.validState}
                    error1={
                      this.state.validState.error.campaignOrdinaryRevenue50M
                    }
                    error2={
                      this.state.validState.error.campaignAssociateRevenue50M
                    }
                  />
                  <Row3Input
                    className="text-base text-[#757575] font-bold"
                    caption="Workforce Size > 400"
                    col1value={
                      this.state.membershipCampaignDetails
                        .campaignOrdinaryRevenueMax
                    }
                    col2value={
                      this.state.membershipCampaignDetails
                        .campaignAssociateRevenueMax
                    }
                    col1FieldName="campaignOrdinaryRevenueMax"
                    col2FieldName="campaignAssociateRevenueMax"
                    col1InputType="Money"
                    col2InputType="Money"
                    handleChange={this.handleChange.bind(this)}
                    validateField={this.validateField.bind(this)}
                    validState={this.state.validState}
                    error1={
                      this.state.validState.error.campaignOrdinaryRevenueMax
                    }
                    error2={
                      this.state.validState.error.campaignAssociateRevenueMax
                    }
                  />
                  <div className="mb-10">
                    <Row3Input
                      className="text-base text-[#757575] font-bold"
                      caption="Number of Free Industry Group"
                      col1value={
                        this.state.membershipCampaignDetails
                          .campaignOrdinaryFreeChapter
                      }
                      col2value={
                        this.state.membershipCampaignDetails
                          .campaignAssociateFreeChapter
                      }
                      col1FieldName="campaignOrdinaryFreeChapter"
                      col2FieldName="campaignAssociateFreeChapter"
                      col1InputType="Number"
                      col2InputType="Number"
                      handleChange={this.handleChange.bind(this)}
                      validateField={this.validateField.bind(this)}
                      validState={this.state.validState}
                      error1={
                        this.state.validState.error.campaignOrdinaryFreeChapter
                      }
                      error2={
                        this.state.validState.error.campaignAssociateFreeChapter
                      }
                    />
                  </div>
                  <Row3Input
                    className="text-base text-[#757575] font-bold"
                    caption="Price of Additional Industry Group"
                    col1value={
                      this.state.membershipCampaignDetails
                        .campaignOrdinaryChapterPrice
                    }
                    col2value={
                      this.state.membershipCampaignDetails
                        .campaignAssociateChapterPrice
                    }
                    col1FieldName="campaignOrdinaryChapterPrice"
                    col2FieldName="campaignAssociateChapterPrice"
                    col1InputType="Money"
                    col2InputType="Money"
                    handleChange={this.handleChange.bind(this)}
                    validateField={this.validateField.bind(this)}
                    validState={this.state.validState}
                    error1={
                      this.state.validState.error.campaignOrdinaryChapterPrice
                    }
                    error2={
                      this.state.validState.error.campaignAssociateChapterPrice
                    }
                  />
                </div>
              </SectionWithNoBorder>
            </div>

            <div className="pl-6">
              <SectionWithNoBorder
                column1="General Members"
                column2="Entrance Fee"
                column3="Annual Fee"
              >
                <Row3Input
                  className="text-base text-[#757575] font-bold"
                  caption="1st Year"
                  col1value={
                    this.state.membershipCampaignDetails
                      .startUpEntrance1stYearFee
                  }
                  col2value={
                    this.state.membershipCampaignDetails.startUpAnnual1stYearFee
                  }
                  col1FieldName="startUpEntrance1stYearFee"
                  col2FieldName="startUpAnnual1stYearFee"
                  col1InputType="Money"
                  col2InputType="Money"
                  handleChange={this.handleChange.bind(this)}
                  validateField={this.validateField.bind(this)}
                  validState={this.state.validState}
                  error1={this.state.validState.error.startUpEntrance1stYearFee}
                  error2={this.state.validState.error.startUpAnnual1stYearFee}
                />
                {/* <Row3Input
                  className="text-base text-[#757575] font-bold"
                  caption="2nd Year"
                  col1value={
                    this.state.membershipCampaignDetails
                      .startUpEntrance2ndYearFee
                  }
                  col2value={
                    this.state.membershipCampaignDetails.startUpAnnual2ndYearFee
                  }
                  col1FieldName="startUpEntrance2ndYearFee"
                  col2FieldName="startUpAnnual2ndYearFee"
                  col1InputType="Money"
                  col2InputType="Money"
                  handleChange={this.handleChange.bind(this)}
                  validateField={this.validateField.bind(this)}
                  validState={this.state.validState}
                  error1={this.state.validState.error.startUpEntrance2ndYearFee}
                  error2={this.state.validState.error.startUpAnnual2ndYearFee}
                />
                <Row3Input
                  className="text-base text-[#757575] font-bold"
                  caption="3rd Year"
                  col1value={
                    this.state.membershipCampaignDetails
                      .startUpEntrance3rdYearFee
                  }
                  col2value={
                    this.state.membershipCampaignDetails.startUpAnnual3rdYearFee
                  }
                  col1FieldName="startUpEntrance3rdYearFee"
                  col2FieldName="startUpAnnual3rdYearFee"
                  col1InputType="Money"
                  col2InputType="Money"
                  handleChange={this.handleChange.bind(this)}
                  validateField={this.validateField.bind(this)}
                  validState={this.state.validState}
                  error1={this.state.validState.error.startUpEntrance3rdYearFee}
                  error2={this.state.validState.error.startUpAnnual3rdYearFee}
                />
                <Row3Input
                  className="text-base text-[#757575] font-bold"
                  caption="4th Year"
                  col1value={
                    this.state.membershipCampaignDetails
                      .startUpEntrance4thYearFee
                  }
                  col2value={
                    this.state.membershipCampaignDetails.startUpAnnual4thYearFee
                  }
                  col1FieldName="startUpEntrance4thYearFee"
                  col2FieldName="startUpAnnual4thYearFee"
                  col1InputType="Money"
                  col2InputType="Money"
                  handleChange={this.handleChange.bind(this)}
                  validateField={this.validateField.bind(this)}
                  validState={this.state.validState}
                  error1={this.state.validState.error.startUpEntrance4thYearFee}
                  error2={this.state.validState.error.startUpAnnual4thYearFee}
                />
                <Row3Input
                  className="text-base text-[#757575] font-bold"
                  caption="5th Year"
                  col1value={
                    this.state.membershipCampaignDetails
                      .startUpEntrance5thYearFee
                  }
                  col2value={
                    this.state.membershipCampaignDetails.startUpAnnual5thYearFee
                  }
                  col1FieldName="startUpEntrance5thYearFee"
                  col2FieldName="startUpAnnual5thYearFee"
                  col1InputType="Money"
                  col2InputType="Money"
                  handleChange={this.handleChange.bind(this)}
                  validateField={this.validateField.bind(this)}
                  validState={this.state.validState}
                  error1={this.state.validState.error.startUpEntrance5thYearFee}
                  error2={this.state.validState.error.startUpAnnual5thYearFee}
                /> */}
                <div className="mb-10">
                  <Row3Input
                    className="text-base text-[#757575] font-bold"
                    caption="Number of Free Industry Group"
                    col1value={
                      this.state.membershipCampaignDetails.startUpFreeChapter
                    }
                    col1FieldName="startUpFreeChapter"
                    col1InputType="Number"
                    handleChange={this.handleChange.bind(this)}
                    validateField={this.validateField.bind(this)}
                    validState={this.state.validState}
                    error1={this.state.validState.error.startUpFreeChapter}
                  />
                </div>
                <Row3Input
                  className="text-base text-[#757575] font-bold"
                  caption="Price of Additional Industry Group"
                  col1value={
                    this.state.membershipCampaignDetails.startUpChapterPrice
                  }
                  col1FieldName="startUpChapterPrice"
                  col1InputType="Money"
                  handleChange={this.handleChange.bind(this)}
                  validateField={this.validateField.bind(this)}
                  validState={this.state.validState}
                  error1={this.state.validState.error.startUpChapterPrice}
                />
              </SectionWithNoBorder>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-6 items-center pl-8 pb-12 pt-12 ">
            <div className="col-span-6">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-6">
                  <label className="text-base text-[#757575] font-bold">
                    Additional Promotion
                  </label>
                  <input
                    className="w-full"
                    placeholder=""
                    type="text"
                    name="campaignAddtionalPromotion"
                    value={
                      this.state.membershipCampaignDetails
                        .campaignAddtionalPromotion
                    }
                    onChange={(e) => this.handleChange2(e)}
                  />
                </div>
                <div className="col-span-6">
                  <label className="text-base text-[#757575] font-bold">
                    Internal Notes
                  </label>
                  <input
                    className="w-full"
                    placeholder=""
                    type="text"
                    name="campaignInternalNotes"
                    value={
                      this.state.membershipCampaignDetails.campaignInternalNotes
                    }
                    onChange={(e) => this.handleChange2(e)}
                  />
                </div>
              </div>
            </div>
            <div className="2xl:col-span-6 lg:col-span-6 pt-6">
              <div className="flex items-center justify-end pr-14">
                <button
                  className="text-lg font-bold  btn btn-red text-white"
                  onClick={() => this.addEditMembershipCampaign()}
                >
                  <span className="border-2 flex items-center justify-center border-white h-7 w-7 mr-2 rounded-full text-xl">
                    +
                  </span>
                  Add/Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
