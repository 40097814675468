import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import BusinessOpportunitiesBannerPreview from "../../components/bannerManagement/BusinessOpportunitiesBannerPreview";
import Footer from "../../components/bannerManagement/Footer";
import Header from "../../components/bannerManagement/Header";
import withParams from "../../HOC/withParams";
import BannersServices from "../../services/axiosServices/apiServices/BannersServices";
import SwalServices from "../../services/swalServices/SwalServices";

class BannerPreview extends Component {
  constructor(props) {
    super(props);
    this.bannersServices = new BannersServices();
    this.swalServices = new SwalServices();
    this.state = {
      bannerPosition: 0,
      bannerDetails: [],
      bannerPositionName: "",
      route: "",
      openTab: true,
    };
  }

  componentDidMount() {
    let params = this.props.params;
    if (params.chapterscommittee === "chapterscommittee") {
      this.setState({ bannerPositionName: "chapterscommittee" });
      this.getBanners(1);
    } else if (params.chapterscommittee === "events") {
      this.setState({ bannerPositionName: "events" });
      this.getBanners(2);
    } else if (params.chapterscommittee === "businessopportunities") {
      this.setState({ bannerPositionName: "businessopportunities" });
      this.getBanners(3);
    } else if (params.chapterscommittee === "articles") {
      this.setState({ bannerPositionName: "articles" });
      this.getBanners(4);
    }
  }

  getBanners = (id) => {
    this.bannersServices.getBanners(id).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        if (response.responseItem.responseContent.length > 0) {
          this.setState({
            bannerDetails: response.responseItem.responseContent,
          });
        }
      } else {
        this.swalServices.Error(response.message);
      }
    });
  };

  addEditBanners = () => {
    let req = this.state.bannerDetails;

    req.map((banner, key) => {
      banner.isPublish = true;
    })
    // req[0].isPublish = true;
    // req[1].isPublish = true;
    // req[2].isPublish = true;

    this.bannersServices.addEditBanners(req).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.swalServices
          .SuccessWithAction("Banners are published successfully.", "Ok")
          .then((response) => {
            this.closePopUp();
          });
      } else {
        this.swalServices.Error(response.message);
      }
    });
  };

  closePopUp = () => {
    this.setState({ route: "/bannerUpload/" + this.state.bannerPositionName });
  };

  render() {
    if (this.state.route !== "") {
      return <Navigate to={this.state.route} />;
    }

    return (
      <div className="">
        <div className="flex flex-wrap">
          <div className="banner-preview-tabs w-full">
            <div className="top-buttons">
              <div className="flex flex-wrap justify-between space-x-10 mt-12 px-10">
                <div>
                  <Link
                    to={"/bannerUpload/" + this.state.bannerPositionName}
                    className="w-32 px-7 py-2 btn-sm btn btn-gray text-lg text-white font-bold hover:text-white"
                  >
                    {" "}
                    Back
                  </Link>
                </div>
                <div>
                  <ul
                    className="flex mb-0 list-none flex-wrap flex-row w-[100%] mx-auto"
                    role="tablist"
                  >
                    <li className="mr-2 last:mr-0 flex-auto text-center">
                      <a
                        className={
                          this.state.openTab === true
                            ? "bg-[#313984] text-white tab-background2 inner-title-font-small-button font-bold px-5 h-[45px] flex items-center justify-center hover:text-white"
                            : "bg-[#E4E4E4] inner-title-font-small-button font-bold px-5 h-[45px] text-[#757575]  flex items-center justify-center "
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({ openTab: true });
                        }}
                        data-toggle="tab"
                        href="#link1"
                        role="tablist"
                      >
                        Desktop
                      </a>
                    </li>

                    <li className="mx-4 mr-2 last:mr-0 flex-auto text-center">
                      <a
                        className={
                          this.state.openTab === false
                            ? "bg-[#313984] text-white inner-title-font-small-button font-bold px-5 h-[45px] flex items-center justify-center hover:text-white"
                            : "bg-[#E4E4E4] inner-title-font-small-button font-bold px-5 h-[45px] text-[#757575]  flex items-center justify-center"
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({ openTab: false });
                        }}
                        data-toggle="tab"
                        href="#link2"
                        role="tablist"
                      >
                        Mobile
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <button
                    className="w-32 px-5 py-2 btn-sm btn-lighblue text-lg font-bold text-white"
                    onClick={() => this.addEditBanners()}
                  >
                    Publish
                  </button>
                </div>
              </div>
            </div>

            {this.state.openTab === true ? (
              <div className="relative break-words bg-white w-full">
                <div className="flex-auto">
                  <div className="tab-content tab-space">
                    <div className="block" id="link1">
                      <div className="grid grid-cols-12 gap-2">
                        <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                          <div className="desktop-banner w-full">
                            <Header />
                            <BusinessOpportunitiesBannerPreview
                              bannerDetails={this.state.bannerDetails}
                              addEditBanners={this.addEditBanners.bind(this)}
                              bannerPositionName={this.state.bannerPositionName}
                            />
                            <Footer />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="relative break-words bg-white w-full">
                <div className="flex-auto">
                  <div className="tab-content tab-space">
                    <div className="block" id="link2">
                      <div className="grid grid-cols-12 gap-2">
                        <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                          <div className="mobile-banner">
                            <div className="mobileifream">
                              <div className="mobileview" id="divMobile">
                                <iframe
                                  // src="/dashboard"
                                  // src="/MobileBannerPreview/businessopportunities"
                                  src={"/MobileBannerPreview/" + this.state.bannerPositionName}
                                  width="300"
                                  height="646"
                                  style={{ border: 0 }}
                                ></iframe>
                                <img
                                  className="w-[340px] h-[692px]"
                                  src="/phone.png"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default withParams(BannerPreview);
