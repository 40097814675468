import React, { Component } from "react";
import Slider from "react-slick";
import withParams from "../../HOC/withParams";
import { APIURL, GetImage } from "../../services/axiosServices/ApiEndPoints";
import BannersServices from "../../services/axiosServices/apiServices/BannersServices";
import SwalServices from "../../services/swalServices/SwalServices";
import Footer from "./Footer";
import Header from "./Header";

class MobileBannerPreview extends Component {
  constructor(props) {
    super(props);
    this.bannersServices = new BannersServices();
    this.swalServices = new SwalServices();
    this.state = {
      bannerPosition: 0,
      bannerDetails: [],
      bannerPositionName: "",
      route: "",
      openTab: true,
      settings: {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 7000,
        speed: 500,
        pauseOnHover: true,
      },
    };
  }
  componentDidMount() {
    let params = this.props.params;
    if (params.chapterscommittee === "chapterscommittee") {
      this.setState({ bannerPositionName: "chapterscommittee" });
      this.getBanners(1);
    } else if (params.chapterscommittee === "events") {
      this.setState({ bannerPositionName: "events" });
      this.getBanners(2);
    } else if (params.chapterscommittee === "businessopportunities") {
      this.setState({ bannerPositionName: "businessopportunities" });
      this.getBanners(3);
    } else if (params.chapterscommittee === "articles") {
      this.setState({ bannerPositionName: "articles" });
      this.getBanners(4);
    }
  }

  getBanners = (id) => {
    this.bannersServices.getBanners(id).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        if (response.responseItem.responseContent.length > 0) {
          this.setState({
            bannerDetails: response.responseItem.responseContent,
          });
        }
      } else {
        this.swalServices.Error(response.message);
      }
    });
  };
  render() {
    return (
      <div className="">
        <Header />
        <div className="main-title ind-bg steeringcommittee-bg">
          <div className="page-header">
            <h2 className="big-title-font font-bold text-white ">Industry Group</h2>
          </div>
        </div>
        <div className="px-[15px] mt-[30px]">
          <div className="main-page-section">
            <div className="body-part 2xl:pt-[85px] 2xl:pb-[53px] lg:pt-[60px] lg:pb-[40px] ">
              <div className="grid grid-cols-12 gap-6">
                <div className="filter-types xl:col-span-8 md:col-span-7 xl:pr-20 md:pr-5 col-span-12">
                  <div className="section-title">
                    <h2 className="2xl:text-[34px] lg:text-[34px] md:text-3xl text-2xl font-bold text-[#757575] ">
                      Overview
                    </h2>
                  </div>
                  <div className="section-desc mb-7">
                    <p className="common-fonts-size text-[#757575]">
                      Artificial Intelligence + High-Performance Computing
                      Industry Group is established in 2018 to reflect AI as a key
                      technology area with the potential to affect all aspects
                      of the digital world. The Industry Group represents tech
                      companies that specialise in AI technology and
                      supercomputing centres in Singapore. The Industry Group aims to
                      foster an integrated ecosystem to accelerate the
                      development of AI capabilities among tech companies,
                      adoption of AI among enterprise users and build a
                      sustainable talent pipeline to support these efforts.
                    </p>
                  </div>
                  <div className="section-title">
                    <h2 className="2xl:text-[34px] lg:text-[34px] md:text-3xl text-2xl font-bold text-[#757575] ">
                      Our Advocacy
                    </h2>
                  </div>
                  <div className="section-desc mb-7">
                    <p className="common-fonts-size text-[#757575]">
                      The value in deploying AI lies in its potential to improve
                      our quality of life. However, due to insufficient
                      knowledge and misconceptions about AI, the adoption and
                      application of AI in Singapore are not as widespread as it
                      could be.
                    </p>
                    <p className="common-fonts-size text-[#757575]">
                      Also, trust is a crucial factor underpinning broader
                      consumer adoption of AI technology. Organisations that
                      build and use AI tools and systems must take the lead to
                      understand the considerations and good practices for
                      ethics and governance of data collected and used for AI.
                    </p>
                    <p className="common-fonts-size text-[#757575]">
                      As the main focus of our advocacy efforts, SMF’s AI &
                      High Performance Computing Industry Group recommends that
                      stakeholders work together in two areas to broaden the
                      adoption of AI and high performance computing in
                      Singapore, so that individuals and organisations can
                      benefit from its use. Read more about our advocacy here.
                    </p>
                  </div>
                  <div className="section-title">
                    <h2 className="2xl:text-[34px] lg:text-[34px] md:text-3xl text-2xl font-bold text-[#757575] ">
                      Key Highlights
                    </h2>
                  </div>
                  <div className="section-desc mb-7">
                    <p className="common-fonts-size text-[#757575]">
                      Jewel Changi Airport’s Smart Journey – An experiential
                      learning journey exclusively for members at Changi
                      Airport’s newest development – the Jewel. In partnership
                      with Cisco Technology, participants from SMF business
                      community gained an insider view about the integrated
                      facilities, security and guest experience management at
                      Jewel. They also had the opportunity to visit Jewel’s
                      Smart Operations Centre and spot real AI technologies in
                      action, such as “PETER”, the robot Patrol &amp; Traffic
                      Enforcement Officer.
                    </p>
                    <p className="common-fonts-size text-[#757575]">
                      Peek into the AI &amp; HPC Industry AI &amp; HPC Industry Group,
                      together with SMF Capability and Capacity Development
                      Team, organised an industry sharing session with IMDA’s
                      iPREP &amp; SGD scholars. The Industry Group intends to educate
                      and inspire students to explore a career in the tech
                      sector and be updated with latest developments in emerging
                      tech areas. Representatives from SMF AI &amp; HPC
                      Industry Group shared about their career journey in tech, and the
                      possibilities of AI and doing social good via
                      technological services and solutions
                    </p>
                  </div>
                  <div className="community-detail-img">
                    <img src="/default-img.png" alt=""></img>
                  </div>
                </div>

                <div className="filter-types xl:col-span-4 md:col-span-5 col-span-12">
                  <div className="right-link-sec border-2 border-[#313984] 2xl:p-[40px] lg:p-[30px] md:p-4 p-3 mb-10">
                    <div className="grid-rows xl:mb-7 lg:mb-7 mb-3 last:mb-0">
                      <button className="flex items-center contact-liks">
                        <span className="text-[#313984] font-bold mr-5 inner-title-font-small-button underline">
                        Industry Group Excos
                        </span>
                        <img
                          className="xl:w-7 lg:w-7 w-5"
                          src="/round-right-icon.png"
                          alt=""
                        ></img>
                      </button>
                    </div>
                    <div className="grid-rows mb-7 last:mb-0">
                      <button className="flex items-center contact-liks">
                        <span className="text-[#313984] font-bold mr-5 inner-title-font-small-button underline">
                        Industry Group Member Directory
                        </span>
                        <img
                          className="xl:w-7 lg:w-7 w-5"
                          src="/round-right-icon.png"
                          alt=""
                        ></img>
                      </button>
                    </div>
                  </div>
                  <div className="right-link-sec bg-[#859cac] 2xl:p-[40px] lg:p-[30px] md:p-4 p-3">
                    <h3 className="text-white inner-title-font-small font-bold ">
                      Related Articles
                    </h3>

                    <div className="2xl:mt-[45px] lg:mt-[25px]">
                      <div>
                        <div>
                          <div className=" grid-rows mt-3">
                            <a
                              href="#."
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-white common-fonts-size underline"
                            >
                              Artificial Intelligence + High-Performance
                              Computing Industry Group
                            </a>
                          </div>
                          <div className=" grid-rows mt-3">
                            <a
                              href="#."
                              className="text-white common-fonts-size underline"
                            >
                              Artificial Intelligence + High-Performance
                              Computing Industry Group
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 gap-6 xl:mt-20 lg:mt-16 mt-10 mb-10">
                <div className="col-span-12">
                  <div className="banner-management-banner">
                    <Slider settings={this.state.settings}>
                      {/* <img className="w-full" src="/ad-banner.jpg"></img>
                      <img className="w-full" src="/ad-banner.jpg"></img>
                      <img className="w-full" src="/ad-banner.jpg"></img> */}

                      {this.state.bannerDetails.map((banner, key) => {
                        return (
                          <>
                            {banner.bannerMobileImageUrl === "" ? null : (
                              <img
                                className="w-full"
                                src={
                                  APIURL +
                                  GetImage +
                                  "?type=bannersmobile&&fileName=" +
                                  banner.bannerMobileImageUrl
                                }
                                alt=""
                                key={key}
                              ></img>
                            )}
                          </>
                        );
                      })}

                      {/* <img className="w-full"
                        src={this.state.bannerDetails[0] ? APIURL + GetImage + "?type=bannersmobile&&fileName=" + this.state.bannerDetails[0].bannerMobileImageUrl : "/ad-banner.jpg"}
                        alt=""></img>
                      <img className="w-full"
                        src={this.state.bannerDetails[1] ? APIURL + GetImage + "?type=bannersmobile&&fileName=" + this.state.bannerDetails[1].bannerMobileImageUrl : "/ad-banner.jpg"}
                        alt=""></img>
                      <img className="w-full"
                        src={this.state.bannerDetails[2] ? APIURL + GetImage + "?type=bannersmobile&&fileName=" + this.state.bannerDetails[2].bannerMobileImageUrl : "/ad-banner.jpg"}
                        alt=""></img> */}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-back-btn">
            <div className="main-page-section">
              <div className="common-fonts-size text-[#757575] mb-12">
                <a
                  href="#."
                  className="text-[#313984] common-fonts-size font-normal flex items-center"
                >
                  <img
                    className="max-w-[22px] rotate-180"
                    src="/arrow-right.svg"
                    alt=""
                  ></img>
                  <span className=" w-auto flex 2xl:ml-[25px] lg:ml-[20px] text-[18px] underline">
                    Back
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withParams(MobileBannerPreview);