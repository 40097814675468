import { axiosPostAuthorize } from '../AxiosRequests';
import {
    GetAllMembershipTerminationList, MembershipTerminationApproval,
} from '../ApiEndPoints';

export default class MembershipTerminationServices {

    async getAllMembershipTerminationList(request) {
        return axiosPostAuthorize(GetAllMembershipTerminationList, request);
    }

    async membershipTerminationApproval(request) {
        return axiosPostAuthorize(MembershipTerminationApproval, request);
    }

}