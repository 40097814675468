import React from 'react'
import { DeleteButtonIcon } from '../SVGConstants'

function TableRadio(props) {
    return (
        <div className="overflow-x-auto">
            <div className='w-full'>
                <table className="w-full bg-white shadow-lg rounded-sm border border-slate-200 radio-table-sec">
                    {/* Table header */}
                    <thead className="table-bg text-xs font-semibold capitalize text-[#757575] border-t border-b border-slate-200 ">
                        <tr>
                            <th className="px-3 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white border border-[#757575]">
                                <div className="font-bold tble-th-txt text-left">
                                    Agent Name
                                </div>
                            </th>
                            <th className="px-3 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white border border-[#757575]">
                                <div className="font-bold tble-th-txt text-left">
                                    Key Contact Person
                                </div>
                            </th>
                            <th className="px-3 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white border border-[#757575]">
                                <div className="font-bold tble-th-txt text-left">
                                    Designation
                                </div>
                            </th>
                            <th className="px-3 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white border border-[#757575]">
                                <div className="font-bold tble-th-txt text-left">
                                    Email Address
                                </div>
                            </th>
                            <th className="px-3 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white border border-[#757575]">
                                <div className="font-bold tble-th-txt text-left">
                                    Contact Information
                                </div>
                            </th>
                            <th className="px-3 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white border border-[#757575]">
                                <div className="font-bold tble-th-txt text-left">
                                    Actions
                                </div>
                            </th>
                        </tr>
                    </thead>
                    {/* Table body */}
                    <tbody className="text-sm custom-tbody">
                        {props.agencyOfficerList.map((officer, key) => (
                            <tr className="custom-tbl-td" key={key}>
                                <td className='px-3 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt pointer text-[#757575] border border-[#757575]'>
                                    <div className='flex flex-col'>
                                        <div className='current-text'>
                                            {officer.agentName}
                                        </div>

                                    </div>
                                </td>
                                <td className='px-3 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt pointer text-[#757575] border border-[#757575]'>
                                    <div className='flex flex-col'>
                                        <div className='current-text'>
                                            <div className="flex items-center justify-start">
                                                <div className="m-3">
                                                    <label className="flex items-center">
                                                        <input
                                                            type="radio"
                                                            name={"isKeyContactPerson_" + key}
                                                            id={"isKeyContactPerson_" + key}
                                                            className="form-radio"
                                                            value={true}
                                                            onChange={(event) => props.handleChangeRadioButtonsKeyPerson(event, officer)}
                                                            checked={officer.isKeyContactPerson === "true"}
                                                        />
                                                        <span className="text-xl  font-medium theme-color ml-2">
                                                            Yes
                                                        </span>
                                                    </label>
                                                </div>

                                                <div className="m-3">
                                                    <label className="flex items-center">
                                                        <input
                                                            type="radio"
                                                            name={"isKeyContactPerson_" + key}
                                                            id={"isKeyContactPerson_" + key}
                                                            className="form-radio"
                                                            value={false}
                                                            onChange={(event) => props.handleChangeRadioButtonsKeyPerson(event, officer)}
                                                            checked={officer.isKeyContactPerson === "false"}
                                                        />
                                                        <span className="text-xl  font-medium theme-color ml-2">
                                                            No
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </td>
                                <td className='px-3 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt pointer text-[#757575] border border-[#757575]'>
                                    <div className='flex flex-col'>
                                        <div className='current-text'>
                                            {officer.designation}
                                        </div>

                                    </div>
                                </td>
                                <td className='px-3 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt pointer text-[#757575] border border-[#757575]'>
                                    <div className='flex flex-col'>
                                        <div className='current-text'>
                                            {officer.emailAddress}
                                        </div>
                                    </div>
                                </td>
                                <td className='px-3 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt pointer text-[#757575] border border-[#757575]'>
                                    <div className='flex flex-col'>
                                        <div className='current-text'>
                                            {officer.contactNo}

                                        </div>
                                    </div>
                                </td>
                                <td className='px-3 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt pointer text-[#757575] border border-[#757575]'>
                                    <div className='flex items-center justify-center edit-field'>
                                        <button className='action-btn mr-4 flex items-center' aria-controls="add-edit-modal"
                                            onClick={() => props.deleteAgencyOfficer(officer.agencyOfficerId, officer.projectAgencyOfficerMappingId)}>
                                            {/* <p className='ml-2 tool-tip-txt text-[#313984] underline cursor-pointer'>
                                                Select Agency Officer
                                            </p> */}
                                            <DeleteButtonIcon />
                                        </button>
                                    </div>
                                </td>
                            </tr>

                        ))}

                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default TableRadio