import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import moment from 'moment';

//import api services
import PartnershipsServices from "../../services/axiosServices/apiServices/PartnershipsServices";
import SwalServices from "../../services/swalServices/SwalServices";
import { checkParameters, encryptAES } from '../../utils/Encryption';

export default class ProjectReview extends Component {
    constructor(props) {
        super(props);
        this.partnershipServices = new PartnershipsServices();
        this.swalServices = new SwalServices();
        this.state = {
            redirect: null,
            agencyId: 0,
            memberId: 0,
            isAgency: "true",
            projectPreviewList: [],
            agencyName: "",
            totalResults: 0,
        };
    }

    componentDidMount() {
        let agencyId = window.location.href;
        var str_array = agencyId.split('/');
        let id = str_array[str_array.length - 1];
        let member = str_array[str_array.length - 2];

        if (id && (id = checkParameters(id, "Number"))) {
            if (id > 0) {
                this.setState({ agencyId: id, isAgency: member }, () => this.getAllProjectReviewList());
            }
        }
    }

    // Project review list method
    getAllProjectReviewList = () => {
        let data = {
            agencyId: this.state.agencyId,
            memberId: this.state.agencyId,
            isAgency: this.state.isAgency === "false" ? false : true,
        }
        this.setState({ isLoading: true });
        this.partnershipServices.getAllProjectReviewList(data).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                let newGetStrategicMemberList = response.responseItem.responseContent;
                if (newGetStrategicMemberList[0].companyName && this.state.isAgency === "false") {
                    this.setState({ agencyName: newGetStrategicMemberList[0].companyName });
                } else if (newGetStrategicMemberList[0].agencyName) {
                    this.setState({ agencyName: newGetStrategicMemberList[0].agencyName });
                }
                newGetStrategicMemberList.forEach((key, index) => {
                    if (key.projectStartDate === '1900-01-01T00:00:00' && key.projectEndDate === '1900-01-01T00:00:00') {
                        key.projectEndDate = ' - ';
                        key.projectStartDate = ' - '
                    }
                    else if (key.projectStartDate != null && key.projectEndDate === '1900-01-01T00:00:00') {
                        key.projectStartDate = moment(key.projectStartDate).format('DD MMM YYYY');
                        key.projectEndDate = 'On going';
                    }
                    else if (key.projectStartDate != null && key.projectEndDate != null) {
                        key.projectStartDate = moment(key.projectStartDate).format('DD MMM YYYY');
                        key.projectEndDate = moment(key.projectEndDate).format('DD MMM YYYY');
                    }
                });

                const swapValue = (details) => {
                    Object.keys(details).forEach(key => {
                        if (details[key].agencyName === true && details[key] !== null) {
                            this.setState({ agencyName: details[key].agencyName });
                        }
                        else if (details[key].companyName === true && details[key] !== null) {
                            this.setState({ agencyName: details[key].companyName });
                        };
                        if (details[key].sgTechSecretariatId === 1 && details[key] !== null) {
                            details[key].sgTechSecretariatId = 'Admin';
                        }
                    })
                };
                swapValue(newGetStrategicMemberList);

                let totalCount = (response.responseItem.responseContent).length
                this.setState({ projectPreviewList: newGetStrategicMemberList });

                this.setState({ totalResults: totalCount });
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        });
    };

    render() {
        return (
            <div>
                <div className="main-body-section">
                    <div className="custom-card shadow-lg">
                        <div className="filter-info grid grid-cols-12 gap-6">
                            <div className="xl:col-span-9 lg:col-span-9 col-span-12 pr-12">
                                <div className="grid grid-cols-12 gap-6">
                                    <div className="filter-types xl:col-span-5 lg:col-span-5 col-span-12 pr-12">
                                        <label
                                            className=" filter-lable block text-xl font-bold  mb-3 theme-color min-h-30 text-[#757575]"
                                            htmlFor="default"
                                        >
                                            {/* Government Partner: <span>{this.state.agencyName}</span> */}
                                            {this.state.isAgency === "false" ?
                                                <>Strategic Member : <span>{this.state.agencyName}</span></>
                                                :
                                                <>Government Partner: <span>{this.state.agencyName}</span></>
                                            }
                                        </label>
                                        <label
                                            className=" filter-lable block text-xl font-bold  mb-3 theme-color min-h-30"
                                            htmlFor="default"
                                        >
                                            Number of Projects: <span>{this.state.totalResults}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='container_tbl'>
                            <div className="mt-2">
                                <table className="w-full tbl-txt mx-3 bg-white rounded-sm contribution-tble">
                                    <thead>
                                        <tr>
                                            <th className='pr-2 text-left last:pr-5 tbl-th pb-0 whitespace-nowrap  text-[#757575]'>
                                                Project Name
                                            </th>
                                            <th className='px-2 first:pl-5 text-left last:pr-5 tbl-th pb-0 whitespace-nowrap text-[#757575]'>
                                                Sg tech OIC

                                            </th>
                                            <th className='px-2 first:pl-5 text-left last:pr-5 tbl-th pb-0 whitespace-nowrap text-[#757575]'>
                                                Start Date
                                            </th>
                                            <th className='px-2 first:pl-5 text-left last:pr-5 tbl-th pb-0 whitespace-nowrap text-[#757575]'>
                                                End Date
                                            </th>
                                            <th className='px-2 first:pl-5 text-left last:pr-5 tbl-th pb-0 whitespace-nowrap text-[#757575]'>
                                            </th>
                                        </tr>
                                    </thead>
                                    {/* Table body */}
                                    <tbody className="text-sm ">
                                        {
                                            this.state.projectPreviewList.map((project, key) =>
                                            (
                                                <tr key={key} className="custom-tbl-td-contribution web-dashboard border-b border-[#757575] last:border-b-0">
                                                    <td className="pr-2 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575]">
                                                        {`${key + 1}. ${project.projectName}`}
                                                    </td>
                                                    <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575]">
                                                        {project.sgTechSecretariatId}
                                                    </td>
                                                    <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575]">
                                                        {project.projectStartDate}
                                                    </td>
                                                    <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575]">
                                                        {project.projectEndDate}
                                                    </td>
                                                    <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#313984] cursor-pointer underline decoration-[#313984]">
                                                        <Link to={`/AddEditProject/${encryptAES(project.projectId)}`}> View Project</Link>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center justify-between mt-7">
                        <Link
                            to="/Partnerships"
                            className="text-lg font-bold btn btn-gray w-auto text-white"
                            text-lg
                            font-bold
                        >
                            Back
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}