import React from "react";
import ButtonLoader from "../../../common/buttonLoader/ButtonLoader";
import Selector from "../../../dropdown/commonDropdown/Selector";
import ImageCropperWithUpload from "../../../ImageUpload/UploadImage";
import ValidationText from "../../../../utils/validation/ValidationText";
import TinyMCEEditorForGoGlobal from "../../../ckEditor/TinyMCEEditorForGoGlobal";
import DropdownSelect from "../../../dropdown/Dropdown";

const GoGlobalInforamtion = (props) => {
    return (
        <div>
            <div className="2xl:px-10 2xl:py-10 2xl:pt-7 lg:px-7 lg:py-7 lg:pt-5">
                <div className="grid grid-cols-12 gap-6 w-full ">
                    {/* Hide Show Section Start */}
                    <div className="2xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="flield-sec mt-2">
                            <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                                Company Type
                                <span className="text-[#c00000]">*</span>
                                {/* <span className="text-[#c00000]">*</span> */}
                            </h2>
                            <DropdownSelect
                                drpIdentity={"CompanyType"}
                                optionArray={props.companyType}
                                setFilterParameters={props.setFilterParameters}
                                value={props.goGlobalDetails.companyTypeId}
                            //disabled={true}
                            />
                            <ValidationText error={props.validStateForGoGlobal.error.companyTypeId} />
                        </div>
                    </div>
                    <div className="2xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="flield-sec mt-2">
                            <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                                Organisation UEN/License Number/VAT ID
                                <span className="text-[#c00000]">*</span>
                                {/* <span className="text-[#c00000]">*</span> */}
                            </h2>
                            <input
                                className='w-full custom-input'
                                type="text"
                                name="organisationUENLicenseNumberVATID"
                                value={props.goGlobalDetails.organisationUENLicenseNumberVATID}
                                onChange={(e) => props.handleChangeForGoGlobal2(e)}
                            onBlur={() => props.validateGoGlobalField("organisationUENLicenseNumberVATID")}

                            ></input>
                            <ValidationText error={props.validStateForGoGlobal.error.organisationUENLicenseNumberVATID} />
                        </div>
                    </div>

                    <div className="2xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="flield-sec mt-2">
                            <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                                Company Name
                                <span className="text-[#c00000]">*</span>
                                {/* <span className="text-[#c00000]">*</span> */}
                            </h2>
                            <input
                                className='w-full custom-input'
                                type="text"
                                name="companyName"
                                value={props.goGlobalDetails.companyName}
                                onChange={(e) => props.handleChangeForGoGlobal(e)}
                                onBlur={() => props.validateGoGlobalField("companyName")}
                            ></input>
                            <ValidationText error={props.validStateForGoGlobal.error.companyName} />
                        </div>
                    </div>
                    <div className="2xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="flield-sec mt-2">
                            <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                                Company Website
                                <span className="text-[#c00000]">*</span>
                                {/* <span className="text-[#c00000]">*</span> */}
                            </h2>
                            <input
                                className='w-full custom-input'
                                type="text"
                                name={"companyWebsite"}
                                value={props.goGlobalDetails.companyWebsite}
                                onChange={(e) => props.handleChangeForGoGlobal(e)}
                                onBlur={() => props.validateGoGlobalField("companyWebsite")}
                            ></input>
                            <ValidationText error={props.validStateForGoGlobal.error.companyWebsite} />
                        </div>
                    </div>

                    <div className="2xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="flield-sec mt-2">
                            <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                                Company Address
                                <span className="text-[#c00000]">*</span>
                                {/* <span className="text-[#c00000]">*</span> */}
                            </h2>
                            <input
                                className='w-full custom-input'
                                type="text"
                                name={"companyAddress"}
                                value={props.goGlobalDetails.companyAddress}
                                onChange={(e) => props.handleChangeForGoGlobal2(e)}
                            onBlur={() => props.validateGoGlobalField("companyAddress")}

                            ></input>
                            <ValidationText error={props.validStateForGoGlobal.error.companyAddress} />
                        </div>
                    </div>
                    <div className="2xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="flield-sec mt-2">
                            <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                                Business Contact Number
                                <span className="text-[#c00000]">*</span>
                                {/* <span className="text-[#c00000]">*</span> */}
                            </h2>
                            <input
                                className='w-full custom-input'
                                type="text"
                                name={"businessContactNumber"}
                                value={props.goGlobalDetails.businessContactNumber}
                                onChange={(e) => props.handleChangeForGoGlobal2(e)}
                            onBlur={() => props.validateGoGlobalField("businessContactNumber")}

                            ></input>
                            <ValidationText error={props.validStateForGoGlobal.error.businessContactNumber} />
                        </div>
                    </div>
                    <div className="2xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="flield-sec mt-2">
                            <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                                Country With Presence
                                <span className="text-[#c00000]">*</span>
                                {/* <span className="text-[#c00000]">*</span> */}
                            </h2>

                            <Selector
                                options={props.countryWithPresence}
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                allowSelectAll={true}
                                drpIdentity="CountryWithPresence"
                                value={props.goGlobalDetails.selectedCountryWithPresence}
                                onChange={props.handleChangeMultiDropdown}
                            />
                            {/* <ValidationText error={props.validStateForGoGlobal.error.selectedCountryWithPresence} /> */}
                        </div>
                    </div>
                    <div className="2xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="flield-sec mt-2">
                            <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                                List of Service(s)
                                <span className="text-[#c00000]">*</span>
                                {/* <span className="text-[#c00000]">*</span> */}
                            </h2>


                            <Selector
                                options={props.providedServiceList}
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                allowSelectAll={true}
                                value={props.goGlobalDetails.selectedServiceProvided}
                                onChange={props.handleChangeMultiDropdown}
                                drpIdentity="ServiceProvided"
                            />
                            <ValidationText error={props.validStateForGoGlobal.error.selectedServiceProvided} />
                        </div>
                    </div>

                    <div className="2xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="flield-sec mt-2">
                            <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                            Primary sector(s) of main business activity
                                <span className="text-[#c00000]">*</span>
                                {/* <span className="text-[#c00000]">*</span> */}
                            </h2>


                            <Selector
                                options={props.businessSectorList}
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                allowSelectAll={true}
                                value={props.goGlobalDetails.selectedBusinessSector}
                                drpIdentity="BusinessSector"
                                onChange={props.handleChangeMultiDropdown}
                            />
                            <ValidationText error={props.validStateForGoGlobal.error.selectedBusinessSector} />
                        </div>
                    </div>
                    <div className="2xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="flield-sec mt-2">
                            <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                                Type of Business Activity
                                <span className="text-[#c00000]">*</span>
                                {/* <span className="text-[#c00000]">*</span> */}
                            </h2>


                            <Selector
                                options={props.partnerTypeList}
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                allowSelectAll={true}
                                value={props.goGlobalDetails.selectedPartnerType}
                                onChange={props.handleChangeMultiDropdown}
                                drpIdentity="TypeOfPartner"
                            />
                            <ValidationText error={props.validStateForGoGlobal.error.selectedPartnerType} />
                        </div>
                    </div>
                    <div className="2xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="flield-sec mt-2">
                            <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                                First Name
                                <span className="text-[#c00000]">*</span>
                                {/* <span className="text-[#c00000]">*</span> */}
                            </h2>
                            <input
                                className='w-full custom-input'
                                type="text"
                                name={"firstName"}
                                value={props.goGlobalDetails.firstName}
                                onChange={(e) => props.handleChangeForGoGlobal(e)}
                                onBlur={() => props.validateGoGlobalField("firstName")}

                            ></input>
                            <ValidationText error={props.validStateForGoGlobal.error.firstName} />
                        </div>
                    </div>
                    <div className="2xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="flield-sec mt-2">
                            <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                                Last Name
                                <span className="text-[#c00000]">*</span>
                                {/* <span className="text-[#c00000]">*</span> */}
                            </h2>
                            <input
                                className='w-full custom-input'
                                type="text"
                                name={"lastName"}
                                value={props.goGlobalDetails.lastName}
                                onChange={(e) => props.handleChangeForGoGlobal(e)}
                                onBlur={() => props.validateGoGlobalField("lastName")}

                            ></input>
                            <ValidationText error={props.validStateForGoGlobal.error.lastName} />
                        </div>
                    </div>
                    <div className="2xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6 w-full ">
                            <div className="col-span-12">
                                <div className="flield-sec mt-2">
                                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                                        Go Global Contact Email
                                        <span className="text-[#c00000]">*</span>
                                        {/* <span className="text-[#c00000]">*</span> */}
                                    </h2>
                                    <input
                                        className='w-full custom-input'
                                        type="text"
                                        name={"goGlobalContactEmail"}
                                        value={props.goGlobalDetails.goGlobalContactEmail}
                                        onChange={(e) => props.handleChangeForGoGlobal(e)}
                                        onBlur={() => props.validateGoGlobalField("goGlobalContactEmail")}

                                    ></input>
                                    <ValidationText error={props.validStateForGoGlobal.error.goGlobalContactEmail} />
                                </div>
                            </div>
                            <div className="col-span-12">
                                <div className="flield-sec mt-2">
                                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                                        Company Description
                                        <span className="text-[#c00000]">*</span>
                                        {/* <span className="text-[#c00000]">*</span> */}
                                    </h2>
                                    <textarea
                                        className="w-full height-set hidden"
                                        // placeholder="Comments"
                                        id=""
                                        rows="8"
                                        type="text"
                                        name={"companyDescription"}
                                        value={props.goGlobalDetails.companyDescription}
                                        onChange={(e) => props.handleChangeForGoGlobal(e)}
                                        onBlur={() => props.validateGoGlobalField("companyDescription")}
                                    />
                                    {/* <ValidationText error={props.validStateForGoGlobal.error.companyDescription} /> */}

                                    <div className="ck-editor-sec small-editor-t w-full">
                                        <TinyMCEEditorForGoGlobal
                                            key={"fullPageCKEditor"}
                                            onChange={(data) => {
                                                props.setVideoDescription(data);
                                            }}
                                            data={props.goGlobalDetails.companyDescription}
                                            onBlur={(event, editor) => {
                                                props.validateGoGlobalField("companyDescription");
                                            }}
                                        />
                                        <div>
                                            <ValidationText error={props.validStateForGoGlobal.error.companyDescription} />
                                        </div>
                                        <div>
                                            {(props &&
                                                props.charLimit -
                                                props.countCompanyDescription) <= 0 ? (
                                                <div className="text-white bg-[#717171] w-[300px] rounded text-center mb-1">
                                                    You reach the character enter limit
                                                </div>
                                            ) : (
                                                <div
                                                    className={`mb-1 rounded text-[#fff] w-[80px] text-center ${props.charLimit -
                                                        props.countCompanyDescription <
                                                        50
                                                        ? "bg-[#313984]"
                                                        : props.charLimit -
                                                            props.countCompanyDescription <
                                                            100
                                                            ? "bg-[#ffc107]"
                                                            : "bg-blue-500"
                                                        }`}
                                                >
                                                    {props.charLimit -
                                                        props.countCompanyDescription}
                                                    /{props.charLimit}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="2xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="flield-sec mt-2 ">
                            <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                                Company Logo
                                <span className="text-[#c00000]">*</span>
                                {/* <span className="text-[#c00000]">*</span> */}
                            </h2>
                            <div className="w-[320px]">
                                <ImageCropperWithUpload
                                    key="GoGlobal"
                                    keyId="GoGlobal"
                                    height={200}
                                    width={400}
                                    isCheckValidation={true}
                                    MaxFileSize={2097152}
                                    MaxFileSizeMessage={"2MB"}
                                    onSaveImage={props.saveMainImageForGoGlobal}
                                    aspactRatio={400 / 200}
                                    imageIdentity="CorporateMembers"
                                    intitImage={props.goGlobalDetails.companyLogo}
                                    uploadBtn={"Upload Logo"}
                                    dimensionText={"(Format: JPG/PNG Dimension: 400px by 200px Maximum Size: 2MB)"}
                                />

                            </div>
                            <ValidationText error={props.validStateForGoGlobal.error.companyLogo} />
                        </div>
                    </div>
                </div>
            </div >

            {/* Save Button */}
            < div className="grid grid-cols-12 gap-6 pb-10" >
                <div className="xl:col-span-12 lg:col-span-12 col-span-12 px-10">
                    <a
                        className="flex items-center justify-end"
                    //href="/CreateCorporateMembers"
                    >
                        {
                            props && props.isLoading === true ?
                                (
                                    <ButtonLoader />
                                )
                                :
                                (
                                    <button
                                        className="w-32 px-7 py-3 btn-sm btn-red text-xl text-white font-semibold"
                                        onClick={() => props.onSave()}
                                    >
                                        Save
                                    </button>
                                )
                        }
                    </a>
                </div>
            </div >
        </div >
    );
}
export default GoGlobalInforamtion;