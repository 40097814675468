import moment from "moment";
import React, { Component } from "react";
import BatchJobExtractionServices from "../../services/axiosServices/apiServices/BatchJobExtractionServices";
import SwalServices from "../../services/swalServices/SwalServices";
import { saveAs } from "file-saver";
import PageLoader from "../../components/common/pageLoader/PageLoader";

export default class ReconcilationUploadedData extends Component {
  constructor(props) {
    super(props);
    this.batchJobExtractionServices = new BatchJobExtractionServices();
    this.swalServices = new SwalServices();
    this.state = {
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "CreatedAt",
        orderFlag: 0,
        searchText: "",
      },
      isLoading: false,
      currentDate: new Date(),
      reconciliationExcelList: [],
      totalCount: 0,

    };
  }

  componentDidMount = () => {
    this.getAllReconciliationUploadedExcelList()
  }

  getAllReconciliationUploadedExcelList = () => {
    this.setState({ isLoading: true });
    let pagination = { ...this.state.pagination };
    this.batchJobExtractionServices.getAllReconciliationUploadedExcelList(pagination).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let details = response.responseItem.responseContent.itemList;
        this.setState({
          reconciliationExcelList: details,
          totalCount: response.responseItem.responseContent.totalCount,
        })
      }
      else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  }
  
  downloadSheetByReconciliationDataFileId = (membershipReportId) => {
    this.setState({ isLoading: true });
    let request = [];
    request.push(membershipReportId);
    this.batchJobExtractionServices.downloadSheetByReconciliationDataFileId(request).then((response) => {
      if (response.status === 200 && response.data != null) {
        // var filename = `${detail}${formattedDate}`;
        let blob = new Blob([response.data])
        saveAs(blob, `Reconciliation uploaded file` + ".xlsx");
      }
      else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  }
  render() {
    return (
      <div className="main-body-section finance-body-sec">
        <div className="bg-white">
          <div className="grid grid-cols-12 gap-6 w-full items-center justify-center px-8 py-10">
            <div className="2xl:col-span-12 lg:col-span-12 col-span-12 w-full">
              {this.state.isLoading ? <PageLoader /> :
                <table className="w-full mx-auto my-5 fixed_header batch-job-extraction-table border">
                  <thead>
                    <tr className="text-base font-bold flex">
                      <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[40%]">
                        <div className="font-bold tble-th-txt text-left">
                          Uploaded Date
                        </div>
                      </th>
                      <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[40%]">
                        <div className="font-bold tble-th-txt text-left">
                          Uploaded Time
                        </div>
                      </th>
                      <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[40%]">
                        <div className="font-bold tble-th-txt text-left">
                          Number of Records
                        </div>
                      </th>
                      <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[40%]">
                        <div className="font-bold tble-th-txt text-left">
                          Success Records
                        </div>
                      </th>
                      <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[40%]">
                        <div className="font-bold tble-th-txt text-left">
                          Failed Records
                        </div>
                      </th>

                      <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[20%]">
                        <div className="font-bold tble-th-txt text-center">Action</div>
                      </th>
                    </tr>
                  </thead>

                  <tbody className="scrollit text-sm custom-tbody">
                    {this.state.reconciliationExcelList.map((e, key) => {
                      return (
                        <tr className="custom-tbl-td flex items-center" key={key}>
                          <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[40%] ">
                            {moment(e.reconciliationFileUploadDate).format("DD MMM YYYY")}
                          </td>
                          <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[40%] ">
                            {moment(e.reconciliationFileUploadDate).format("hh:mm A")}
                          </td>
                          <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[40%]">
                            {e.totalCount}
                          </td>
                          <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[40%]">
                            {e.passCount}
                          </td>
                          <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[40%]">
                            {e.failCount}
                          </td>

                          <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[20%]">
                            <button
                              className="action-btn mx-auto flex items-center"
                              aria-controls="add-edit-modal"
                              onClick={() => {
                                this.downloadSheetByReconciliationDataFileId(e.fileName);
                              }}
                            >
                              <p className="ml-2 tool-tip-txt underline text-[#81a3b8]">
                                Download
                              </p>
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              }
            </div>

          </div>
        </div>

      </div>
    );
  }
}
