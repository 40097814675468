import React, { Component } from 'react'
import { Link } from "react-router-dom";
import DropdownSelect from "../../../components/dropdown/Dropdown";
import DropdownAction from "../../../components/dropdown/DropdownAction";
import SwalServices from '../../../services/swalServices/SwalServices';
import MembershipCorporateServices from '../../../services/axiosServices/apiServices/MembershipCorporateServices'
import Table from '../../../components/table/Table';
import ActionContainer from '../../../components/UIComponent/ActionTableComponent/actionContainer';
import { Navigate } from 'react-router-dom';
import { CommonSuccessMessages } from "../../../utils/Messages";
import { encryptAES } from '../../../utils/Encryption';
//import CommonServices from "../../../services/axiosServices/apiServices/CommonServices";


export default class ViewCorporateMembers extends Component {
  constructor(props) {
    super(props)
    this.membershipCorporateServices = new MembershipCorporateServices();
    //this.CommonServices = new CommonServices();
    this.swalServices = new SwalServices();
    this.state = {
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
        searchByUENNumber: "",
        statusId: 0
      },
      corporateMemberList: [],
      totalResultes: 0,
      statuses: [],
      actionDropdown: ['Activate', 'Deactivate', 'Update', 'Delete']
    }
  }


  setFilterParameters = (id) => {
    let detail = this.state.pagination;
    detail.pageNo = 1;
    detail.statusId = id;
    this.setState({ pagination: { ...detail } });
    this.getMembershipCorporateList();
  }

  searchByName = (value, identity) => {
    let detail = this.state.pagination;
    if (identity === "searchByCompanyName") {
      detail.pageNo = 1;
      detail.pageSize = 10;
      detail.searchText = value;
      this.setState({ pagination: { ...detail } });
    }
    else if (identity === "searchByUENNumber") {
      detail.pageNo = 1;
      detail.pageSize = 10;
      detail.searchByUENNumber = value;
      this.setState({ pagination: { ...detail } });
    }
    this.getMembershipCorporateList();
  }

  setPagination = (newPagination) => {
    this.setState({ pagination: newPagination });
    this.getMembershipCorporateList();
  }

  onClose = () => {
    this.setState({ setDropdownOpen: false })
  }

  actionClick = (index, value, option) => {
    if (value && value > 0 && option === 'Deactivate') {
      this.deActivateCorporateMemnber(value);
    }
    if (value && value > 0 && option === 'Activate') {
      this.activeInActiveCorporateMember(value);
    }
    if (value && value > 0 && option === 'Update') {
      this.updateCorporateMemberUpdate(value);
    }
    if (value && value > 0 && option === 'Delete') {
      this.deleteCorporateMember(value);
    }
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ setDropdownOpen: false })
    }
  }
  deActivateCorporateMemnber = (value) => {
    let request = {
      id: value,
      statusId: 7
    }
    this.setState({ isLoading: true });
    this.membershipCorporateServices.activeInActiveCorporateMember(request).then((response) => {
      if (response.statusCode === 200) {
        this.swalServices.Success("Corporate member deactivated Successfully.");
        this.getMembershipCorporateList();
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    })
  }

  activeInActiveCorporateMember = (value) => {
    let request = {
      id: value,
      statusId: 8
    }
    this.setState({ isLoading: true });
    this.membershipCorporateServices.activeInActiveCorporateMember(request).then((response) => {
      if (response.statusCode === 200) {
        this.swalServices.Success("Corporate member activated Successfully.");
        this.getMembershipCorporateList();
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    })
  }

  updateCorporateMemberUpdate = (value) => {
    if (value && value > 0) {
      this.setState({ route: '/CreateCorporateMembers/CorporateMemberId?=' + encryptAES(value) });
    }
  }

  deleteCorporateMember = (value) => {
    let request = {
      id: value,
      deletedBy: 1,
      appId: 115
    }
    this.swalServices.Confirm(CommonSuccessMessages.Confirm_Question.replace("{0}", "Member"), CommonSuccessMessages.Confirm_Delete.replace("{0}", "Member"),
      CommonSuccessMessages.Yes_Text, CommonSuccessMessages.No_Text).then((response) => {
        if (response) {
          this.membershipCorporateServices.deleteCorporateMember(request).then((response) => {
            if (response.statusCode === 200) {
              this.swalServices.Success("Deleted");
              this.getMembershipCorporateList();
            } else {
              this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
          });
        }
      });
  }

  actions = (element, index, value) => {
    return element !== "corporateMemberId" ? null : (
      <td>
        <ActionContainer>
          <DropdownAction
            key={index}
            index={index}
            value={value}
            options={this.state.actionDropdown}
            align="right"
            onOptionClick={this.actionClick.bind(this)}
          />
        </ActionContainer>
      </td>
    );
  };

  getAllCorporateStatus = () => {
    this.membershipCorporateServices.getAllCorporateStatus().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({ statuses: response.responseItem.responseContent }, () => this.getMembershipCorporateList());
      }
      else {
        this.getMembershipCorporateList();
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    })
  }

  getMembershipCorporateList = (pagination = this.state.pagination) => {
    this.setState({ isLoading: true });

    this.membershipCorporateServices.getMembershipCorporateList(pagination).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({ corporateMemberList: response.responseItem.responseContent.itemList });
        this.setState({ totalResultes: response.responseItem.responseContent.totalCount })
      }
      else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    })
  }

  componentDidMount() {

    this.getAllCorporateStatus();
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  render() {
    if (this.state.route != null) {
      return <Navigate to={this.state.route} />;
    }
    return (
      <div className="main-body-section" >
        <div className="flex">
          <div className="relative flex flex-col flex-1 "></div>
        </div>
        <main className="pb-10">
          <div className="custom-card shadow-lg mb-10">
            <div className="filter-info grid grid-cols-12 gap-6">
              <div className="filter-types 2xl:col-span-4 lg:col-span-4 col-span-12">
                <h2
                  className="filter-lable block font-medium theme-color min-h-30"
                  htmlFor="default"
                >
                  Search by Company Name
                </h2>
                <input
                  id="default"
                  className="form-input rounded-none w-full shadow-red py-3 text-lg"
                  type="text"
                  onChange={(e) => this.searchByName(e.target.value, 'searchByCompanyName')}
                />
              </div>
              <div className="filter-types 2xl:col-span-4 lg:col-span-4 col-span-12">
                <h2
                  className="filter-lable block font-medium theme-color min-h-30"
                  htmlFor="default"
                >
                  Search by UEN Number
                </h2>
                <input
                  id="default"
                  className="form-input rounded-none w-full shadow-red py-3 text-lg"
                  type="text"
                  onChange={(e) => this.searchByName(e.target.value, "searchByUENNumber")}
                />
              </div>

              <div className="filter-types 2xl:col-span-4 lg:col-span-4 col-span-12">
                <h2
                  className="pl-6 block text-xl font-medium  theme-color min-h-30 "
                  htmlFor="default"
                >
                  Status
                </h2>
                <div className="pl-6 border-l-[1px] border-[#757575]">
                  <DropdownSelect
                    //drpIdentity={"MembershipType"}
                    optionArray={this.state.statuses}
                    setFilterParameters={this.setFilterParameters}
                    value={this.state.pagination.statusId}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full mx-auto">
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              <div className="mb-4 sm:mb-0">
                <h1 className="table-title-txt theme-color font-bold">
                  Total : {this.state.totalResultes}
                </h1>
              </div>
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-3">
                <Link to="/CreateCorporateMembers">
                  <button className="btn btn-red text-white">
                    <span className="text-xl text-white font-semibold tracking-normal">
                      {" "}
                      Create New{" "}
                    </span>
                  </button>
                </Link>
              </div>
            </div>

            <div className="relative">
              <div>
                <div className="" onMouseLeave={() => this.onClose()} >
                  <Table columns={[{ name: 'siisAccountId', title: 'SMF Account Id' }, { name: 'companyName', title: 'Company Name' }, { name: 'companyUEN', title: 'UEN Number' }, { name: 'isFeatured', title: 'Featured' }, { name: 'statusName', title: 'status' }, { name: 'corporateMemberId', title: 'Actions' }]}
                    rows={this.state.corporateMemberList}
                    isLoading={this.state.isLoading}
                    sortingColumns={["companyName", "membershipTypeName", "statusName"]}
                    pagination={this.state.pagination}
                    totalResultes={this.state.totalResultes}
                    totalCount={this.state.totalResultes}
                    setPagination={this.setPagination.bind(this)}
                    customScope={[
                      {
                        column: 'corporateMemberId',
                        renderTableData: this.actions.bind(this)
                      },
                    ]} />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    )
  }
}