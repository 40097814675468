import { useRef, useEffect} from "react";



export default function ActionDropdownButton(props) {

    const wrapperRef = useRef(null);
    const dropdown = useRef(null);


    useEffect(() => {

        const clickHandler = ({ target }) => {
            if (!dropdown.current) return;
            if (!props.setDropdownOpen || dropdown.current.contains(target) || wrapperRef.current.contains(target)) return;
            props.setDropdownOpen = false;
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    });


    return (
        <div className="action-btn relative">
            <button

                className="custom-action-btn rounded btn bg-white border-slate-200 hover:border-slate-300 theme-color px-4 py-3 hover:text-[#313984] mr-3"
                aria-haspopup="true"
                onClick={() => props.onDropdownAction(props.index)}
                aria-expanded={props.setDropdownOpen}
            >
                <span className="sr-only">Filter</span><wbr />
                <svg className="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="16px" height="20px" viewBox="0 0 52 52" enableBackground="new 0 0 52 52" >
                    <path d="M8.3,14h35.4c1,0,1.7,1.3,0.9,2.2L27.3,37.4c-0.6,0.8-1.9,0.8-2.5,0L7.3,16.2C6.6,15.3,7.2,14,8.3,14z" />
                </svg>
            </button>
            

            {props.setDropdownOpen && props.setDropdownIndex === props.index ?

                <div ref={wrapperRef} className="action-desc absolute bg-white shadow-md z-[11] right-[-5px] rounded-md py-3 bottom-[-10px]" key={"drp_" + props.index} >
                    <ul className="">
                        
                        <li className="py-1 px-3"
                            onClick={(e) => props.onActionClick1(props.value,e)}
                        >
                            <label className="flex items-center">
                                <span className="text-sm font-medium ml-2 theme-color hover:text-[#313984]">{props.text1}</span>
                            </label>
                        </li>
                        {props.text2 ? 
                        
                        <li className="py-1 px-3"
                            onClick={(e) => props.onActionClick2(props.value,e)}
                        >
                            <label className="flex items-center">
                                <span className="text-sm font-medium ml-2 theme-color hover:text-[#313984]">{props.text2}</span>
                            </label>
                        </li> 

                        : null
                        }
                          {props.text3 ? 
                        
                        <li className="py-1 px-3"
                            onClick={(e) => props.onActionClick3(props.value,e)}
                        >
                            <label className="flex items-center">
                                <span className="text-sm font-medium ml-2 theme-color hover:text-[#313984]">{props.text3}</span>
                            </label>
                        </li> 

                        : null
                        }
                          
                    </ul>
                </div>
                : null
            }

        </div>
    )
}
