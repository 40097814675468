// Import react libraries and components
import React, { Component } from "react";
import TextInput from "../../components/UIComponent/TextInput";
import DropdownSelect from "../../components/dropdown/Dropdown";
import {
    CommonValidationMessages,
    ErrorMessage,
} from "../../utils/Messages";
import { isValidForm, validate } from "../../utils/validation/CommonValidator";
import ValidationText from "../../utils/validation/ValidationText";
import { Navigate } from "react-router-dom";
import Selector from "../../components/dropdown/commonDropdown/Selector";
import ImageCropperWithUpload from "../../components/ImageUpload/UploadImage";

//import api services
import GoGlobalServices from "../../services/axiosServices/apiServices/GoGlobalService";
import SwalServices from "../../services/swalServices/SwalServices";
import CommonServices from "../../services/axiosServices/apiServices/CommonServices";
import { checkParameters } from "../../utils/Encryption";
import ButtonLoader from "../../components/common/buttonLoader/ButtonLoader";
import TinyMCEEditorForGoGlobal from "../../components/ckEditor/TinyMCEEditorForGoGlobal";

export default class ViewGoGlobalAccount extends Component {
    constructor(props) {
        super(props);
        this.GoGlobalServices = new GoGlobalServices();
        this.CommonServices = new CommonServices();
        this.SwalServices = new SwalServices();
        this.state = {
            isLoading: false,
            redirect: null,
            regionList: [],
            countryList: [],
            cityList: [],
            businessSectorList: [],
            serviceProvidedList: [],
            typeOfPartnerList: [],

            goGlobalInformation: {
                accountActiveStatusId: "",
                businessSector: "",
                city: "",
                companyDescription: "",
                companyLogo: "",
                companyName: "",
                companyWebsite: "",
                corporateMemberId: 0,
                countryId: 0,
                countryName: "",
                goGlobalContactName: "",
                goGlobalContactEmail: "",
                goGlobalMemberId: 0,
                region: "",
                serviceProvided: "",
                typeOfPartner: 0,
                selectedRegionArray: [],
                selectedCountryName: [],
                selectedCity: [],
                selectedBusinessSector: [],
                selectedServiceProvided: [],
                selectedPartnerType: [],
                companyTypeId: 0,
                organisationUENLicenseNumberVATID: "",
                countryWithPresence: "",
                businessContactNumber: "",
                companyAddress: "",
                firstName: "",
                lastName: "",
                selectedCountryWithPresence: [],
            },
            companyType: [],
            countryWithPresence: [],
            goGlobalMemberId: 0,
            charLimit: 1000,
            countCompanyDescription: 0,
            validationRule: {
                companyTypeId: [
                    {
                        type: "require",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "company name"
                        ),
                    },
                ],
                companyName: [
                    {
                        type: "require",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "company name"
                        ),
                    },
                ],
                companyWebsite: [
                    {
                        type: "require",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "company website"
                        ),
                    },
                    {
                        type: "isValidateURL",
                        message: CommonValidationMessages.FieldRequired.replace("{0}", "proper website url"),
                    },
                ],
                // selectedRegionArray: [
                //     {
                //         type: "require",
                //         message: CommonValidationMessages.SelectRequired.replace(
                //             "{0}",
                //             "region"
                //         ),
                //     },
                // ],
                selectedCountryWithPresence: [
                    {
                        type: "require",
                        message: CommonValidationMessages.SelectRequired.replace(
                            "{0}",
                            "country with presence"
                        ),
                    },
                ],
                // selectedCity: [
                //     {
                //         type: "require",
                //         message: CommonValidationMessages.SelectRequired.replace(
                //             "{0}",
                //             "city"
                //         ),
                //     },
                // ],
                selectedBusinessSector: [
                    {
                        type: "require",
                        message: CommonValidationMessages.SelectRequired.replace(
                            "{0}",
                            "primary sector(s) of main business activity"
                        ),
                    },
                ],
                selectedServiceProvided: [
                    {
                        type: "require",
                        message: CommonValidationMessages.SelectRequired.replace(
                            "{0}",
                            "provided services"
                        ),
                    },
                ],
                selectedPartnerType: [
                    {
                        type: "require",
                        message: CommonValidationMessages.SelectRequired.replace(
                            "{0}",
                            "type of partner"
                        ),
                    },
                ],
                companyDescription: [
                    {
                        type: "require",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "company description"
                        ),
                    },
                ],
                firstName: [
                    {
                        type: "require",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "first name"
                        ),
                    },
                ],
                lastName: [
                    {
                        type: "require",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "last name"
                        ),
                    },
                ],
                goGlobalContactEmail: [
                    {
                        type: "require",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "go global contact email"
                        ),
                    },
                ],
                companyLogo: [
                    {
                        type: "require",
                        message: CommonValidationMessages.SelectRequired.replace("{0}", "company logo"),
                    }
                ],
                organisationUENLicenseNumberVATID: [
                    {
                        type: "require",
                        message: CommonValidationMessages.FieldRequired.replace("{0}", "organisation UEN"),
                    }
                ],
                businessContactNumber: [
                    {
                        type: "require",
                        message: CommonValidationMessages.FieldRequired.replace("{0}", "business contact number"),
                    }
                ],
                companyAddress: [
                    {
                        type: "require",
                        message: CommonValidationMessages.FieldRequired.replace("{0}", "company address"),
                    }
                ]
            },
            validState: {
                isValid: true,
                error: {},
            },
            isApprovingAccount: false,
            isRejectingAccount: false,
            currentAccountStatusId: 0,
        };
    }

    //Method declaration section start
    componentDidMount() {
        let params = new URLSearchParams(window.location.search);
        let goGlobalMemberId = params.get("");
        if (goGlobalMemberId && (goGlobalMemberId = checkParameters(goGlobalMemberId, "Number"))) {
            if (goGlobalMemberId > 0) {
                this.setState({ goGlobalMemberId: goGlobalMemberId }, () => this.getAllDropDownsForGoGlobalMembers());
            }
        }
        this.getAllDropDownsForGoGlobalMembers();
    }

    // Get go global account details by id
    getGoGlobalMemberDetailsById = (id) => {
        if (this.state.goGlobalMemberId > 0 && this.state.goGlobalMemberId != null) {

            this.GoGlobalServices.getGoGlobalMemberDetailsById(id).then((response) => {
                if (response.statusCode === 200 && response.responseItem != null) {

                    let res = { ...this.state.goGlobalInformation };
                    res.accountActiveStatusId = response.responseItem.responseContent.accountActiveStatusId;
                    res.businessSector = response.responseItem.responseContent.businessSector;
                    //res.city = response.responseItem.responseContent.city;
                    res.companyDescription = response.responseItem.responseContent.companyDescription;
                    res.companyLogo = response.responseItem.responseContent.companyLogo;
                    res.companyName = response.responseItem.responseContent.companyName;
                    res.companyWebsite = response.responseItem.responseContent.companyWebsite;
                    res.corporateMemberId = response.responseItem.responseContent.corporateMemberId;
                    //res.countryId = response.responseItem.responseContent.countryId;
                    //res.countryName = response.responseItem.responseContent.countryName;
                    //res.goGlobalContactEmail = response.responseItem.responseContent.goGlobalContactEmail;
                    //res.goGlobalContactName = response.responseItem.responseContent.goGlobalContactName;
                    res.goGlobalContactEmail = response.responseItem.responseContent.goGlobalContactEmail;
                    res.goGlobalMemberId = response.responseItem.responseContent.goGlobalMemberId;
                    //res.region = response.responseItem.responseContent.region;
                    res.serviceProvided = response.responseItem.responseContent.serviceProvided;
                    res.typeOfPartner = response.responseItem.responseContent.typeOfPartner;
                    res.companyTypeId = response.responseItem.responseContent.companyTypeId;
                    res.firstName = response.responseItem.responseContent.firstName;
                    res.lastName = response.responseItem.responseContent.lastName;
                    res.organisationUENLicenseNumberVATID = response.responseItem.responseContent.organisationUENLicenseNumberVATID;
                    res.companyAddress = response.responseItem.responseContent.companyAddress;
                    res.businessContactNumber = response.responseItem.responseContent.businessContactNumber;
                    res.countryWithPresence = response.responseItem.responseContent.countryWithPresence;

                    // var array = (res.region && res.region !== '0') ? res.region.split(',') : [];
                    // res.selectedRegionArray = [];
                    // array.forEach(topic => {
                    //     res.selectedRegionArray.push(this.state.regionList.find(topics => topics.label === topic));
                    // });

                    let array = (res.businessSector && res.businessSector !== '0') ? res.businessSector.split(',') : [];
                    res.selectedBusinessSector = [];
                    array.forEach(topic => {
                        res.selectedBusinessSector.push(this.state.businessSectorList.find(topics => topics.value === Number(topic)));
                    });

                    array = (res.serviceProvided && res.serviceProvided !== '0') ? res.serviceProvided.split(',') : [];
                    res.selectedServiceProvided = [];
                    array.forEach(topic => {
                        res.selectedServiceProvided.push(this.state.serviceProvidedList.find(topics => topics.value === Number(topic)));
                    });

                    array = (res.typeOfPartner && res.typeOfPartner !== '0') ? res.typeOfPartner.split(',') : [];
                    res.selectedPartnerType = [];
                    array.forEach(topic => {
                        res.selectedPartnerType.push(this.state.typeOfPartnerList.find(topics => topics.value === Number(topic)));
                    });

                    array = (res.countryWithPresence && res.countryWithPresence !== '0') ? res.countryWithPresence.split(',') : [];
                    res.selectedCountryWithPresence = [];
                    array.forEach(topic => {
                        res.selectedCountryWithPresence.push(this.state.countryWithPresence.find(topics => topics.value === Number(topic)));
                    });

                    // var array2 = (res.countryName && res.countryName !== '') ? res.countryName.split(',') : [];
                    // res.selectedCountryName = [];
                    // array2.forEach(topic => {
                    //     let arr = {}
                    //     res.selectedCountryName.push({ value : 1 ,label : topic });
                    // });

                    //let selectedCountry = response.responseItem.responseContent.companyName;
                    this.setState({ goGlobalInformation: res, currentAccountStatusId: res.accountActiveStatusId }, () => {
                        //this.getDropdownDataByName('Region', '');
                        //this.getDropdownDataByName('Country', res.countryName);

                        let str = this.state.goGlobalInformation.companyDescription.replaceAll("<p>", "")
                            .replaceAll("</p>", "")
                            .replaceAll("\n", "")
                            .replaceAll("&nbsp;", " ").replaceAll("</br>", " ");
                        this.setState({ countCompanyDescription: str.length })

                    });
                } else {
                    this.SwalServices.Error(response.message);
                }
            }
            );
        }
    };

    getDropdownDataByName = (identity, selected) => {
        //if (identity === 'Region') {    
        //if (identity === 'Region') {
        let selectedList = [];
        if (identity === 'Region') {
            selectedList = [...this.state.goGlobalInformation.selectedRegionArray];
        } else {
            selectedList = [...this.state.goGlobalInformation.selectedCountryName];
        }
        //let selectedRegionList = [...this.state.goGlobalInformation.selectedRegionArray];
        selectedList?.map((x, keyId) => {
            if (keyId === 0) { selected = x.label; }
            else { selected = selected + ',' + x.label; }
        });
        selected = selected || '';
        //}
        if (identity && selected && selected !== '') {
            let request = {
                name: selected,
                isRegion: identity === 'Region' ? 1 : 0,
            };
            this.GoGlobalServices.getDropdownDataByName(request).then((response) => {
                if (response.responseItem.responseContent != null) {
                    if (identity === 'Region') {
                        let countries = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "Country");
                        countries.map(function (item) { delete item["dropdownName"]; });
                        countries = countries.map((p) => ({ value: p.id, label: p.name }));
                        this.setState({ countryList: countries }, () => {
                            let data = { ...this.state.goGlobalInformation };
                            let array = data.countryName ? data.countryName.split(',') : [];
                            data.selectedCountryName = [];
                            array.forEach(topic => {
                                data.selectedCountryName.push(this.state.countryList.find(topics => topics.label === topic));
                            });
                            this.setState({ goGlobalInformation: data }, () => {
                                this.getDropdownDataByName('Country', data.countryName);
                            })
                        });

                    } else if (identity === 'Country') {
                        let city = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "City");
                        city.map(function (item) { delete item["dropdownName"]; });
                        city = city.map((p) => ({ value: p.id, label: p.name }));
                        this.setState({ cityList: city }, () => {
                            let data = { ...this.state.goGlobalInformation };
                            let array = data.city ? data.city.split(',') : [];
                            data.selectedCity = [];
                            array.forEach(topic => {
                                data.selectedCity.push(this.state.cityList.find(topics => topics.label === topic));
                            });
                            this.setState({ goGlobalInformation: data });
                        });
                    }
                } else {
                    this.swalServices.Error("Something went wrong.");
                }
            });
        }
    }

    approveGoGlobalMemberDetails = (id) => {
        // if (this.state.corporateMemberId) {

        let request = this.state.goGlobalInformation;
        request.goGlobalMemberId = this.state.goGlobalMemberId;
        request.accountActiveStatusId = id;
        //request.region = "";
        request.businessSector = "";
        request.serviceProvided = "";

        if (this.state.goGlobalInformation.selectedServiceProvided.length > 0) {
            let arr2 = this.state.goGlobalInformation.selectedServiceProvided.map(function (item) {
                delete item.bad;
                return item.value;
            });
            request.serviceProvided = arr2.join([',']);
        } else {
            request.serviceProvided = "";
        }

        if (this.state.goGlobalInformation.selectedPartnerType.length > 0) {
            let arr2 = this.state.goGlobalInformation.selectedPartnerType.map(function (item) {
                delete item.bad;
                return item.value;
            });
            request.typeOfPartner = arr2.join([',']);
        } else {
            request.typeOfPartner = "";
        }



        if (this.state.goGlobalInformation.selectedBusinessSector.length > 0) {
            let arr3 = this.state.goGlobalInformation.selectedBusinessSector.map(function (item) {
                delete item.bad;
                return item.value;
            });
            request.businessSector = arr3.join([',']);
        } else {
            request.businessSector = "";
        }

        if (this.state.goGlobalInformation.selectedCountryWithPresence.length > 0) {
            let arr1 = this.state.goGlobalInformation.selectedCountryWithPresence.map(function (item) {
                delete item.bad;
                return item.value;
            });
            request.countryWithPresence = arr1.join([',']);
        } else {
            request.countryWithPresence = "";
        }

        if (this.isValidateAllFields()) {
            if (this.state.countCompanyDescription <= this.state.charLimit) {
                if (id === 4) {
                    this.setState({ isApprovingAccount: true });
                } else if (id === 12) {
                    this.setState({ isRejectingAccount: true });
                }
                this.GoGlobalServices.approveGoGlobalMemberDetails(request).then((response) => {
                    if (response.statusCode === 200 && response.responseItem != null) {
                        if (response.responseItem.responseContent.keyId === 0 && response.responseItem.responseContent.errorType === 'exists') {
                            this.SwalServices.Error('Go Global member already exist');
                        } else {
                            this.SwalServices.Success("Go Global account status has been updated successfully");
                            this.setState({ redirect: '/goGlobal/GoGlobalAccountApproval' });
                            this.setState({ isApprovingAccount: false });
                            this.setState({ isRejectingAccount: false });
                        }
                    }
                    else {
                        this.SwalServices.Error(ErrorMessage.DefaultMessage);
                        this.setState({ isApprovingAccount: false });
                        this.setState({ isRejectingAccount: false });

                    }

                })
            } else {
                this.SwalServices.Error('You reach the character enter limit');
            }
        }
        // } 
        // else {
        //     this.swalServices.Warning("Please enter go global account details.");
        // }
    }

    getAllDropDownsForGoGlobalMembers = () => {
        this.setState({ isLoading: true });
        this.GoGlobalServices.getAllDropDownsForGoGlobalMembers().then(
            (response) => {
                if (response.statusCode === 200 && response.responseItem != null) {

                    // var Country = response.responseItem.responseContent.filter(
                    //     (drp) => drp.dropdownName === "Country"
                    // );

                    // var City = response.responseItem.responseContent.filter(
                    //     (drp) => drp.dropdownName === "City"
                    // );

                    // var Region = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "Region");
                    // Region.map(function (item) {
                    //     return delete item["dropdownName"];
                    // });
                    // Region = Region.map((p) => ({ value: p.id, label: p.name }));

                    let BusinessSectors = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "BusinessSectors");
                    BusinessSectors.map(function (item) {
                        return delete item["dropdownName"];
                    });
                    BusinessSectors = BusinessSectors.map((p) => ({ value: p.id, label: p.name }));


                    let ServiceProvided = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "Services");
                    ServiceProvided.map(function (item) {
                        return delete item["dropdownName"];
                    });
                    ServiceProvided = ServiceProvided.map((p) => ({ value: p.id, label: p.name }));

                    let PartnerType = response.responseItem.responseContent.filter(
                        (drp) => drp.dropdownName === "PartnerType"
                    );
                    PartnerType = PartnerType.map((p) => ({ value: p.id, label: p.name }));

                    let companyType = response.responseItem.responseContent.filter(
                        (drp) => drp.dropdownName === "CompanyType"
                    );

                    let countryWithPresence = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "CountryWithPresence");
                    countryWithPresence.map(function (item) { delete item["dropdownName"]; });
                    countryWithPresence = countryWithPresence.map((p) => ({ value: p.id, label: p.name }));
                    //this.setState({ countryWithPresence: countryWithPresence });

                    // var companyType = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "CompanyType");
                    // companyType.map(function (item) { delete item["dropdownName"]; });
                    // companyType = companyType.map((p) => ({ value: p.id, label: p.name }));
                    // this.setState({ companyType: companyType }
                    //     //, () => {
                    //     // let data = this.state.accountDetails;
                    //     // data.companyTypeId = this.state.companyType[0].value;
                    //     // data.selectedCompanyType = this.state.companyType[0];
                    //     // this.setState({ accountDetails: data })
                    //     //}
                    // );

                    this.setState({
                        //regionList: Region,
                        //countryList: Country,
                        //cityList: City,
                        businessSectorList: BusinessSectors,
                        serviceProvidedList: ServiceProvided,
                        typeOfPartnerList: PartnerType,
                        countryWithPresence: countryWithPresence,
                        companyType: companyType,
                    }, () => this.getGoGlobalMemberDetailsById(this.state.goGlobalMemberId));
                } else {
                    this.SwalServices.Error(response.message);
                }
                this.setState({ isLoading: false });
            }
        );
    };

    saveMainImage = (file, keyId, imageIdentity) => {
        file.storagePath = imageIdentity
        this.CommonServices.storeBase64Image(file).then((response) => {
            if (response != null) {
                this.SwalServices.Success("File Uploaded");
                let detail = this.state.goGlobalInformation;
                if (file.storagePath === "CorporateMembers") {
                    detail["companyLogo"] = response.fileName;
                    this.setState({ goGlobalInformation: { ...detail } },
                    );
                }
            }
            else {
                this.SwalServices.Error(response.message);
            }
        })
    }
    // API method declaration end

    // Internal method declaration start

    // Field validation method declaration start
    validateField = (key) => {
        const newValidState = validate(
            key,
            this.state.goGlobalInformation,
            this.state.validationRule,
            this.state.validState
        );
        this.setState({ validState: newValidState });
    };

    isValidateAllFields = () => {
        const newValidState = isValidForm(
            this.state.goGlobalInformation,
            this.state.validationRule,
            this.state.validState
        );
        this.setState({ validState: newValidState });
        return newValidState.isValid;
    };
    // Field validation method declaration end

    // Go global information on change handle method
    handleChange(event) {
        const value = event.target.value;
        const name = event.target.name;
        let detail = this.state.goGlobalInformation;
        detail[name] = value;
        this.setState({ goGlobalInformation: { ...detail } });
    }

    // Handle multiselect dropdown method 
    handleChangeMultiDropdown(selected, identity) {
        let details = { ...this.state.goGlobalInformation };

        if (identity === "businessSectors") {
            details.selectedBusinessSector = selected;
            this.setState({ goGlobalInformation: details },
                () => this.validateField("selectedBusinessSector"));
        }
        else if (identity === "Service_Provided") {
            details.selectedServiceProvided = selected;
            this.setState({ goGlobalInformation: details },
                () => this.validateField("selectedServiceProvided"));
        }
        else if (identity === "typeOfPartner") {
            details.selectedPartnerType = selected;
            this.setState({ goGlobalInformation: details },
                () => this.validateField("selectedPartnerType"));
        }
        else if (identity === "Selected_Region") {
            //if (selected.length > 0) {
            details.selectedRegionArray = selected;
            details.countryName = "";
            details.countryId = 0;
            details.city = "";
            details.selectedCountryName = [];
            details.selectedCity = [];
            this.setState({ goGlobalInformation: details, countryList: [], cityList: [] },
                () => { this.validateField("selectedRegionArray"); this.getDropdownDataByName('Region', ''); });
        } else if (identity === "Country") {
            details.city = "";
            details.selectedCity = [];
            details.selectedCountryName = selected;
            this.setState({ goGlobalInformation: details, cityList: [] }, () => {
                this.validateField("selectedCountryName");
                this.getDropdownDataByName(identity, '');
            });
        } else if (identity === "City") {
            details.selectedCity = selected;
            this.setState({ goGlobalInformation: details }, () => {
                this.validateField("selectedCity");
            });
        } else if (identity === "CountryWithPresence") {
            details.selectedCountryWithPresence = selected;
            this.setState({ goGlobalInformation: details }, () => {
                this.validateField("selectedCountryWithPresence");
            });
        }
    }

    // All dropdown handle method
    setFilterParameters = (id, drpIdentity) => {
        let detail = this.state.goGlobalInformation;

        // if (drpIdentity === "countryList") {
        //     detail["countryId"] = id;
        //     this.validateField("countryId");
        // }
        if (drpIdentity === "CompanyType") {
            detail["companyTypeId"] = id;
            this.validateField("companyTypeId");
        }
        if (drpIdentity === "typeOfPartner") {
            detail["typeOfPartner"] = id;
            this.validateField("typeOfPartner");
        }
        this.setState({ goGlobalInformation: detail });
    };

     setVideoDescription = (data) => {
        if (typeof data === 'string') {
            let detail = this.state.goGlobalInformation;
            // var re = new RegExp('<a href="([^"]+)"', "g");
            // var result = data.replace(
            //   re,
            //   '<a href=\'$1\' target="_blank" rel="noopener noreferrer"'
            // );
            detail["companyDescription"] = data;
            this.setState({ goGlobalInformation: { ...detail } }, () => {
                let str = this.state.goGlobalInformation.companyDescription.replaceAll("<p>", "")
                    .replaceAll("</p>", "")
                    .replaceAll("\n", "")
                    .replaceAll("&nbsp;", " ").replaceAll("</br>", " ");
                this.setState({ countCompanyDescription: str.length })
            });
        }
    };
    // Method declaration end

    render() {
        if (this.state.redirect != null) {
            return <Navigate to={this.state.redirect} />;
        }
        return (
            <div className="main-body-section global-account-sec">
                <div className="bg-white">
                    <div className=" heading-part px-7 py-4 border-b">
                        <h4 className="small-title font-bold theme-color">
                            Account Details
                        </h4>
                    </div>
                    <div className="px-8 pt-10 account-fields">
                        <div className="w-full 2xl:px-8 lg:px-4 px-4 mt-5">
                            <div className="grid grid-cols-12 gap-6 w-full ">
                                <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                                    <div className="grid grid-cols-12 gap-6 w-full ">
                                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                            <h2 className="text-xl text-[#757575] font-bold mb-3">
                                                Company Type{" "}
                                                <span className="text-[#c00000]">*</span>
                                            </h2>
                                            <div className="flex items-center">
                                                <div className="w-full">
                                                    <DropdownSelect
                                                        drpIdentity={"CompanyType"}
                                                        optionArray={this.state.companyType}
                                                        setFilterParameters={this.setFilterParameters.bind(this)}
                                                        value={this.state.goGlobalInformation.companyTypeId}
                                                    //disabled={true}
                                                    />
                                                    {/* <div>
                                                        <ValidationText
                                                            error={props.validState.error.financeAccountCodeId}
                                                        />
                                                    </div> */}
                                                </div>
                                            </div>
                                            <ValidationText error={this.state.validState.error.companyTypeId} />
                                        </div>
                                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                            <h2 className="text-xl text-[#757575] font-bold mb-3">
                                                Organisation UEN/License Number/VAT ID{" "}
                                                <span className="text-[#c00000]">*</span>
                                            </h2>
                                            <div className="dropdwn-input flex items-center">
                                                <div className="w-full">
                                                    <TextInput
                                                        placeholder=""
                                                        type="text"
                                                        name="organisationUENLicenseNumberVATID"
                                                        value={this.state.goGlobalInformation.organisationUENLicenseNumberVATID}
                                                        identity="OrganisationUENLicenseNumberVATID"
                                                        handleChange={this.handleChange.bind(this)}
                                                        onBlur={(e) => this.validateField("organisationUENLicenseNumberVATID", this.state.goGlobalInformation)}
                                                    />
                                                </div>
                                            </div>
                                            <ValidationText error={this.state.validState.error.organisationUENLicenseNumberVATID} />
                                        </div>

                                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                            <h2 className="text-xl text-[#757575] font-bold mb-3">
                                                Company Name{" "}
                                                <span className="text-[#c00000]">*</span>
                                            </h2>
                                            <div className="dropdwn-input flex items-center">
                                                <div className="w-full">
                                                    <TextInput
                                                        placeholder=""
                                                        type="text"
                                                        name="companyName"
                                                        value={this.state.goGlobalInformation.companyName}
                                                        identity="OrganizationProfile"
                                                        handleChange={this.handleChange.bind(this)}
                                                        onBlur={(e) => this.validateField("companyName", this.state.goGlobalInformation)}
                                                    />
                                                </div>
                                            </div>
                                            <ValidationText error={this.state.validState.error.companyName} />
                                        </div>
                                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                            <h2 className="text-xl text-[#757575] font-bold mb-3">
                                                Company Website{" "}
                                                <span className="text-[#c00000]">*</span>
                                            </h2>
                                            <div className="w-full outline-none appearance-none">
                                                <TextInput
                                                    placeholder=""
                                                    type="text"
                                                    name="companyWebsite"
                                                    value={this.state.goGlobalInformation.companyWebsite}
                                                    identity="OrganizationProfile"
                                                    handleChange={this.handleChange.bind(this)}
                                                    onBlur={(e) => this.validateField("companyWebsite", this.state.goGlobalInformation)}
                                                />
                                            </div>
                                            <ValidationText error={this.state.validState.error.companyWebsite} />
                                        </div>
                                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                            <h2 className="text-xl text-[#757575] font-bold mb-3">
                                                Company Address{" "}
                                                <span className="text-[#c00000]">*</span>
                                            </h2>
                                            <div className="dropdwn-input flex items-center">
                                                <div className="w-full">
                                                    <TextInput
                                                        placeholder=""
                                                        type="text"
                                                        name="companyAddress"
                                                        value={this.state.goGlobalInformation.companyAddress}
                                                        identity="companyAddress"
                                                        handleChange={this.handleChange.bind(this)}
                                                        onBlur={(e) => this.validateField("companyAddress", this.state.goGlobalInformation)}
                                                    />
                                                </div>
                                            </div>
                                            <ValidationText error={this.state.validState.error.companyAddress} />
                                        </div>
                                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                            <h2 className="text-xl text-[#757575] font-bold mb-3">
                                                Business Contact Number{" "}
                                                <span className="text-[#c00000]">*</span>
                                            </h2>
                                            <div className="dropdwn-input flex items-center">
                                                <div className="w-full">
                                                    <TextInput
                                                        placeholder=""
                                                        type="text"
                                                        name="businessContactNumber"
                                                        value={this.state.goGlobalInformation.businessContactNumber}
                                                        identity="businessContactNumber"
                                                        handleChange={this.handleChange.bind(this)}
                                                        onBlur={(e) => this.validateField("businessContactNumber", this.state.goGlobalInformation)}
                                                    />
                                                </div>
                                            </div>
                                            <ValidationText error={this.state.validState.error.businessContactNumber} />
                                        </div>
                                        {/* <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                            <h2 className="text-xl text-[#757575] font-bold mb-3">
                                                Region{" "}
                                                <span className="text-[#c00000]">*</span>
                                            </h2>
                                            <div className="">
                                                <Selector
                                                    options={this.state.regionList}
                                                    isMulti
                                                    closeMenuOnSelect={false}
                                                    hideSelectedOptions={false}
                                                    // components={{
                                                    //     Option,
                                                    // }}
                                                    onChange={this.handleChangeMultiDropdown.bind(this)}
                                                    allowSelectAll={true}
                                                    value={this.state.goGlobalInformation.selectedRegionArray}
                                                    drpIdentity="Selected_Region"
                                                />
                                            </div>
                                            <ValidationText error={this.state.validState.error.selectedRegionArray} />
                                        </div> */}

                                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                            <h2 className="text-xl text-[#757575] font-bold mb-3">
                                                Country With Presence {""}
                                                <span className="text-[#c00000]">*</span>
                                            </h2>
                                            <div className="flex items-center">
                                                <Selector
                                                    options={this.state.countryWithPresence}
                                                    isMulti
                                                    closeMenuOnSelect={false}
                                                    hideSelectedOptions={false}
                                                    onChange={this.handleChangeMultiDropdown.bind(this)}
                                                    allowSelectAll={true}
                                                    value={this.state.goGlobalInformation.selectedCountryWithPresence}
                                                    drpIdentity="CountryWithPresence"
                                                />
                                            </div>
                                            <ValidationText error={this.state.validState.error.selectedCountryWithPresence} />
                                        </div>


                                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                            <h2 className="text-xl text-[#757575] font-bold mb-3">
                                                List of Service(s)
                                                <span className="text-[#c00000]">*</span>
                                            </h2>
                                            <div className="">
                                                <Selector
                                                    options={this.state.serviceProvidedList}
                                                    isMulti
                                                    closeMenuOnSelect={false}
                                                    hideSelectedOptions={false}
                                                    // components={{
                                                    //     Option,
                                                    // }}
                                                    onChange={this.handleChangeMultiDropdown.bind(this)}
                                                    allowSelectAll={true}
                                                    value={this.state.goGlobalInformation.selectedServiceProvided}
                                                    drpIdentity="Service_Provided"
                                                />
                                            </div>
                                            <ValidationText error={this.state.validState.error.selectedServiceProvided} />
                                        </div>
                                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                            <h2 className="text-xl text-[#757575] font-bold mb-3">
                                            Primary sector(s) of main business activity {""}
                                                <span className="text-[#c00000]">*</span>
                                            </h2>
                                            <div className="">
                                                <Selector
                                                    options={this.state.businessSectorList}
                                                    isMulti
                                                    closeMenuOnSelect={false}
                                                    hideSelectedOptions={false}
                                                    // components={{
                                                    //     Option,
                                                    // }}
                                                    onChange={this.handleChangeMultiDropdown.bind(this)}
                                                    allowSelectAll={true}
                                                    value={this.state.goGlobalInformation.selectedBusinessSector}
                                                    drpIdentity="businessSectors"
                                                />
                                            </div>
                                            <ValidationText error={this.state.validState.error.selectedBusinessSector} />
                                        </div>
                                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                            <h2 className="text-xl text-[#757575] font-bold mb-3">
                                                Type of Business Activity
                                                <span className="text-[#c00000]">*</span>
                                            </h2>
                                            <div className="flex items-center">

                                                <Selector
                                                    options={this.state.typeOfPartnerList}
                                                    isMulti
                                                    closeMenuOnSelect={false}
                                                    hideSelectedOptions={false}
                                                    // components={{
                                                    //     Option,
                                                    // }}
                                                    onChange={this.handleChangeMultiDropdown.bind(this)}
                                                    allowSelectAll={true}
                                                    value={this.state.goGlobalInformation.selectedPartnerType}
                                                    drpIdentity="typeOfPartner"
                                                />
                                            </div>
                                            <ValidationText error={this.state.validState.error.selectedPartnerType} />
                                        </div>
                                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                            <h2 className="text-xl text-[#757575] font-bold mb-3">
                                                First Name {""}
                                                <span className="text-[#c00000]">*</span>
                                            </h2>
                                            <TextInput
                                                placeholder=""
                                                type="text"
                                                name="firstName"
                                                value={this.state.goGlobalInformation.firstName}
                                                maxLength={40}
                                                identity="firstName"
                                                handleChange={this.handleChange.bind(this)}
                                                onBlur={(e) =>
                                                    this.validateField(
                                                        "firstName",
                                                        this.state.goGlobalInformation
                                                    )
                                                }
                                            />
                                            <ValidationText error={this.state.validState.error.firstName} />
                                        </div>
                                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                            <h2 className="text-xl text-[#757575] font-bold mb-3">
                                                Last Name {""}
                                                <span className="text-[#c00000]">*</span>
                                            </h2>
                                            <TextInput
                                                placeholder=""
                                                type="text"
                                                name="lastName"
                                                value={this.state.goGlobalInformation.lastName}
                                                maxLength={40}
                                                identity="lastName"
                                                handleChange={this.handleChange.bind(this)}
                                                onBlur={(e) =>
                                                    this.validateField(
                                                        "lastName",
                                                        this.state.goGlobalInformation
                                                    )
                                                }
                                            />
                                            <ValidationText error={this.state.validState.error.lastName} />
                                        </div>
                                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                            <div className="grid grid-cols-12 gap-6 w-full ">
                                                <div className="col-span-12">
                                                    <h2 className="text-xl text-[#757575] font-bold mb-3">
                                                        Go Global Contact Email {""}
                                                        <span className="text-[#c00000]">*</span>
                                                    </h2>
                                                    <>
                                                        <div className="w-full outline-none appearance-none text-[#757575]">
                                                            <TextInput
                                                                placeholder=""
                                                                type="text"
                                                                name="goGlobalContactEmail"
                                                                value={this.state.goGlobalInformation.goGlobalContactEmail}
                                                                maxLength={40}
                                                                identity="OrganizationProfile"
                                                                handleChange={this.handleChange.bind(this)}
                                                                onBlur={(e) =>
                                                                    this.validateField(
                                                                        "goGlobalContactEmail",
                                                                        this.state.goGlobalInformation
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <ValidationText error={this.state.validState.error.goGlobalContactEmail} />
                                                    </>
                                                </div>
                                                <div className="col-span-12">
                                                    <h2 className="text-xl text-[#757575] font-bold mb-3">
                                                        Company Description {""}
                                                        <span className="text-[#c00000]">*</span>
                                                    </h2>
                                                    <div className="flex items-center height-inherit-text-area w-full">
                                                        <textarea
                                                            className="w-full hidden"
                                                            // placeholder="Comments"
                                                            id=""
                                                            rows="8"
                                                            name="companyDescription"
                                                            value={this.state.goGlobalInformation.companyDescription}
                                                            onChange={this.handleChange.bind(this)}
                                                            onBlur={() => this.validateField("companyDescription")}
                                                        />
                                                        <div className="ck-editor-sec small-editor-t w-full">
                                                            {/* <TinyMCEEditor
                                                        key={"fullPageCKEditor"}
                                                        onChange={(data) => {
                                                            this.setVideoDescription(data);
                                                        }}
                                                        data={this.state.goGlobalInformation.companyDescription}
                                                        onBlur={(event, editor) => {
                                                            this.validateField("companyDescription");
                                                        }}
                                                    /> */}
                                                            <TinyMCEEditorForGoGlobal
                                                                key={"fullPageCKEditor"}
                                                                onChange={(data) => {
                                                                    this.setVideoDescription(data);
                                                                }}
                                                                data={this.state.goGlobalInformation.companyDescription}
                                                                onBlur={(event, editor) => {
                                                                    this.validateField("companyDescription");
                                                                }}
                                                            />
                                                            <div>
                                                                <ValidationText error={this.state.validState.error.companyDescription} />
                                                            </div>
                                                            {(this.state &&
                                                                this.state.charLimit -
                                                                this.state.countCompanyDescription) <= 0 ? (
                                                                <div className="text-white bg-[#717171] w-[300px] rounded text-center mb-1">
                                                                    You reach the character enter limit
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    className={`mb-1 rounded text-[#fff] w-[80px] text-center ${this.state.charLimit -
                                                                        this.state.countCompanyDescription <
                                                                        50
                                                                        ? "bg-[#313984]"
                                                                        : this.state.charLimit -
                                                                            this.state.countCompanyDescription <
                                                                            100
                                                                            ? "bg-[#ffc107]"
                                                                            : "bg-blue-500"
                                                                        }`}
                                                                >
                                                                    {this.state.charLimit -
                                                                        this.state.countCompanyDescription}
                                                                    /{this.state.charLimit}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {/* <ValidationText error={this.state.validState.error.companyDescription} /> */}
                                                </div>
                                            </div>
                                        </div>



                                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                            <h2 className="text-xl text-[#757575] font-bold mb-3">
                                                Company Logo
                                                <span className="text-[#c00000]">*</span>
                                            </h2>
                                            <div className="w-[320px]">
                                                <div className="company-logo-cropper ml-auto mr-auto goglobal-company-logo">
                                                    <ImageCropperWithUpload
                                                        key="Main"
                                                        height={200}
                                                        width={400}
                                                        isCheckValidation={true}
                                                        MaxFileSize={2097152}
                                                        MaxFileSizeMessage={"2MB"}
                                                        onSaveImage={this.saveMainImage}
                                                        imageIdentity="CorporateMembers"
                                                        intitImage={this.state.goGlobalInformation.companyLogo}
                                                        aspactRatio={400 / 200}
                                                        uploadBtn={"Upload Logo"}
                                                        dimensionText={"(Format: JPG/PNG Dimension: 400px by 200px Maximum Size: 2MB)"}
                                                    />

                                                </div>
                                            </div>
                                            <ValidationText error={this.state.validState.error.companyLogo} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*Footer Button */}
                            <div className="flex flex-wrap justify-end space-x-10 mt-12 pb-8">
                                {this.state.currentAccountStatusId != 4 ?
                                    <>
                                        {this.state.isApprovingAccount === true ?
                                            <button className="buttonload group relative btn btn-lighblue text-xl border text-white focus:outline-none">
                                                <span className="absolute left-[15px] flex items-center ">
                                                    <i className="fa fa-spinner fa-spin text-white text-[17px]"></i>
                                                </span>
                                                <span className="ml-8">
                                                    Loading
                                                </span>
                                            </button> :
                                            <button
                                                className="w-32 px-7 py-2 btn-sm btn-lighblue text-lg text-white"
                                                onClick={() =>
                                                    this.approveGoGlobalMemberDetails(4)
                                                }
                                            >
                                                Approve
                                            </button>
                                        }
                                    </>
                                    : <button className="w-32 px-7 py-2 btn-sm btn-disabled text-lg text-white">
                                        Approve
                                    </button>}

                                <div className="space-x-4">
                                    {this.state.currentAccountStatusId != 12 ?
                                        <>
                                            {this.state.isRejectingAccount === true ?
                                                <ButtonLoader />
                                                :
                                                <button
                                                    className="w-32 px-7 py-2 btn-sm btn-red text-lg text-white"
                                                    onClick={() => this.approveGoGlobalMemberDetails(12)}
                                                >
                                                    Reject
                                                </button>}
                                        </>
                                        :
                                        <button className="w-32 px-7 py-2 btn-sm btn-disabled-red text-lg text-white">
                                            Reject
                                        </button>
                                    }
                                </div>
                            </div>
                            {/*Footer Button End*/}

                        </div>
                    </div>


                </div>
            </div>
        );
    }
}