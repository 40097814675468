import React from 'react'
import ActionDeleteButton from '../UIComponent/ActionTableComponent/ActionDeleteButton';
import ReadMore from '../showMore/ReadMore';
import PageLoader from '../common/pageLoader/PageLoader';

export default function StrategicTable(props) {
    return (
        <div className='mb-2  overflow-x-auto overflow-y-auto'>
            {props.isLoading ? <PageLoader /> :
                <table className="stretegic-member-tbl w-full mx-auto my-5 fixed_header">

                    <thead >
                        <tr className="text-base font-bold flex">
                            <th className="px-2 first:pl-5 last:pr-5 tbl-th 2xl:whitespace-nowrap lg:whitespace-normal text-white w-[20%]">
                                <div className="font-bold tble-th-txt text-left">Strategic Member</div>
                            </th>
                            <th className="px-2 first:pl-5 last:pr-5 tbl-th 2xl:whitespace-nowrap lg:whitespace-normal text-white w-[15%]">
                                <div className="font-bold tble-th-txt text-center">Number Of Project</div>
                            </th>
                            <th className="px-2 first:pl-5 last:pr-5 tbl-th 2xl:whitespace-nowrap lg:whitespace-normal text-white w-[30%]">
                                <div className="font-bold tble-th-txt text-center">Industry Group</div>
                            </th>
                            <th className="px-2 first:pl-5 last:pr-5 tbl-th 2xl:whitespace-nowrap lg:whitespace-normal text-white w-[25%]">
                                <div className="font-bold tble-th-txt text-center">Last Engagement</div>
                            </th>
                            <th className="px-2 first:pl-5 last:pr-5 tbl-th 2xl:whitespace-nowrap lg:whitespace-normal text-white w-[10%]">
                                <div className="font-bold tble-th-txt text-center">Action</div>
                            </th>
                        </tr>
                    </thead>

                    <tbody className="scrollit text-sm custom-tbody">
                        {
                            props?.strategicMembersList && props.strategicMembersList.length > 0 ?
                                (
                                    props.strategicMembersList.map((data, key) => {
                                        return (
                                            <tr className='custom-tbl-td flex items-center' key={key}>
                                                <td className=" px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[20%]">
                                                    {data.memberName}
                                                </td>
                                                <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[15%]">
                                                    <div className="dollar-sign-icon inline-block">
                                                        {data.noOfProject}
                                                    </div>
                                                </td>
                                                <td className="px-2 first:pl-5 last:pr-5 tble-td-txt text-[#757575] w-[30%]">
                                                    {/* <div className="dollar-sign-icon custom-breakline-td">
                                                    {data.chapters}
                                                </div> */}
                                                    {
                                                        data && data.chapters === null || data.chapters === "" ?
                                                            (
                                                                <p>-</p>
                                                            )
                                                            :
                                                            (
                                                                 data?.chapters !== null && data.chapters.length > 35 ?
                                                                    (
                                                                        <ReadMore sliceValue={35}>{String(data.chapters)}</ReadMore>
                                                                    )
                                                                    :
                                                                    (
                                                                        <p>{data.chapters}</p>
                                                                    )
                                                            )

                                                    }

                                                </td>
                                                <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[25%]">
                                                    {data.updatedAt}
                                                </td>
                                                <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[10%]">
                                                    <ActionDeleteButton
                                                        onActionClick={() => props.onDeleteStrategicMember(data.corporateMemberId)}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })
                                )
                                :
                                (
                                    <tr className='grid justify-items-center'>
                                        <td className="text-lg text-[#757575]	">
                                            No Records Found
                                        </td>
                                    </tr>
                                )
                        }
                    </tbody>
                </table>
            }
        </div>
    );
}