import React, { Component } from "react";
import { components } from "react-select";
import SwalServices from "../../services/swalServices/SwalServices";
import ResourceServices from '../../services/axiosServices/apiServices/ResourceServices';
import CommonServices from "../../services/axiosServices/apiServices/CommonServices";
import { Navigate } from 'react-router-dom';
import MultiSelectDropdown from "../../components/dropdown/MultiSelectDropdown";
import moment from "moment";
import { isValidForm, validate } from "../../utils/validation/CommonValidator";
import { CommonValidationMessages } from '../../utils/Messages';
import ValidationText from "../../utils/validation/ValidationText";
import { APIURL, GetImage } from "../../services/axiosServices/ApiEndPoints";
import { checkParameters, encryptAES } from "../../utils/Encryption";

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

export default class PostApproval extends Component {
    constructor(props) {
        super(props);

        this.swalServices = new SwalServices();
        this.resourceServices = new ResourceServices();
        this.commonServices = new CommonServices();
        this.state = {
            toggle1: true,
            resourceTopics: [],
            isLoading: false,
            resource: {
                resourceId: 0,
                resourceName: "",
                resourceImage: "",
                visibilityStatusId: 0,
                visibilityStatus: "",
                metaTitle: "",
                metaDescription: "",
                metaKeyWords: "",
                resourceTopicIds: 0,
                statusId: 0,
                publishDate: "",
                optionSelected: null,
                pageDescription: [],
            },
            selectedTopicIds: [],
            redirect: null,
            DecArray: [{
                resourceDescriptionId: 1,
                resourceDescription: '',
                ResourceDescriptionImage: ''
            },],
            validationRule: {
                metaTitle: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.FieldRequired.replace('{0}', 'meta title'),
                    },
                ],
                metaKeyWords: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.FieldRequired.replace('{0}', 'meta Keywords'),
                    },
                ],
                metaDescription: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.FieldRequired.replace('{0}', 'meta description'),
                    },
                ],
                optionSelected: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.FieldRequired.replace('{0}', 'Topics')
                    }
                ],
            },
            validState: {
                isValid: true,
                error: {}
            },
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle = () => {
        if (this.state.toggle1 === true) {
            this.setState({ toggle1: false });
        } else {
            this.setState({ toggle1: true });
        }
    };


    handleChangeInDropdown = selected => {
        let detail = this.state.resource;
        this.setState({ selectedTopicIds: { ...this.state.selectedTopicIds, selectedTopicIds: selected } });
        detail.optionSelected = selected
        this.setState({ resource: { ...detail } });
    };

    getAllResourceTopics = () => {
        this.resourceServices.getAllResourceTopics().then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                this.setState({ resourceTopics: response.responseItem.responseContent });
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        })
    }


    handleRadioButtons(event) {
        const value = event.target.checked;
        const id = event.target.id;
        let detail = this.state.resource;
        if (id === "Hidden") {
            detail['visibilityStatus'] = "Hidden";
        }
        else if (id === "Member") {
            detail['visibilityStatus'] = "Member";
        }
        else if (id === "Non-Member") {
            detail['visibilityStatus'] = "Non-Member";
        }
        detail['visibilityStatusId'] = false;
        detail[id] = value;
        this.setState({ resource: { ...detail } });
    }

    bindMultiSelect = (topicIds) => {
        var array = topicIds.split(',');
        const options = [];
        array.forEach(topic => {
            options.push(this.state.resourceTopics.find(topics => topics.value === Number(topic)));
        });
        var selected = this.state.resource
        selected.optionSelected = options
        this.setState({ resource: selected });
    }

    getResourceTaggingDetails(resourceId) {
        this.resourceServices.getResourceTaggingDetails(resourceId).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                var resourceItem = this.state.resource;
                var arr = [];
                response.responseItem.responseContent.resourceDescription.forEach(dec => {
                    const obj = {

                        resourceDescriptionId: dec.resourceDescriptionId,
                        resourceDescription: dec.resourceDescription,
                        resourceDescriptionImage: dec.resourceDescriptionImage
                    };
                    arr.push(obj);
                });
                resourceItem.resourceId = response.responseItem.responseContent.resourceId;
                resourceItem.resourceName = response.responseItem.responseContent.resourceName;
                resourceItem.visibilityStatusId = response.responseItem.responseContent.visibilityStatusId;
                resourceItem.visibilityStatus = response.responseItem.responseContent.visibilityStatus;
                resourceItem.metaTitle = response.responseItem.responseContent.metaTitle ? response.responseItem.responseContent.metaTitle : response.responseItem.responseContent.resourceName;
                resourceItem.metaKeyWords = response.responseItem.responseContent.metaKeyWords;
                resourceItem.metaDescription = response.responseItem.responseContent.metaDescription;
                resourceItem.resourceTopicIds = response.responseItem.responseContent.resourceTopicIds;
                resourceItem.statusId = response.responseItem.responseContent.statusId;
                resourceItem.publishDate = response.responseItem.responseContent.publishDate;
                resourceItem.optionSelected = response.responseItem.responseContent.optionSelected;
                resourceItem.pageDescription = arr;
                resourceItem.resourceImage = APIURL + GetImage + "?type=resource&&fileName=" + response.responseItem.responseContent.resourceImage;
                this.setState({ resource: resourceItem }, () => {
                    this.getAllResourceTopics();
                    this.bindMultiSelect(response.responseItem.responseContent.resourceTopicIds);
                }
                );

            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        })
    }

    cancel = () => {
        this.setState({ redirect: "/ViewResource" });
    }
    addEditResourceTaggingDetails = (value) => {
        if (this.isValidateAllFields()) {
            var resource = this.state.resource;
            var arr = [];
            if (this.state.DecArray.length > 0) {
                this.state.DecArray.forEach(dec => {
                    const obj = {
                        resourceDescriptionId: dec.index,
                        resourceDescription: dec.imageDescription,
                        resourceDescriptionImage: dec.image
                    };
                    arr.push(obj);
                });
            }
            if (this.state.resource.optionSelected.length > 0) {
                var arr1 = this.state.resource.optionSelected.map(function (item) {
                    delete item.bad;
                    return item.value;
                });
                resource.resourceTopicIds = arr1.join([',']);
            }
            if (resource.visibilityStatus === 'Hidden') {
                resource.visibilityStatusId = 3;
            } else if (resource.visibilityStatus === 'Member') {
                resource.visibilityStatusId = 4;
            } else if (resource.visibilityStatus === 'Non-Member') {
                resource.visibilityStatusId = 5;
            }
            resource.createdBy = 115;
            resource.createdAppId = 115;
            resource.statusId = value;
            resource.resourceDescription = arr;
            this.resourceServices.addEditResourceTaggingDetails(resource).then((response) => {
                if (response.statusCode === 200 && response.responseItem != null) {
                    this.setState({ resource: response.responseItem.responseContent });
                    if (value === 1) {
                        this.swalServices.Success('Resource has been save to draft.');
                    } else if (value === 3) {
                        this.swalServices.Success(' Resource has been saved.');
                    }
                    else if (value === 4) {
                        this.swalServices.Success(' Resource has been approved.');
                    }
                    this.setState({ redirect: "/ViewResource" });
                }
                else {
                    this.swalServices.Error(response.message);
                }
                this.setState({ isLoading: false });
            })
        }
    }

    handleChange(event) {
        const value = event.target.value;
        const name = event.target.name;
        let detail = this.state.resource;
        detail[name] = value;
        this.setState({ resource: { ...detail } });
    }

    validateField = (key) => {
        const newValidState = validate(key, this.state.resource, this.state.validationRule, this.state.validState);
        this.setState({ validState: newValidState });
    }

    isValidateAllFields = () => {
        const newValidState = isValidForm(this.state.resource, this.state.validationRule, this.state.validState);
        this.setState({ validState: newValidState });
        return newValidState.isValid;
    }

    redirectToEditResources = () => {
        if (this.state.resource.resourceId !== 0) {
            this.setState({ redirect: '/EditResource/ResourceId?=' + encryptAES(this.state.resource.resourceId) });
        }
    }

    componentDidMount() {
        let params = new URLSearchParams(window.location.search);
        let resourceId = params.get("");
        if (resourceId && (resourceId = checkParameters(resourceId, "Number"))) {
            if (resourceId != null && resourceId > 0) {
                this.getResourceTaggingDetails(resourceId);
            }
        } else {
            this.getAllResourceTopics();
        }

    }

    render() {
        if (this.state.redirect != null) {
            return <Navigate to={this.state.redirect} />;
        }
        return (
            <div className="main-body-section">
                <div className="flex">
                    <div className="relative flex flex-col flex-1 ">
                        <main className="">
                            <div className="flex">
                                <div className="relative flex flex-col flex-1 ">
                                    <div className="custom-card box-border border border-[#9e9e9e] mt-5 p-6">
                                        <div className="flex flex-wrap justify-between space-x-10 mt-3 mb-8">
                                            <div>
                                                <button className="px-14  py-2 btn btn-lighblue-border text-xl border h-12" onClick={() => this.redirectToEditResources()}>
                                                    Edit Details
                                                </button>
                                            </div>
                                            <div>
                                                <button className="w-32 px-7 py-2 btn-sm btn btn-gray text-xl text-white font-semibold tracking-normal h-12 mr-5" onClick={() => this.cancel()}>
                                                    Cancel
                                                </button>
                                                <button className="w-32 px-7  py-2 btn-sm btn btn-lighblue text-xl text-white font-semibold tracking-normal h-12 mr-5" onClick={() => this.addEditResourceTaggingDetails(3)}>
                                                    Save
                                                </button>
                                                <button className="w-32 px-7 py-2 btn-sm btn btn-red text-xl text-white font-semibold tracking-normal h-12 mr-5" onClick={() => this.addEditResourceTaggingDetails(4)}>
                                                    Approve
                                                </button>
                                                <button className=" px-7  py-2 btn btn-sm  text-xl font-semibold tracking-normal lighblue-text  h-12" onClick={() => this.addEditResourceTaggingDetails(1)}>
                                                    Draft
                                                </button>
                                            </div>
                                        </div>
                                        <div className="filter-info grid grid-cols-3 gap-2 box-border border border-[#9e9e9e] mb-16">
                                            <div className="col-span-2">
                                                <div className="grid grid-cols-12 mg-8 gap-6">
                                                    <div className="border border-[#9e9e9e] mt-4 ml-4  2xl:col-span-8 lg:col-span-8 col-span-12 ">
                                                        {/* <img className="2xl:h-[330px] lg:h-[230px] w-full object-cover object-center" src="/default-img.png" alt="Image Area" /> */}
                                                        <img className="w-full " alt="ResourceImage" src={this.state.resource.resourceImage} ></img>
                                                    </div>
                                                    <div className="p-4 custom-card box-border border-2  mt-4  2xl:col-span-4 lg:col-span-4 col-span-12 ">
                                                        <p className="post-approval-right-title block tracking-wide font-bold mb-3 theme-color min-h-30 ">{this.state.resource.resourceName}</p>

                                                    </div>
                                                    <div className="grid grid-cols-12 gap-6 ml-4  mt-10 mb-10 border  border-[#757575] p-4 pr-7 col-span-12">

                                                        {this.state.resource.pageDescription ? (
                                                            <div className="rounded-none w-full p-3 text-lg col-span-12 ck-editor__main">
                                                                <div className="title-text-part">
                                                                    <h3 className="title-text font-bold mt-5">
                                                                        {this.state.resource.resourceName}
                                                                    </h3>
                                                                </div>
                                                                <div className='className="resource-details mt-3"'>
                                                                    <div>
                                                                        <div className="theme-color font-semibold mb-4">
                                                                            <p className=" mt-5">
                                                                                Published {moment(this.state.resource.publishDate).format("MMMM YYYY")}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="resource-details mt-3">
                                                                    {this.state.resource.pageDescription.map((value, key) => {
                                                                        return (
                                                                            <React.Fragment key={key}>
                                                                                <div className="theme-active-color font-bold mb-4" >
                                                                                {value.resourceDescriptionImage ? (
                                                                                    <img className="my-7 w-full"
                                                                                    src={APIURL + GetImage + "?type=resourcedescription&&fileName=" + value.resourceDescriptionImage} alt="" />
                                                                                    )
                                                                                    :
                                                                                    null}
                                                                                    </div>
                                                                                <div className="theme-color font-semibold mb-4 text-justify">
                                                                                    <div dangerouslySetInnerHTML={{ __html: value.resourceDescription }}></div>
                                                                                </div>
                                                                                <img className="my-7 w-full" src={value.resourceDescriptionImage} alt="" />
                                                                            </React.Fragment>
                                                                        )
                                                                    }
                                                                    )}
                                                                </div>
                                                            </div>)
                                                            :
                                                            null}

                                                    </div>

                                                </div>
                                            </div>
                                            <div className="grid grid-cols-12 gap-6  mb-10">
                                                <div className="grid grid-row-2 col-span-11">
                                                    <div>
                                                        <div className="filter-types 2xl:col-span-11 lg:col-span-11 col-span-12 2xl:ml-4 lg:ml-2 ml-0">
                                                            <label className="block text-xl font-bold theme-color min-h-30 mt-5" htmlFor="default">
                                                                Topics{" "}<span className="text-[#c00000]">*</span>
                                                            </label>
                                                            <MultiSelectDropdown
                                                                options={this.state.resourceTopics}
                                                                isMulti
                                                                closeMenuOnSelect={false}
                                                                hideSelectedOptions={false}
                                                                components={{
                                                                    Option
                                                                }}
                                                                onChange={this.handleChangeInDropdown}
                                                                allowSelectAll={true}
                                                                value={this.state.resource.optionSelected}
                                                            />
                                                            <ValidationText error={this.state.validState.error.optionSelected} />
                                                        </div>
                                                        <div className="filter-types 2xl:col-span-11 lg:col-span-11 col-span-12 2xl:ml-4 lg:ml-2 ml-0 mt-3">
                                                            <label className="block text-xl font-bold  theme-color min-h-30 " htmlFor="default">
                                                                Meta Title{" "}
                                                            </label>
                                                            <input id="default" className="form-input rounded-none w-full shadow-red py-3 text-lg" type="text"
                                                                name="metaTitle"
                                                                onChange={(event) => this.handleChange(event)}
                                                                value={this.state.resource.metaTitle}
                                                                onBlur={() => this.validateField("metaTitle")}
                                                            />
                                                            <ValidationText error={this.state.validState.error.metaTitle} />
                                                        </div>
                                                        <div className="filter-types 2xl:col-span-11 lg:col-span-11 col-span-12 2xl:ml-4 lg:ml-2 ml-0 mt-3">
                                                            <label className="block text-xl font-bold theme-color min-h-30 " htmlFor="default">
                                                                Meta Keywords{" "}
                                                                <span className="text-[#c00000]">*</span>
                                                            </label>
                                                            <label className=" text-left theme-color" htmlFor="default">
                                                                Separate tags with commas{" "}
                                                            </label>
                                                            <input id="default" className="form-input rounded-none w-full shadow-red py-3 text-lg" type="text"
                                                                name="metaKeyWords"
                                                                onChange={(event) => this.handleChange(event)}
                                                                value={this.state.resource.metaKeyWords}
                                                                onBlur={() => this.validateField("metaKeyWords")} />
                                                            <ValidationText error={this.state.validState.error.metaKeyWords} />
                                                        </div>
                                                        <div className="filter-types 2xl:col-span-11 lg:col-span-11 col-span-12 2xl:ml-4 lg:ml-2 ml-0 mt-3">
                                                            <label className="block text-xl font-bold theme-color min-h-30 " htmlFor="default">
                                                                Meta Description{" "}
                                                                <span className="text-[#c00000]">*</span>
                                                            </label>

                                                            <textarea id="default" className="form-input rounded-none w-full shadow-red  text-lg"
                                                                name="metaDescription"
                                                                onChange={(event) => this.handleChange(event)}
                                                                value={this.state.resource.metaDescription}
                                                                onBlur={() => this.validateField("metaDescription")} rows="3" cols="50"></textarea>
                                                            {/* <input id="default" className="form-input rounded-none w-full h-24 shadow-red  text-lg" type="text"
                                                                name="metaDescription"
                                                                onChange={(event) => this.handleChange(event)}
                                                                value={this.state.resource.metaDescription}
                                                                onBlur={() => this.validateField("metaDescription")}
                                                            /> */}
                                                            <ValidationText error={this.state.validState.error.metaDescription} />
                                                        </div>
                                                        <div className="filter-types 2xl:col-span-11 lg:col-span-11 col-span-12 2xl:ml-4 lg:ml-2 ml-0 mt-3">
                                                            <div className="flex items-center justify-between pr-5">
                                                                <label className="block text-xl font-bold  theme-color min-h-30 " htmlFor="default">
                                                                    Visibility
                                                                </label>
                                                                {/* <div className="form-switch mt-2">
                                                                    <input type="checkbox" id="switch-1" className="sr-only"
                                                                        checked={this.state.resource.visibilityStatus != null}
                                                                        onChange={() => this.toggle()}
                                                                    />
                                                                    <label className="theme-bg-color" htmlFor="switch-1">
                                                                        <span className="bg-white shadow-sm" aria-hidden="true" ></span>
                                                                        <span className="sr-only">
                                                                            Switch label
                                                                        </span>
                                                                    </label>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                        <div className="filter-types 2xl:col-span-11 lg:col-span-11 col-span-12 2xl:ml-4 lg:ml-2 ml-0 mt-3">
                                                            <div className="flex items-center justify-between pr-5 mb-2">
                                                                <label className="text-left text-xl theme-color" htmlFor="default">
                                                                    Hidden
                                                                </label>
                                                                <div className="">
                                                                    <label className="flex items-center">
                                                                        <input type="radio" name="radio-buttons" className="form-radio" id="Hidden"
                                                                            value={this.state.resource.visibilityStatusId}
                                                                            onChange={(event) => this.handleRadioButtons(event)}
                                                                            checked={this.state.resource.visibilityStatus === "Hidden"}
                                                                        />
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="flex items-center justify-between pr-5 mb-2">
                                                                <label className="text-left text-xl theme-color" htmlFor="default">
                                                                    Member
                                                                </label>
                                                                <div className="">
                                                                    <label className="flex items-center selected ">
                                                                        <input type="radio" name="radio-buttons" className="form-radio" id="Member"
                                                                            value={this.state.resource.visibilityStatusId}
                                                                            onChange={(event) => this.handleRadioButtons(event)}
                                                                            checked={this.state.resource.visibilityStatus === "Member"}
                                                                        />
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="flex items-center justify-between pr-5">
                                                                <label className="text-left text-xl theme-color" htmlFor="default">
                                                                    Non-Member
                                                                </label>
                                                                <div className="">
                                                                    <label className="flex items-center">
                                                                        <input type="radio" name="radio-buttons" className="form-radio" id="Non-Member"
                                                                            value={this.state.resource.visibilityStatusId}
                                                                            onChange={(event) => this.handleRadioButtons(event)}
                                                                            checked={this.state.resource.visibilityStatus === "Non-Member"}
                                                                        />
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-wrap justify-between space-x-10 mt-3 mt-10">
                                            <div>
                                                <button className="px-14  py-2 btn btn-lighblue-border text-xl border h-12" onClick={() => this.redirectToEditResources()}>
                                                    Edit Details
                                                </button>
                                            </div>
                                            <div>
                                                <button className="w-32 px-7 py-2 btn-sm btn btn-gray text-xl text-white font-semibold tracking-normal h-12 mr-5" onClick={() => this.cancel()}>
                                                    Cancel
                                                </button>
                                                <button className="w-32 px-7  py-2 btn-sm btn btn-lighblue text-xl text-white font-semibold tracking-normal h-12 mr-5" onClick={() => this.addEditResourceTaggingDetails(3)}>
                                                    Save
                                                </button>
                                                <button className="w-32 px-7 py-2 btn-sm btn btn-red text-xl text-white font-semibold tracking-normal h-12 mr-5" onClick={() => this.addEditResourceTaggingDetails(4)}>
                                                    Approve
                                                </button>
                                                <button className=" px-7  py-2 btn btn-sm  text-xl font-semibold tracking-normal lighblue-text  h-12" onClick={() => this.addEditResourceTaggingDetails(1)}>
                                                    Draft
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        );
    }
}