import React from "react";
import PageLoader from "../common/pageLoader/PageLoader";
import moment from "moment";
function HistoryTable(props) {
  return (
    <div>
      {props.isLoading ? (
        <PageLoader />
      ) : (
        <div className="overflow-x-auto">
          <table className="w-full bg-white shadow-lg rounded-sm border border-[#81a3b8]">
            {/* Table header */}
            <thead className="table-bg text-xs font-semibold capitalize text-[#757575] border-[#81a3b8]">
              <tr>
                <th className="px-2 first:pl-8 last:pr-5 tbl-th whitespace-nowrap text-white">
                  <div className="font-bold tble-th-txt text-left">History</div>
                </th>
                <th className="px-2 first:pl-8 last:pr-5 tbl-th whitespace-nowrap text-white">
                  <div className="font-bold tble-th-txt text-left"></div>
                </th>
                <th className="px-2 first:pl-8 last:pr-5 tbl-th whitespace-nowrap text-white">
                  <div className="font-bold tble-th-txt text-left"></div>
                </th>
                <th className="px-2 first:pl-8 last:pr-5 tbl-th whitespace-nowrap text-white">
                  <div className="font-bold tble-th-txt text-left"></div>
                </th>
              </tr>
            </thead>
            {/* Table body */}

            {props.isCorporate ?
              <tbody className="text-sm custom-tbody border border-[#757575]">
                {props.engagementList.map((engagement, key) => (
                  <tr className="custom-tbl-td-history" key={key}>
                    <td className="p-7  text-lg">
                      <div className="text-[#3a4358]">
                        <span>Engagement Type: </span>
                        <span>{engagement.engagementType}</span>
                      </div>
                      <div className="text-[#3a4358] mt-2">
                        <span>Description: </span>
                        {engagement.engagementDescription}
                      </div>
                    </td>
                    <td className="p-7  text-lg">
                      <div className="text-[#3a4358]">
                        <span>Follow Up: </span>
                        <span className="font-bold text-[#3a4358]">
                        {engagement.followUp ? engagement.followUp : "-"}</span>
                      </div>
                      <div className="text-[#3a4358] mt-2">
                      <span>Engagement Date: </span>
                      {moment(engagement.engagementDate).format('DD/MM/yyyy')}
                    </div>
                    </td>
                    
                    <td className="p-7  text-lg">
                      <div className="action-part" onClick={() => props.onEdit(engagement.corporateEngagementTrackingId)}>
                        {/* <Link to="/" className="font-bold text-[#313984]">View/Edit</Link> */}
                        <div className="font-bold text-[#313984] cursor-pointer">
                          View/Edit
                        </div>
                      </div>
                    </td>
                    <td className="p-7  text-lg">
                      <div className="font-bold text-[#3a4358]">
                        {/* {engagement.followUp} */}
                      </div>
                      <div className="text-[#3a4358] mt-2">
                        
                      </div>
                    </td>
                    
                  </tr>
                ))}
              </tbody>
              :
              <tbody className="text-sm custom-tbody border border-[#757575]">
                {props.engagementList.map((engagement, key) => (
                  <tr className="custom-tbl-td-history" key={key}>
                    <td className="p-7  text-lg">
                      <div className="text-[#3a4358]">
                        <span>Engagement Type: </span>
                        <span>{engagement.corporateEngagementTypeName}</span>
                      </div>
                      <div className="text-[#3a4358] mt-2">
                        <span>Description: </span>
                        <span>{engagement.engagementDescription}</span>
                      </div>
                    </td>
                    <td className="p-7  text-lg">
                      <div className="text-[#3a4358]">
                        <span>Follow Up: </span>
                        {/* <span>{engagement.corporateEngagementFollowUpByName}</span> */}
                      </div>
                      <div className="text-[#3a4358] mt-2">
                        <span>
                        Engagement Date:
                        </span>
                        <span>
                          {/* To contact on the payment */}
                        </span>
                      </div>
                    </td>
                    <td className="p-7  text-lg">
                      <div className="font-bold text-[#3a4358]">
                      <span>{engagement.corporateEngagementFollowUpByName}</span>
                      </div>
                      <div className="text-[#3a4358] mt-2">
                        {/* {engagement.engagementDate} */}
                        {moment(engagement.engagementDate).format('L')}
                      </div>
                    </td>
                    <td className="p-7  text-lg">
                      <div className="action-part" onClick={() => props.onEdit(engagement.individualEngagementId)}>
                        {/* <Link to="/" className="font-bold text-[#313984]">View/Edit</Link> */}
                        <div className="font-bold text-[#313984]">
                          View/Edit
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            }
          </table>
        </div>
      )}
    </div>
  );
}
export default HistoryTable;