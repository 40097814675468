import React from 'react'
import { Link } from 'react-router-dom'

const ExportButton = (props) => {
    return (
        <Link to={props.redirect}>
            {/* <Link to='/show ALL members with the Exco Information' > */}
            <button className="mr-8 text-lg font-bold btn-red-border border text-[#313984] px-3" >
                <span className="text-lg text-red font-semibold tracking-normal"> {props.text} </span>
            </button>
        </Link>
    )
}
export default ExportButton;