import React from "react";
import ValidationText from "../../utils/validation/ValidationText";
import Selector from "../dropdown/commonDropdown/Selector";
import SingleSelectWithSearch from "../dropdown/SingleSelectWithSearch";

function AgencyProfile(props) {
  return (
    <div>
      <div className="bg-white pb-7">
        <div className=" heading-part px-7 py-4 border-b">
          <h4 className="small-title font-bold theme-color">Agency Profile</h4>
        </div>
        <div className="px-6 pt-10">
          <div className="grid 2xl:grid-cols-12 xl:grid-cols-12 lg:grid-cols-12 sm:grid-cols-12 gap-6 w-full">
            <div className="xl:col-span-6 lg:col-span-6 sm:col-span-12 input-sec-texts">
              <h2 className="text-base theme-color font-bold mb-1">
                Search Agency Name
                <span className="text-[#c00000]">*</span>
              </h2>
              <SingleSelectWithSearch
                drpIdentity={"AgencyName"}
                optionArray={props.agencyListTest}
                setFilterParameters={props.setFilterParametersTest}
                value={props.agencyListTestSelected}
                handleChangeTest={props.handleChangeTest}
                searchText={props.agencyListSelectedName}
              />
              <div>
                <ValidationText error={props.validState.error.agencyId} />
                <ValidationText error={props.validState.error.agencyName} />
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 sm:col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6 mb-6">
                <div className=" col-span-12 w-full">
                  <label
                    className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                    htmlFor="default"
                  >
                    Key Agency
                    <span className="text-[#c00000]">*</span>
                  </label>
                  <div className="flex items-center justify-start">
                    <div className="m-3 mr-20">
                      <label className="flex items-center">
                        <span className="text-xl  font-medium theme-color mx-2 ml-2">
                          Yes
                        </span>
                        <input
                          type="radio"
                          name="isKeyAgency"
                          id="isKeyAgency"
                          className="form-radio"
                          value={true}
                          onChange={(event) => props.handleRadioButtons(event)}
                          checked={props.agencyProfile.isKeyAgency === "true"}
                        />
                      </label>
                    </div>
                    <div className="m-3">
                      <label className="flex items-center">
                        <span className="text-xl  font-medium theme-color ml-2 mx-2">
                          No
                        </span>
                        <input
                          type="radio"
                          name="isKeyAgency"
                          id="isKeyAgency"
                          className="form-radio"
                          value={false}
                          onChange={(event) => props.handleRadioButtons(event)}
                          checked={props.agencyProfile.isKeyAgency === "false"}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 sm:col-span-12 input-sec-texts">
              <h2 className="text-base theme-color font-bold mb-1">
                Agency Officer              
              </h2>
              <Selector
                options={props.agencyOfficerDropdown}
                // isMulti
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                // components={{
                //   Option,
                // }}
                onChange={props.handleChangeInDropdown}
                allowSelectAll={false}
                value={props.agencyOfficerDropdown.find(
                  (item) => item.value === props.agencyProfile.agencyOfficerId
                )}
                drpIdentity="AgencyOfficer"
                isDisabled={props.agencyOfficerDropdown.length === 0 ? true : false}
              />
              <div>
                <ValidationText
                  error={props.validState.error.agencyOfficerId}
                />
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 sm:col-span-12 input-sec-texts">
              <h2 className="text-base theme-color font-bold mb-1">
                SMF Secretariat
                <span className="text-[#c00000]">*</span>
              </h2>
              <Selector
                options={props.SGTechSecretariaList}
                // isMulti
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                // components={{
                //   Option,
                // }}
                onChange={props.handleChangeInDropdown}
                allowSelectAll={false}
                value={props.SGTechSecretariaList.find(
                  (item) =>
                    item.value === props.agencyProfile.sGTechSecretariatId
                )}
                drpIdentity="SGTechSecretariat"
              />
              <div>
                <ValidationText
                  error={props.validState.error.sGTechSecretariatId}
                />
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 sm:col-span-12 input-sec-texts">
              <h2 className="text-xl text-zinc-500 font-bold">
                Internal Notes
              </h2>
              <textarea
                className="w-full h-[120px]"
                placeholder=""
                id="internalNotes"
                name="internalNotes"
                rows="4"
                cols="4"
                value={props.agencyProfile.internalNotes}
                onChange={(event) => props.handleChange(event)}
              />
            </div>
            <div className="xl:col-span-6 lg:col-span-6 sm:col-span-12 input-sec-texts">
              <div className="flex items-end justify-end h-full">
                <button
                  className="w-32 h-[50px] px-7 py-2 btn-sm btn-red text-lg text-white"
                  onClick={() => props.addEditAgencyPartner()}
                // disabled={props.isLoaderEnabled}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AgencyProfile;