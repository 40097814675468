import React from "react";
import MultiSelectDropdown from "../../../dropdown/MultiSelectDropdown";
import Option from "../../../dropdown/multiSelectDropdownOption/MultiSelectOption";
import DropdownSelect from "../../../dropdown/Dropdown";
import TextInput from "../../../UIComponent/TextInput";
import Validation from "../../../../utils/validation/ValidationText";

function SGTechInterest(props) {
  return (
    <div>
      <div className="bg-white border border-[#c9c9c9] mb-10">

        <div className="w-full 2xl:px-8 lg:px-4 px-4 mt-5">
          <div className="grid grid-cols-12 gap-6 w-full ">
            {/* <div className="xl:col-span-6 lg:col-span-6 col-span-12 custon-padding-add">
              <div className="grid grid-cols-12 gap-6">
                <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                  <h2 className="text-xl text-[#000000] font-bold mb-3">
                    Reasons to Join SMF
                  </h2>

                  <MultiSelectDropdown
                    drpIdentity="sgtech"
                    options={props.sgTechArr}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                      Option,
                    }}
                    onChange={props.handleChangeMultiDropdown}
                    allowSelectAll={true}
                    value={props.sgTechInterest.selectedSGTech}
                  />
                </div>
              </div>
            </div> */}
            {/* <div className="xl:col-span-6 lg:col-span-6 col-span-12 custon-padding-add">
              <div className="grid grid-cols-12 gap-6">
                <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                  <h2 className="text-xl text-[#000000] font-bold mb-3">
                    Sponsorship
                  </h2>

                  <MultiSelectDropdown
                    drpIdentity="sponsorship"
                    options={props.sponsorshipArr}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                      Option,
                    }}
                    onChange={props.handleChangeMultiDropdown}
                    allowSelectAll={true}
                    value={props.sgTechInterest.selectedSponsorship}
                  />
                </div>
              </div>
            </div> */}
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 custon-padding-add">
              <div className="grid grid-cols-12 gap-6">
                <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                  <h2 className="text-xl text-[#000000] font-bold mb-3 custom-min-height">
                    {/* Business Matching */}
                    Which country do you require business matching?
                  </h2>

                  <MultiSelectDropdown
                    drpIdentity="matching"
                    options={props.matchingArr}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                      Option,
                    }}
                    onChange={props.handleChangeMultiDropdown}
                    allowSelectAll={true}
                    value={props.sgTechInterest.selectedMatching}
                  />
                </div>
              </div>
            </div>
            {/* <div className="xl:col-span-6 lg:col-span-6 col-span-12 custon-padding-add">
              <div className="grid grid-cols-12 gap-6">
                <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                  <h2 className="text-xl text-[#000000] font-bold mb-3">
                   Certifications
                  </h2>

                  <MultiSelectDropdown
                    drpIdentity="certifications"
                    options={props.certificationsarr}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                      Option,
                    }}
                    onChange={props.handleChangeMultiDropdown}
                    allowSelectAll={true}
                    value={props.sgTechInterest.selectedCertifications}
                  />
                </div>
              </div>
            </div> */}
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 custon-padding-add">
              <div className="grid grid-cols-12 gap-6">
                <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                  <h2 className="text-xl text-[#000000] font-bold mb-3">
                    Which of the following SMF programmes would you be interested in knowing more? <span className="text-[#c00000]">*</span>
                  </h2>

                  <MultiSelectDropdown
                    drpIdentity="program"
                    options={props.strategicProgrammesArr}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                      Option,
                    }}
                    onChange={props.handleChangeMultiDropdown}
                    allowSelectAll={true}
                    value={props.sgTechInterest.selectedProgram}
                  />
                </div>
                {props.sgTechInterest.selectedProgram &&
                  props.sgTechInterest.selectedProgram.find(x => x.label.includes('Others')) ?
                  <div className="2xl:col-span-12 lg:col-span-12 col-span-12 ">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                        <h2 className="2xl:text-xl lg:text-md text-[#000000] font-bold 2xl:mb-3">
                          Others
                        </h2>
                        <TextInput
                          placeholder=""
                          type="text"
                          name="otherselectedProgram"
                          value={props.sgTechInterest.otherselectedProgram}
                          handleChange={props.handleChange}
                          identity="SGTechInterest"
                        // validateField={props.validateField}
                        // validState={props.validState}
                        // disabled={props.isOrganisationModalEditable === true ? false : true}
                        />

                        {/* <ValidationText error={props.validState.error.othercompanyBusinessFunctions} /> */}
                      </div>
                    </div>
                    <Validation error={props.validState.error.otherselectedProgram} />
                  </div>
                  : null
                }
              </div>
              <Validation error={props.validState.error.selectedProgram} />
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 custon-padding-add">
              <div className="grid grid-cols-12 gap-6">
                <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                  <h2 className="text-xl text-[#000000] font-bold mb-3">
                    Certifications
                  </h2>
                  <MultiSelectDropdown
                    drpIdentity="certifications"
                    options={props.certifications}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                      Option,
                    }}
                    onChange={props.handleChangeMultiDropdown}
                    allowSelectAll={true}
                    value={props.sgTechInterest.selectedCertifications}
                  />
                </div>
                {props.sgTechInterest.selectedCertifications &&
                  props.sgTechInterest.selectedCertifications.find(x => x.value === 15) ?
                  <div className="2xl:col-span-12 lg:col-span-12 col-span-12 ">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                        <h2 className="2xl:text-xl lg:text-md text-[#000000] font-bold 2xl:mb-3">
                          Others
                        </h2>
                        <TextInput
                          placeholder=""
                          type="text"
                          name="otherselectedCertifications"
                          value={props.sgTechInterest.otherselectedCertifications}
                          handleChange={props.handleChange}
                          identity="otherselectedCertifications"
                        // validateField={props.validateField}
                        // validState={props.validState}
                        // disabled={props.isOrganisationModalEditable === true ? false : true}
                        />

                        {/* <ValidationText error={props.validState.error.othercompanyBusinessFunctions} /> */}
                      </div>
                    </div>
                    <Validation error={props.validState.error.otherselectedCertifications} />
                  </div>
                  : null
                }
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 custon-padding-add">
              <div className="grid grid-cols-12 gap-6">
                <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                  <h2 className="text-xl text-[#000000] font-bold mb-3">
                    Do you have a sustainability function / department?
                  </h2>
                  <div className="flex items-center">
                    <div className="w-full outline-none appearance-none">
                      <DropdownSelect
                        drpIdentity={"SustainabilityFunctionDepartment"}
                        optionArray={props.yesNoOptionDropdown}
                        setFilterParameters={props.setFilterParameters}
                        value={props.sgTechInterest.sustainabilityFunctionDepartmentId}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 custon-padding-add">
              <div className="grid grid-cols-12 gap-6">
                <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                  <h2 className="text-xl text-[#000000] font-bold mb-3">
                    Membership(s) in other Trade Organisations(s)
                  </h2>
                  <div className="flex items-center">
                    <TextInput
                      placeholder=""
                      type="text"
                      name="membershipInotherOrganisations"
                      value={props.sgTechInterest.membershipInotherOrganisations}
                      identity="SGTechInterest"
                      handleChange={props.handleChange}
                    />
                  </div>
                </div>
              </div>
              {/* <ValidationText error={props.validState.error.city} /> */}
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 custon-padding-add">
              <div className="grid grid-cols-12 gap-6">
                <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                  <h2 className="text-xl text-[#000000] font-bold mb-3">
                    Have you received any government grants before
                  </h2>
                  <div className="flex items-center">
                    <div className="w-full outline-none appearance-none">
                      <DropdownSelect
                        drpIdentity={"ReceivedGovernmentGrants"}
                        optionArray={props.yesNoOptionDropdown}
                        setFilterParameters={props.setFilterParameters}
                        value={props.sgTechInterest.receivedGovernmentGrantsId}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Save Button */}
          <div className="grid grid-cols-12 gap-6 pb-10 mt-7">
            <div className="xl:col-span-12 lg:col-span-12 col-span-12 flex items-center justify-end">
              {/* <a
                      className="flex items-center justify-end"
                      href="/CreateCorporateMembers"
                    > */}
              <button
                className="w-32 px-7 py-3 btn-sm btn-red text-xl text-white font-semibold"
                onClick={() => props.updateSGTechIntrest()}
              >
                Save
              </button>
              {/* </a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SGTechInterest;
