import React from "react";
import MyEditor from "../ckEditor/Editor";
import ValidationText from "../../utils/validation/ValidationText";
import { APIURL, GetImage } from "../../services/axiosServices/ApiEndPoints";
import moment from "moment";

const EditDetailsColumn1 = (props) => {
  // let settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 600,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  // };

  const checkValidation = (field) => {
    if (
      props.categoryCampaignItem.eventTypeId === 1 &&
      field === "outlineDescription"
    ) {
      props.validateFieldDescription("outlineDescription");
    } else if (
      props.categoryCampaignItem.eventTypeId === 1 &&
      field === "speakerDescription"
    ) {
      props.validateFieldDescription("speakerDescription");
    } else if (
      props.categoryCampaignItem.eventTypeId === 1 &&
      field === "readMoreDescription"
    ) {
      props.validateFieldDescription("readMoreDescription");
    }
  };
  return (
    <>
      <div className="grid grid-cols-12 gap-6">
        <div className="2xl:col-span-8 lg:col-span-12 col-span-12 w-full">
          <img
            className="border border-[#e0e0e0] w-full"
            src={
              APIURL +
              GetImage +
              "?type=\\Events\\BasicEventInformation&&fileName=" +
              props.categoryCampaignItem.eventImage
            }
            alt=""
          ></img>
          {/* <Slider {...settings}>
                        {props.carouselData.map((carousel, key) => (
                            // <img className="border border-[#e0e0e0]" src={carousel.img} key={key} alt="" />
                            <img
                            className="border border-[#e0e0e0]"
                            src={
                              APIURL +
                              GetImage +
                              "?type=\\Events\\BasicEventInformation&&fileName=" +
                              carousel.eventImage
                            }
                            alt=""
                            key={key}
                          ></img>
                        ))}
                    </Slider> */}
        </div>
        <div className="2xl:col-span-4 lg:col-span-12 col-span-12 border border-[#e0e0e0] border-solid p-4 space-y-4">
          <h2 className="text-3xl theme-color font-bold">
            {props.categoryCampaignItem.eventName}
          </h2>
          {/* <label className="text-xl theme-color font-normal">
                        Trainer company name
                    </label> */}
          <div className="flex items-left justify-left space-x-4">
            <img className="w-5 h-5" src="/calender.png" alt="" />
            <span className="theme-color common-fonts-size font-normal">
              {moment(props.entryPricingDetails.eventLessonDate).format(
                "DD MMMM YYYY"
              )}
            </span>
          </div>


          <div className="flex items-left justify-left space-x-4">
            <img className="w-5 h-5" src="/clock.png" alt="" />
            <span className="theme-color common-fonts-size font-normal">
              {props.categoryCampaignItem.eventTypeId === 3 ?
                props.categoryCampaignItem.eventFormEndTime :
                (props.entryPricingDetails.eventStartTime + " - " +
                  props.entryPricingDetails.eventEndTime)}
              {/* {props.categoryCampaignItem.eventFormEndTime} */}
              {/* {props.entryPricingDetails.eventStartTime} -{" "}
              {props.entryPricingDetails.eventEndTime} */}
            </span>
          </div>

          {props.categoryCampaignItem.eventTypeId === 3 ? null :
            <div className="flex items-left justify-left space-x-4">
              <img className="w-5 h-5" src="/refreshing.png" alt="" />
              <span className="theme-color common-fonts-size font-normal">
                {`${props.entryPricingDetails.totalHours} hours`}
              </span>
            </div>
          }

          <div className="flex items-left justify-left space-x-4">
            <img className="w-4 h-5" src="/location.png" alt="" />
            <span className="theme-color common-fonts-size font-normal">
              {props.entryPricingDetails.locationAreaName}
            </span>
          </div>

          {props.entryPricingDetails.eventPackageList.map((pack, key) => {
            return (
              <div key={key} className="flex items-left justify-left space-x-4">
                <img className="w-4 h-5 " src="/tags.png" alt="" />
                <div className="grid grid-cols-12 gap-2 w-full">
                  <div className="col-span-12">
                    <div className="flex items-center justify-between">
                      <label className="theme-color common-fonts-size font-normal col-span-9">
                        {pack.eventPackageName}
                      </label>
                      <label className="theme-color font-normal edit-detail-price-text">
                        {pack.eventPrice}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          {/* <div className="flex items-left justify-left theme-color text-base font-normal space-x-4">
                        <div className="grid grid-cols-12 gap-4">
                            <label className="theme-color common-fonts-size font-normal col-span-9">
                                Ordinary Member
                            </label>
                            <label className="theme-color font-normal edit-detail-price-text">
                                {` $${props.categoryCampaignItem.ordinaryMemberFees.toFixed(2)}`}
                            </label>
                        </div>
                    </div> */}
        </div>
        {/* Add About description */}
        <div className="filter-types 2xl:col-span-12 lg:col-span-12 col-span-12">
          <label
            className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
            htmlFor="default"
          >
            About <span className="text-[#c00000]">*</span>
          </label>
          <div className="ck-editor-sec">
            <MyEditor
              // key={index}
              // onChange={(event, editor) => {
              //     const data = editor.getData();
              //     setDescription(data, index, value.resourceDescriptionImage);
              // }}
              onChange={props.handleAboutEditorChange}
              data={props.categoryCampaignItem.eventAbout}
              onBlur={(event, editor) => {
                props.validateField("eventAbout");
              }}
            />
            <div>
              <ValidationText error={props.validState.error.eventAbout} />
            </div>
          </div>
        </div>
        {/* Add Outlines description */}
        {props.categoryCampaignItem.eventTypeId === 1 ? (
          <div className="filter-types 2xl:col-span-12 lg:col-span-12 col-span-12">
            <label
              className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
              htmlFor="default"
            >
              Outlines <span className="text-[#c00000]">*</span>
            </label>
            <div className="ck-editor-sec">
              <MyEditor
                // key={index}
                onChange={props.handleOutlineEditorChange}
                data={props.categoryCampaignItem.outlineDescription}
                onBlur={() => checkValidation("outlineDescription")}
              />
              <div>
                <ValidationText
                  error={props.validStateDescription.error.outlineDescription}
                />
              </div>
            </div>
          </div>
        ) : null}
        {/* Add Outlines description End */}

        {/* Add Speakers Description Start*/}
        {props.categoryCampaignItem.eventTypeId === 1 ? (
          <div className="filter-types 2xl:col-span-12 lg:col-span-12 col-span-12">
            <label
              className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
              htmlFor="default"
            >
              Speaker
            </label>
            <div className="ck-editor-sec">
              <MyEditor
                // key={index}
                onChange={props.handleSpeakerEditorChange}
                data={props.categoryCampaignItem.speakerDescription}
              // onBlur={() => checkValidation("speakerDescription")}
              />
              <div>
                <ValidationText
                  error={props.validStateDescription.error.speakerDescription}
                />
              </div>
            </div>
          </div>
        ) : null}
        {/* Read More Description */}
        {props.categoryCampaignItem.eventTypeId === 1 ? (
          <div className="filter-types 2xl:col-span-12 lg:col-span-12 col-span-12">
            <label
              className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
              htmlFor="default"
            >
              Read More <span className="text-[#c00000]">*</span>
            </label>
            <div className="ck-editor-sec">
              <MyEditor
                // key={index}
                onChange={props.handleReadMoreEditorChange}
                data={props.categoryCampaignItem.readMoreDescription}
                onBlur={() => checkValidation("readMoreDescription")}
              />
              <div>
                <ValidationText
                  error={props.validStateDescription.error.readMoreDescription}
                />
              </div>
            </div>
          </div>
        ) : null}
        {/* Read More Description End*/}

        {/* <div className="filter-types 2xl:col-span-12 lg:col-span-12 col-span-12">
                    <label
                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                        htmlFor="default"
                    >
                        Read More  <span className="text-[#c00000]">*</span>
                    </label>
                    <div className="ck-editor-sec">
                        <MyEditor
                            // key={index}
                            onChange={props.handleReadMoreEditorChange}
                            data={props.categoryCampaignItem.readMoreDescription}
                            onBlur={() => checkValidation("readMoreDescription")}
                        />
                        <div>
                            <ValidationText error={props.validStateDescription.error.readMoreDescription} />
                        </div>
                    </div>

                </div> */}

        {/* Selection Display */}
        <div className="filter-types 2xl:col-span-12 lg:col-span-12 col-span-12">
          <label
            className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
            htmlFor="default"
          >
            Selection Display
          </label>
          <div className="border border-[#c1c1c1] border-solid w-full py-20 pl-2 lg:grid-cols-3 lg:justify-center gap-4 lg:flex-wrap items-center">
            {props?.selectionDisplay && props.selectionDisplay.map((selected, key) => (
              <React.Fragment key={key}>
                {selected !== '' ?
                  <button className="mx-2 py-2 px-3 border">{`${selected} X`}</button>
                  :
                  null
                }

              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default EditDetailsColumn1;
