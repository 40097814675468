import moment from "moment";
import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import Table from "../../../components/table/Table";
import Checkbox from "../../../components/UIComponent/Checkbox";
import SwalServices from "../../../services/swalServices/SwalServices";
import MembershipTerminationServices from "../../../services/axiosServices/apiServices/MembershipTerminationServices";
import DropdownSelect from "../../../components/dropdown/Dropdown";
import CorporateMemberServices from "../../../services/axiosServices/apiServices/CorporateMemberServices";

export default class MembershipTermination extends Component {
    constructor(props) {
        super(props);
        this.membershipTerminationServices = new MembershipTerminationServices();
        this.corporateMemberServices = new CorporateMemberServices();
        this.swalServices = new SwalServices();
        this.state = {
            pagination: {
                pageNo: 1,
                pageSize: 10,
                orderByColumn: "",
                orderFlag: 0,
                searchText: "",
                billingCategoryId: 0,
                searchByUENNumber : ""
            },
            billingCategories : [],
            route: "",
            membershipTerminationList: [],
            totalResults: 0,
            selectedIdList: [],
            checkedRow: false,
            checkedHeader: false,
            isRejected: true,
            isApprove: true,
            isLoading: false
        };
    }


    
    componentDidMount() {
        this.getAllMembershipTerminationList();
        this.getAllDropdownForCorporateMember();
    }

    getAllMembershipTerminationList = () => {
        this.setState({ isLoading: true });
        this.membershipTerminationServices.getAllMembershipTerminationList(this.state.pagination).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                let data = response.responseItem.responseContent.itemList;
                data.map((x) => {
                    x.subcriptionEndDate = moment(x.subcriptionEndDate).format("DD MMM YYYY");
                    x.lapseDate = moment(x.lapseDate).format("DD MMM YYYY");
                });
                this.setState({ membershipTerminationList: data, totalResults: response.responseItem.responseContent.totalCount });
            } else {
                this.swalServices.Error("Something went wrong.");
            }
            this.setState({ isLoading: false });
        })
    }


    getAllDropdownForCorporateMember = () => {
        this.corporateMemberServices.getAllDropdownForCorporateMember().then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            let billingCategories = response.responseItem.responseContent.filter(
              (drp) => drp.dropdownName === "BillingCategoryStatus"
            );
            this.setState({
                billingCategories: billingCategories
            });
          } 
        });
      };

    setPagination = (newPagination) => {
        this.setState({ pagination: newPagination }, () => { this.getAllMembershipTerminationList() });
    };

    select = (element, index, value) => {
        return element !== "selectRow" ? null : (
            <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575]">
                <input
                    name="isRequireQuotation"
                    type="checkbox"
                    className="cursor-pointer h-6 w-6 rounded check-box-custom"
                    onChange={() => this.selectSingleRows(value)}
                    checked={this.state.membershipTerminationList[index].isSelected === true}
                />
            </td>
        );
    };

    selectSingleRows = (value) => {
        let getAllRows = [...this.state.membershipTerminationList];
        let check = getAllRows.find(x => x.corporateMemberId === value)
        let checkedHeader = true;
        if (check.isSelected) {
            getAllRows.map((x) => {
                if (x.corporateMemberId === value) {
                    x.isSelected = false;
                }
            })
            this.setState({ isRejected: true, isApprove: true });
        } else {
            getAllRows.map((x) => {
                if (x.corporateMemberId === value) {
                    x.isSelected = true;
                }
            })
        }
        let isCheck = false;
        getAllRows.map((x) => {
            if (x.isSelected === true) {
                if (x.billingStatus === 'Lapse Period') {
                    this.setState({ isRejected: false, isApprove: false });
                } else {
                    isCheck = true;
                }
            } else {
                checkedHeader = false;
            }
        })

        if (isCheck) {
            this.setState({ isRejected: isCheck, isApprove: isCheck });
        }
        this.setState({ membershipTerminationList: getAllRows, checkedHeader: checkedHeader });
    }

    selectAllRows = () => {
        let getAllRows = [...this.state.membershipTerminationList];
        if (this.state.checkedHeader) {
            getAllRows.map((item) => {
                item.isSelected = false;
            });
            this.setState({ membershipTerminationList: getAllRows, checkedHeader: false, isRejected: true, isApprove: true });
        } else {
            getAllRows.map((item) => {
                item.isSelected = true;
            });
            this.setState({ isRejected: false, isApprove: false });
            this.setState({ membershipTerminationList: getAllRows, checkedHeader: true });
        }
    }

    membershipTerminationApproval = (identity) => {
        let isCheckForApproval = true;
        let request = {
            updatedBy: 1,
            updatedAt: moment().format("yyyy-MM-DD"),
            updatedAppId: 114,
            corporateMemberId: []
        };

        let selectedIdList = this.state.membershipTerminationList.filter(
            (get) => get.isSelected === true
        );
        selectedIdList = selectedIdList.map((p) => (p.corporateMemberId));
        request.corporateMemberId = [...selectedIdList];
        if (isCheckForApproval) {
            this.membershipTerminationServices.membershipTerminationApproval(request).then((response) => {
                if (response.statusCode === 200 && response.responseItem != null) {
                    this.swalServices.Success('Termination successfully')
                    this.getAllMembershipTerminationList()
                } else {
                    this.swalServices.Error("Something went wrong.");
                }
            })
        } 
    }

    setFilterParameters = (id) => {
        let detail = this.state.pagination;
        detail.pageNo = 1;
        detail.billingCategoryId = id;
        this.setState({ pagination: { ...detail } });
        this.getAllMembershipTerminationList();
      }

      searchByName = (value, identity) => {
        let detail = this.state.pagination;
        if (identity === "searchByCompanyName") {
          detail.pageNo = 1;
          detail.pageSize = 10;
          detail.searchText = value;
          this.setState({ pagination: { ...detail } });
        }
        else if (identity === "searchByUENNumber") {
          detail.pageNo = 1;
          detail.pageSize = 10;
          detail.searchByUENNumber = value;
          this.setState({ pagination: { ...detail } });
        }
        this.getAllMembershipTerminationList();
      }

    render() {
        if (this.state.route !== "") {
            return <Navigate to={this.state.route} />;
        }
        return (
            <div className="main-body-section finance-body-sec">
                <div className="custom-card shadow-lg mb-10">
            <div className="filter-info grid grid-cols-12 gap-6">
              <div className="filter-types 2xl:col-span-4 lg:col-span-4 col-span-12">
                <h2
                  className="filter-lable block font-medium theme-color min-h-30"
                  htmlFor="default"
                >
                  Search by Company Name
                </h2>
                <input
                  id="default"
                  className="form-input rounded-none w-full shadow-red py-3 text-lg"
                  type="text"
                  onChange={(e) => this.searchByName(e.target.value, 'searchByCompanyName')}
                />
              </div>
              <div className="filter-types 2xl:col-span-4 lg:col-span-4 col-span-12">
                <h2
                  className="filter-lable block font-medium theme-color min-h-30"
                  htmlFor="default"
                >
                  Search by UEN Number
                </h2>
                <input
                  id="default"
                  className="form-input rounded-none w-full shadow-red py-3 text-lg"
                  type="text"
                  onChange={(e) => this.searchByName(e.target.value, "searchByUENNumber")}
                />
              </div>

              <div className="filter-types 2xl:col-span-4 lg:col-span-4 col-span-12">
                <h2
                  className="pl-6 block text-xl font-medium  theme-color min-h-30 "
                  htmlFor="default"
                >
                  	Billing Category
                </h2>
                <div className="pl-6 border-l-[1px] border-[#757575]">
                  <DropdownSelect
                    drpIdentity={"BillingCategory"}
                    optionArray={this.state.billingCategories}
                    setFilterParameters={this.setFilterParameters}
                    value={this.state.pagination.billingCategoryId}
                  />
                </div>
              </div>
            </div>
          </div>
                {/* Table Section Start */}
                <div className="mt-10 credit-note-approval-table">
                    <Table
                        columns={[
                            { name: "selectRow", title: <Checkbox handleCheckbox={() => this.selectAllRows()} checkedHeader={this.state.checkedHeader} /> },
                            { name: "companyName", title: "Company Name" },
                            { name: "companyUEN", title: "UEN Number" },
                            { name: "subcriptionEndDate", title: "Membership End Date" },
                            { name: "lapseDate", title: "Lapse Date" },
                            { name: "billingStatus", title: "Billing Category" },
                        ]}
                        rows={this.state.membershipTerminationList}
                        isLoading={this.state.isLoading}
                        sortingColumns={[""]}
                        pagination={this.state.pagination}
                        totalResultes={this.state.totalResults}
                        totalCount={this.state.totalResults}
                        setPagination={this.setPagination.bind(this)}
                        customScope={[
                            {
                                column: "selectRow",
                                renderTableData: this.select.bind(this),
                                valueColumnName: "corporateMemberId",
                            },
                        ]}
                        isRequiredButton1={true}
                        buttonText1="Terminate"
                        button1Click={() => { this.membershipTerminationApproval("Terminate") }}
                        button1Disable={this.state.isApprove}
                    />
                </div>
                {/*Table Section End */}
            </div>
        );
    }
}
