import {
    AcceptTotalDTSMEPackagePaidAmountInReconciliation,
    GetDTSMEMarketPlaceCompanyInformationList,
    GetDTSMEMarketPlaceCompanyInformationById,
    AddEditDTSMEMarketPlaceCompanyInformation,
    GetALLMarketPlaceCompanyInformationList,
    GetAllDropdownsForDTSME,

} from "../ApiEndPoints";
import {
    axiosGetAuthorizeMultiParams,
    axiosGetAuthorize,
    axiosPostAuthorize,
} from "../AxiosRequests";

export default class DtsmeServices {
    async acceptTotalDTSMEPackagePaidAmountInReconciliation(request) {
        return axiosGetAuthorizeMultiParams(AcceptTotalDTSMEPackagePaidAmountInReconciliation, request);
    }
    async getALLMarketPlaceCompanyInformationList(request) {
        return axiosGetAuthorize(GetALLMarketPlaceCompanyInformationList, request);
    }
    async getDTSMEMarketPlaceCompanyInformationList(request) {
        return axiosPostAuthorize(GetDTSMEMarketPlaceCompanyInformationList, request);
    }
    async getDTSMEMarketPlaceCompanyInformationById(request) {
        return axiosGetAuthorize(GetDTSMEMarketPlaceCompanyInformationById, request);
    }
    async getAllDropdownsForDTSME(request) {
        return axiosGetAuthorize(GetAllDropdownsForDTSME, request);
    }
    async addEditDTSMEMarketPlaceCompanyInformation(request) {
        return axiosPostAuthorize(AddEditDTSMEMarketPlaceCompanyInformation, request);
    }
}