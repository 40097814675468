import React from "react";
import ModalBasic from "../../component/FormModal";
import SectionWithBorder from "../../../components/membership/membershipTaskList/SectionWithBorder";
import CalculationChart from "../../../components/membership/membershipTaskList/CalculationChart";
import DropdownSelect from "../../../components/dropdown/Dropdown";
import moment from "moment";
import ValidationText from "../../../utils/validation/ValidationText";
import { StatusType } from "../../../utils/Constants";
import ButtonLoader from "../../../components/common/buttonLoader/ButtonLoader";
import { encryptAES } from "../../../utils/Encryption";


const MembershipTaskListProcess = (props) => {
  const handleChangeInPriceTable = (value, name, year, tableName) => {
    props.handleChangeInPriceTable(value, name, year, tableName);
  };

  return (
    <ModalBasic
      id="add-edit-modal"
      className="membership-add-edit-modal"
      modalOpen={props.setAddeditModalOpen}
      setModalOpen={props.setModalOpen}
      title="Membership Task List Process"
    >
      {/* Modal content */}
      <div className="2xl:px-14 lg:px-3 pt-4">
        <div className="">
          <SectionWithBorder title="Organisation Basic Information">
            <div className="grid grid-cols-12 gap-6 w-full items-center pt-7 px-7 pb-7">
              <div className="xl:col-span-6 md:col-span-6 col-span-12">
                <div className="grid grid-cols-12 gap-6">
                  <div className="xl:col-span-12 md:col-span-12 col-span-12">
                    <label
                      className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                      htmlFor="default"
                    >
                      Company Name <span className="text-[#c00000]">*</span>
                    </label>
                    <div className="disabled-input">
                      <input
                        id="default"
                        className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                        type="text"
                        name="companyName"
                        value={props.taskDetails.companyName ? props.taskDetails.companyName : ""}
                        onChange={(event) => props.handleChange(event)}
                        onBlur={() => props.validateField("companyName")}
                        disabled={true}
                      />
                      <div>
                      </div>
                      {" "}
                      <ValidationText
                        error={props.validState.error.companyName}
                      />{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className="xl:col-span-6 md:col-span-6 col-span-12">
                <div className="grid grid-cols-12 gap-6">
                  <div className="xl:col-span-12 md:col-span-12 col-span-12">
                    <label
                      className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                      htmlFor="default"
                    >
                      Company UEN <span className="text-[#c00000]">*</span>
                    </label>
                    <div className="disabled-input">
                      <input
                        id="default"
                        className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                        type="text"
                        name="companyUEN"
                        value={props.taskDetails.companyUEN ? props.taskDetails.companyUEN : ""}
                        onChange={(event) => props.handleChange(event)}
                        onBlur={() => props.validateField("companyUEN")}
                        disabled={true}
                      />
                    </div>
                    <div>
                      {" "}
                      <ValidationText
                        error={props.validState.error.companyUEN}
                      />{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className="xl:col-span-6 md:col-span-6 col-span-12">
                <div className="grid grid-cols-12 gap-6">
                  <div className="xl:col-span-12 md:col-span-12 col-span-12">
                    <label
                      className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                      htmlFor="default"
                    >
                      C/O
                    </label>
                    <input
                      id="default"
                      className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                      type="text"
                      name="coCompanyName"
                      value={props.taskDetails.coCompanyName ? props.taskDetails.coCompanyName : ""}
                      onChange={(event) => props.handleChange(event)}

                    />
                  </div>
                </div>
              </div>
              <div className="xl:col-span-6 md:col-span-6 col-span-12">
                <div className="grid grid-cols-12 gap-6">
                  <div className="xl:col-span-12 md:col-span-12 col-span-12">
                    <label
                      className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                      htmlFor="default"
                    >
                      Incorporation Date{" "}
                      {props.taskDetails.membershipTypeId === 3 ? <span className="text-[#c00000]">*</span> : null}
                    </label>
                    <input
                      id="default"
                      className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                      type="date"
                      name="incorporationDate"
                      max={moment(new Date()).format("yyyy-MM-DD")}
                      value={props.taskDetails.incorporationDate ? moment(
                        props.taskDetails.incorporationDate
                      ).format("yyyy-MM-DD") : ""}
                      onChange={(event) => props.handleChange(event)}
                    //onBlur={() => props.validateField("incorporationDate")}
                    />
                    <div>
                      {" "}
                      <ValidationText
                        error={props.validState.error.incorporationDate}
                      />{" "}
                    </div>
                    <ValidationText
                      error={
                        props.validStateForInvoice.error
                          .incorporationDate
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="xl:col-span-6 md:col-span-6 col-span-12">
                <div className="grid grid-cols-12 gap-6">
                  <div className="xl:col-span-12 md:col-span-12 col-span-12">
                    <label
                      className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                      htmlFor="default"
                    >
                      Attention To First Name{" "}
                      <span className="text-[#c00000]">*</span>
                    </label>
                    <input
                      id="default"
                      className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                      type="text"
                      name="firstName"
                      value={props.taskDetails.firstName ? props.taskDetails.firstName : ""}
                      onChange={(event) => props.handleChange(event)}
                      onBlur={() => props.validateField("firstName")}
                    />
                    <div>
                      {" "}
                      <ValidationText
                        error={props.validState.error.firstName}
                      />{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className="xl:col-span-6 md:col-span-6 col-span-12">
                <div className="grid grid-cols-12 gap-6">
                  <div className="xl:col-span-12 md:col-span-12 col-span-12">
                    <label
                      className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                      htmlFor="default"
                    >
                      Attention To Last Name{" "}
                      <span className="text-[#c00000]">*</span>
                    </label>
                    <input
                      id="default"
                      className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                      type="text"
                      name="lastName"
                      value={props.taskDetails.lastName ? props.taskDetails.lastName : ""}
                      onChange={(event) => props.handleChange(event)}
                      onBlur={() => props.validateField("lastName")}
                    />
                    <div>
                      {" "}
                      <ValidationText
                        error={props.validState.error.lastName}
                      />{" "}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="xl:col-span-6 md:col-span-6 col-span-12">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="xl:col-span-12 md:col-span-12 col-span-12">
                      <label
                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                        htmlFor="default"
                      >
                        Designation <span className="text-[#c00000]">*</span>
                      </label>
                     
                      <Selector
                        drpIdentity="Designation_TaskList"
                        options={props.designationList}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        onChange={props.handleChangeMultiDropdown}
                        allowSelectAll={true}
                        value={
                          props.taskDetails.selectedDesignation
                        }
                      />
                      <div>
                        {" "}
                        <ValidationText
                          error={props.validState.error.selectedDesignation}
                        />{" "}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="xl:col-span-6 md:col-span-6 col-span-12">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="xl:col-span-12 md:col-span-12 col-span-12">
                      <label
                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                        htmlFor="default"
                      >
                        Business Email <span className="text-[#c00000]">*</span>
                      </label>
                      <input
                        id="default"
                        className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                        type="text"
                        name="email"
                        value={props.taskDetails.email}
                        onChange={(event) => props.handleChange(event)}
                        onBlur={() => props.validateField("email")}
                      />
                      <div>
                        {" "}
                        <ValidationText
                          error={props.validState.error.email}
                        />{" "}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="xl:col-span-6 md:col-span-6 col-span-12">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="xl:col-span-12 md:col-span-12 col-span-12">
                      <label
                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                        htmlFor="default"
                      >
                        Business Contact Number{" "}
                        <span className="text-[#c00000]">*</span>
                      </label>
                      <input
                        id="default"
                        className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                        type="text"
                        name="contactNo"
                        value={props.taskDetails.contactNo}
                        onChange={(event) => props.handleChange(event)}
                        onBlur={() => props.validateField("contactNo")}
                      />
                      <div>
                        {" "}
                        <ValidationText
                          error={props.validState.error.contactNo}
                        />{" "}
                      </div>
                    </div>
                  </div>
                </div> */}
            </div>
          </SectionWithBorder>

          <SectionWithBorder title="Corporate Membership Subscription">
            <div className="px-7">
              <div className="grid grid-cols-12 gap-6 w-full items-center pt-7 pb-0">
                <div className="xl:col-span-6 md:col-span-6 col-span-12">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="xl:col-span-12 md:col-span-12 col-span-12">
                      <label
                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                        htmlFor="default"
                      >
                        {" "}
                        Billing Category{" "}
                        <span className="text-[#c00000]">*</span>{" "}
                      </label>
                      <DropdownSelect
                        drpIdentity={"billingCategories"}
                        optionArray={props.billingCategories}
                        setFilterParameters={props.setFilterParameters}
                        value={props.taskDetails.billingCategoryId ? props.taskDetails.billingCategoryId : 0}
                        disabled={props.disableSubscription}
                      />
                    </div>
                  </div>
                </div>

                <div className="xl:col-span-6 md:col-span-6 col-span-12">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="xl:col-span-12 md:col-span-12 col-span-12">
                      <label
                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30 "
                        htmlFor="default"
                      >
                        {" "}
                        Subscription Start Date{" "}
                        <span className="text-[#c00000]">*</span>{" "}
                      </label>
                      <div className="grid grid-cols-12 gap-6 items-center w-full">
                        <div className=" 2xl:col-span-12 lg:col-span-12 col-span-12 w-full">
                          <input
                            id="default"
                            name="subscriptionStartDate"
                            className={`date-pickers form-input rounded-none w-full ${props.disableSubscription
                              ? "bg-[#C4C4C4]"
                              : null
                              } shadow-red py-3 text-lg opacity-50 cursor-not-allowed`}
                            type="date"
                            value={props.taskDetails.subscriptionStartDate ? moment(
                              props.taskDetails.subscriptionStartDate
                            ).format("yyyy-MM-DD") : ""}
                            onChange={(event) => props.handleChange(event)}
                            disabled={props.disableSubscription}
                          // onBlur={() => props.validateField("publishDate")}
                          />
                          {/* <div>
                          <ValidationText error={props.validState.error.publishDate} />
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="xl:col-span-6 md:col-span-6 col-span-12">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="xl:col-span-12 md:col-span-12 col-span-12">
                      <label
                        className="filter-lable flex w-full font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                        htmlFor="default"
                      >
                        <span>Last Updated Date </span>
                        <span className="text-[#c00000]">*</span>{" "}
                      </label>
                      <div className="grid grid-cols-12 gap-6 items-center w-full">
                        <div className=" 2xl:col-span-12 lg:col-span-12 col-span-12 w-full">
                          <input
                            id="default"
                            name="lastUpdatedDate"
                            className={`date-pickers form-input rounded-none w-full ${props.disableSubscription
                              ? "bg-[#C4C4C4]"
                              : null
                              } shadow-red py-3 text-lg opacity-50 cursor-not-allowed`}
                            type="date"
                            value={props.taskDetails.lastUpdatedDate ? moment(
                              props.taskDetails.lastUpdatedDate
                            ).format("yyyy-MM-DD") : ""}
                            onChange={(event) => props.handleChange(event)}
                            disabled={props.disableSubscription}
                          // onBlur={() => props.validateField("publishDate")}
                          />
                          {/* <div>
                          <ValidationText error={props.validState.error.publishDate} />
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="xl:col-span-6 md:col-span-6 col-span-12 pt-3">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="xl:col-span-12 md:col-span-12 col-span-12">
                      <label
                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                        htmlFor="default"
                      >
                        {" "}
                        Subscription End Date{" "}
                        <span className="text-[#c00000]">*</span>{" "}
                      </label>
                      <div className="grid grid-cols-12 gap-6 items-center w-full">
                        <div className=" 2xl:col-span-12 lg:col-span-12 col-span-12 w-full ">
                          <input
                            id="default"
                            name="subscriptionEndDate"
                            className={`date-pickers form-input rounded-none w-full ${props.disableSubscription
                              ? "bg-[#C4C4C4]"
                              : null
                              } shadow-red py-3 text-lg opacity-50 cursor-not-allowed`}
                            type="date"
                            value={props.taskDetails.subscriptionEndDate ? moment(
                              props.taskDetails.subscriptionEndDate
                            ).format("yyyy-MM-DD") : ""}
                            onChange={(event) => props.handleChange(event)}
                            disabled={props.disableSubscription}
                          // onBlur={() => props.validateField("publishDate")}
                          />
                          {/* <div>
                          <ValidationText error={props.validState.error.publishDate} />
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-span-12 flex justify-end">
                  <button
                    className="text-xl font-bold text-[#313984] btn-red-border w-[250px] text-red p-3"
                    onClick={() => window.open("/CreateCorporateMembers/CorporateMemberId?=" + encryptAES(props.taskDetails.corporateMemberId))}
                  >
                    View Corporate Information
                  </button>
                </div>

              </div>

              <div className="grid grid-cols-12 gap-6 w-full items-center pt-3 pb-7">

              </div>
            </div>
          </SectionWithBorder>

          <SectionWithBorder title="Pricing Table">
            <div className="px-7">
              <div className="grid grid-cols-12 gap-6 w-full items-center pt-3 pb-7 mt-5">
                <div className="xl:col-span-12 md:col-span-12 col-span-12">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="xl:col-span-6 md:col-span-6 col-span-12">
                      <label
                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                        htmlFor="default"
                      >
                        {" "}
                        Membership Type{" "}
                        <span className="text-[#c00000]">*</span>{" "}
                      </label>
                      <DropdownSelect
                        drpIdentity={"membershipTypes"}
                        optionArray={props.membershipTypes}
                        setFilterParameters={props.setFilterParameters}
                        value={props.taskDetails.membershipTypeId ? props.taskDetails.membershipTypeId : 0}
                        onBlur={(e) =>
                          props.validateField(
                            "membershipTypeId",
                            props.taskDetails
                          )
                        }
                      />
                      <ValidationText
                        error={
                          props.validStateForInvoice.error.membershipTypeId
                        }
                      />
                    </div>
                    {props.taskDetails.membershipTypeId > 0 && props.taskDetails.membershipTypeId === 5 ?
                      <>
                        <div className="xl:col-span-6 md:col-span-6 col-span-12">
                          <label
                            className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                            htmlFor="default"
                          >
                            Please select the TAC that you are from
                            <span className="text-[#c00000]">*</span>{" "}
                          </label>
                          <DropdownSelect
                            drpIdentity={"TACFromType"}
                            optionArray={props.tacNameList}
                            setFilterParameters={props.setFilterParameters}
                            value={props.taskDetails.tacFromId ? props.taskDetails.tacFromId : 0}
                          />
                          <ValidationText
                            error={
                              props.validState.error.tacFromId
                            }
                          />
                        </div>
                        {props.taskDetails.tacFromId > 0 && props.taskDetails.tacFromId === 1 ?
                          <div className="xl:col-span-6 md:col-span-6 col-span-12">
                            <div className="grid grid-cols-12 gap-6">
                              <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                <label
                                  className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                  htmlFor="default"
                                >
                                  TAC Name
                                  <span className="text-[#c00000]">*</span>
                                </label>
                                <input
                                  id="default"
                                  className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                  type="text"
                                  name="tacName"
                                  value={props.taskDetails.tacName ? props.taskDetails.tacName : ""}
                                  onChange={(event) => props.handleChange(event)}

                                />
                                <ValidationText error={props.validState.error.tacName}
                                />
                              </div>

                            </div>
                          </div>
                          : null}
                      </> : null}

                    <div className="xl:col-span-6 md:col-span-6 col-span-12">
                      <label
                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                        htmlFor="default"
                      >
                        {" "}
                        Size of Workforce{" "}
                        <span className="text-[#c00000]">*</span>{" "}
                      </label>
                      <DropdownSelect
                        drpIdentity={"membershipRevenue"}
                        optionArray={props.membershipRevenueList}
                        setFilterParameters={props.setFilterParameters}
                        value={props.taskDetails.membershipRevenueId ? props.taskDetails.membershipRevenueId : 0}
                        onBlur={(e) =>
                          props.validateField(
                            "membershipRevenueId",
                            props.taskDetails
                          )
                        }
                      />
                      <ValidationText
                        error={
                          props.validStateForInvoice.error.membershipRevenueId
                        }
                      />
                    </div>
                    <div className="xl:col-span-6 md:col-span-6 col-span-12">
                      <label
                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                        htmlFor="default"
                      >
                        {" "}
                        Transaction Type{" "}
                        <span className="text-[#c00000]">*</span>{" "}
                      </label>
                      <DropdownSelect
                        drpIdentity={"transactionTypes"}
                        optionArray={props.transactionTypes}
                        setFilterParameters={props.setFilterParameters}
                        value={props.taskDetails.transactionTypeId ? props.taskDetails.transactionTypeId : 0}
                        onBlur={(e) =>
                          props.validateField(
                            "transactionTypeId",
                            props.taskDetails
                          )
                        }
                        disabled={true}
                      />
                      <ValidationText
                        error={
                          props.validStateForInvoice.error.transactionTypeId
                        }
                      />
                    </div>
                    <div className="xl:col-span-6 md:col-span-6 col-span-12 disabled-input">
                      <label
                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                        htmlFor="default"
                      >
                        Comments
                      </label>
                      <textarea
                        className="w-full"
                        // placeholder="Comments"
                        id=""
                        rows="6"
                        name="comments"
                        value={props.taskDetails ? props.taskDetails.comments : ""}
                        onChange={(e) => props.handleChange(e)}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="grid grid-cols-12 gap-6 w-full items-center pt-3 pb-7 my-2">
                <div className="xl:col-span-12 md:col-span-12 col-span-12">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="xl:col-span-12 md:col-span-12 col-span-12">
                      <div className="flex items-center justify-between">
                        <div className="check-box-sec flex items-center">
                          <input
                            className="form-checkbox"
                            type="checkbox"
                            name="SendQuotation"
                            id="SendQuotation"
                            onChange={(event) => props.handleChangeCheckBox(event)}
                            checked={props.taskDetails.quotationNeeded}
                            value={props.taskDetails.quotationNeeded ? props.taskDetails.quotationNeeded : false}
                          />
                          <span className="filter-lable font-bold text-[#757575] pl-4">
                            Send Quotation
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {props.taskDetails.quotationNeeded === true ? (
                <div className="grid grid-cols-12 gap-6 w-full items-center pt-3 pb-7">
                  <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                        <label
                          className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                          htmlFor="default"
                        >
                          {" "}
                          Membership Tenure{" "}
                          <span className="text-[#c00000]">*</span>{" "}
                        </label>
                        <DropdownSelect
                          drpIdentity={"membershipTenure"}
                          optionArray={props.tenureArr}
                          setFilterParameters={props.setFilterParameters}
                          value={props.taskDetails.membershipTenureId ? props.taskDetails.membershipTenureId : 0}
                          onBlur={(e) =>
                            props.validateFieldForInvoice(
                              "membershipTenureId",
                              props.taskDetails
                            )
                          }
                        />
                        <ValidationText
                          error={
                            props.validStateForInvoice.error
                              .membershipTenureId
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="xl:col-span-12 md:col-span-12 col-span-12">
                        <label
                          className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                          htmlFor="default"
                        >
                          {" "}
                          Number of Industry Group{" "}
                          <span className="text-[#c00000]">*</span>{" "}
                        </label>
                        <DropdownSelect
                          drpIdentity={"chapters"}
                          optionArray={props.numberOfChapters}
                          setFilterParameters={props.setFilterParameters}
                          value={props.taskDetails.numberOfChapter ? props.taskDetails.numberOfChapter : 0}
                          isNumberDropDown={true}
                          isRemoveDefaultOption={true}
                          onBlur={(e) =>
                            props.validateFieldForInvoice(
                              "numberOfChapter",
                              props.taskDetails
                            )
                          }
                        />
                        <ValidationText
                          error={
                            props.validStateForInvoice.error.numberOfChapter
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="2xl:col-span-6 lg:col-span-6 col-span-12 mt-10">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="xl:col-span-12 md:col-span-12 col-span-12">
                        <div className="flex items-center justify-between">
                          <div className="check-box-sec flex items-center">
                            <input
                              className="form-checkbox"
                              type="checkbox"
                              name="name"
                              id="startMembershipOn"
                              onChange={(event) => props.handleChangeMembershipOnCheckBox(event)}
                              checked={props.taskDetails.startMembership}
                              value={props.taskDetails.startMembership ? props.taskDetails.startMembership : false}
                            />
                            <span className="filter-lable font-bold text-[#757575] pl-4">
                              Start membership on
                            </span>
                          </div>
                          <input
                            id="startMembershipDate"
                            name="startMembershipOn"
                            className={`date-pickers form-input rounded-none shadow-red py-3 text-lg ${props && props.taskDetails && props.taskDetails.startMembership && (props.taskDetails.isAppliedForRenewal !== true) ? null : "bg-[#f2f2f2]"}`}
                            type="date"
                            value={moment(
                              props.taskDetails.startMembershipOn
                            ).format("yyyy-MM-DD")}
                            onChange={(event) => props.handleChange(event)}
                            disabled={props && props.taskDetails && props.taskDetails.startMembership && (props.taskDetails.isAppliedForRenewal !== true) ? false : true}
                          //onBlur={() => props.validateFieldForInvoice("startMembershipOn")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="2xl:col-span-6 lg:col-span-6 col-span-12 mt-0">
                      <div className="grid grid-cols-12 gap-6">
                        <div className="xl:col-span-12 md:col-span-12 col-span-12">
                          <label
                            className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                            htmlFor="default"
                          >PO Number:

                          </label>
                          <div className="flex items-center justify-between">

                            <input
                              id="default"
                              className="text-lg font-bold  h-[55px] w-full text-[#757575]"
                              type="text"
                              name="poNumber"
                              value={props.taskDetails.poNumber}
                              onChange={(event) => props.handleChange(event)}
                            //onBlur={() => props.validateField("contactNo")}
                            />

                            /<input
                            //  id="pONumber"
                             // name="pONumber"
                            //  className="date-pickers form-input rounded-none shadow-red py-3 text-lg"
                            //  type="date"
                            //  value={moment(
                            //    props.taskDetails.startMembershipOn
                            //  ).format("yyyy-MM-DD")}
                          //    onChange={(event) => props.handleChange(event)}
                              //onBlur={() => props.validateFieldForInvoice("startMembershipOn")}
                            /> 
                          </div>
                        </div>
                      </div>
                    </div> */}
                </div>
              ) : null}

              {/* <TwoColsDropdownInput
                  col1Title="Membership Type"
                  col2Title="Type of Revenue"
                />
                <TwoColsDropdownInput
                  col1Title="Transaction Type"
                  col2Title=""
                /> */}
            </div>
            <div className="grid grid-cols-2 gap-6 pt-10  pb-8 ">
              <div className="border-r border-[#757575] pl-7 padding-r-0 pr-0">
                <CalculationChart
                  title="Base Pricing/ Market Campaign Pricing"
                  disabled={true}
                  showButtons={false}
                  tableData={props.basePriceTable}
                  handleChangeInPriceTable={(value, name, year, tableName) =>
                    handleChangeInPriceTable(value, name, year, tableName)
                  }
                  showStatus={StatusType.StateFalse}
                  membershipTypeId={props.taskDetails.membershipTypeId}
                />
              </div>
              <div className="padding-r-0">
                <div className="pb-8">
                  <CalculationChart

                    title="Allowable Discount"
                    disabled={true}
                    showButtons={false}
                    tableData={props.allowableDiscountTable}
                    handleChangeInPriceTable={(value, name, year, tableName) =>
                      handleChangeInPriceTable(value, name, year, tableName)
                    }
                    showStatus={StatusType.StateFalse}
                    membershipTypeId={props.taskDetails.membershipTypeId}
                  />
                </div>
              </div>
            </div>
            <hr className="custom-hr" />
            <div className="grid grid-cols-2 gap-6 pt-10 pb-4">
              <div className="border-r border-[#757575] px-7 active-inputs padding-r-0 pr-0">
                <CalculationChart
                  title="Percentage Discount Setting"
                  disabled={false}
                  showButtons={false}
                  tableData={props.percentageDiscountTable}
                  handleChangeInPriceTable={(value, name, year, tableName) =>
                    handleChangeInPriceTable(value, name, year, tableName)
                  }
                  showStatus={StatusType.StatusTrue}
                  membershipTypeId={props.taskDetails.membershipTypeId}
                />
              </div>
              <div className="padding-r-0 pr-0">
                <CalculationChart
                  title="System Calculated"
                  disabled={true}
                  showButtons={false}
                  tableData={props.systemCalculatedTable}
                  handleChangeInPriceTable={(value, name, year, tableName) =>
                    handleChangeInPriceTable(value, name, year, tableName)
                  }
                  membershipTypeId={props.taskDetails.membershipTypeId}
                />
              </div>
            </div>
            <hr className="custom-hr" />
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12 gap-6 px-8 py-8 ">
                <div className="grid grid-cols-12 gap-6 items-center">
                  <div className="2xl:2xl:col-span-3 lg:col-span-3 col-span-12 gap-6 ">
                    <label className="text-lg font-bold text-[#757575]">
                      Additional Promotion
                    </label>
                  </div>
                  <div className="2xl:2xl:col-span-9 lg:col-span-9 col-span-12 gap-6 ">
                    {/* <input
                        className="text-lg col-span-5 w-full"
                        name="text"
                        type="text"
                        value="Get a free starbucks voucher when you renew for 3 years."
                      /> */}
                    <input
                      //id="default"
                      className="text-lg col-span-5 w-full"
                      type="text"
                      name="additionalPromotion"
                      value={props.taskDetails.additionalPromotion ? props.taskDetails.additionalPromotion : ""}
                      onChange={(event) => props.handleChange(event)}
                    //onBlur={() => props.validateField("additionalPromotion")}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-6 mt-8 items-center">
                  <div className="2xl:2xl:col-span-3 lg:col-span-3 col-span-12 gap-6 ">
                    <label className="text-lg font-bold text-[#757575]">
                      Comments to member
                    </label>
                  </div>
                  <div className="2xl:2xl:col-span-9 lg:col-span-9 col-span-12 gap-6">
                    <input
                      //id="default"
                      className="text-lg col-span-5 w-full"
                      type="text"
                      name="commentsToMember"
                      value={props.taskDetails.commentsToMember ? props.taskDetails.commentsToMember : ""}
                      onChange={(event) => props.handleChange(event)}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-6  mt-8 items-center">
                  <div className="2xl:2xl:col-span-3 lg:col-span-3 col-span-12 gap-6 ">
                    <label className="text-lg font-bold text-[#757575]">
                      Internal Notes
                    </label>
                  </div>
                  <div className="2xl:2xl:col-span-9 lg:col-span-9 col-span-12 gap-6">
                    <input
                      //id="default"
                      className="text-lg col-span-5 w-full"
                      type="text"
                      name="internalNotes"
                      value={props.taskDetails.internalNotes ? props.taskDetails.internalNotes : ""}
                      onChange={(event) => props.handleChange(event)}
                    />
                  </div>
                </div>
              </div>

              <div className="2xl:col-span-12 lg:col-span-12 col-span-12 gap-6 px-8 pb-10 ">
                <div className="flex items-center justify-between">
                  <div className="flex items-center justify-between">
                    {props.isLoadingApprove ? (
                      <div className="w-[233px] quatation-send-btn">
                        <ButtonLoader />
                      </div>
                    ) : (
                      <button
                        className={`col-start-1 col-end-3 text-xl font-bold btn-red-border w-[250px] text-red p-3 ${!props.disablePaymentButton
                          ? "opacity-50 cursor-not-allowed"
                          : null
                          }`}
                        onClick={() => props.addEditMembershipTask()}
                        disabled={!props.disablePaymentButton}
                      >
                        Send for Approval
                      </button>
                    )}
                  </div>
                  {props.taskDetails.quotationNeeded === true ? (
                    <div className="flex items-center justify-between">
                      <div className="flex items-center justify-between mr-4">
                        {props.isLoadingQuotation ? (
                          <ButtonLoader />
                        ) : (<>

                          {props.tenureArr && props.tenureArr.length > 0 ?
                            <button
                              className=" px-0 py-3 btn-red text-xl border text-white w-[233px]"
                              onClick={(e) => {
                                //e.stopPropagation();
                                //setSendQuotation(true);
                                props.sendQuotationEmailToCorporateMember();
                              }}
                            >
                              Send Quotation
                            </button>
                            :
                            <button className=" px-0 py-3 btn-red text-xl border text-white w-[233px] opacity-50 cursor-not-allowed">
                              Send Quotation
                            </button>
                          }

                        </>
                        )}
                      </div>
                      {/* <div className="flex items-center justify-between ml-4">
                          {props.isLoadingInvoice ? (
                            <ButtonLoader />
                          ) : (
                            <button
                              className="px-10 py-3 btn-red text-xl border text-white w-[233px]"
                              onClick={(e) => {
                                //e.stopPropagation();
                                //setSendInvoice(true);
                                props.sendInvoiceEmailToCorporateMember();
                              }}
                            >
                              Send Invoice
                            </button>
                          )}
                        </div> */}
                    </div>
                  ) : null}

                  <div className="flex items-center justify-between">
                    {
                      (props.taskDetails.companyName === "" ||
                        props.taskDetails.companyUEN === "" ||
                        props.taskDetails.firstName === "" || props.taskDetails.lastName === "" ||
                        props.taskDetails.email === "") ?
                        <span className="text-[#313984] px-2">Please fill all mandatory fields</span>
                        :
                        null
                    }
                    {props.isLoadingPayment ? (
                      <div className="w-[233px] quatation-send-btn">
                        <ButtonLoader />
                      </div>
                    ) : (<>
                      {props.tenureArr && props.tenureArr.length > 0 ?
                        <button
                          disabled={(props.taskDetails.companyName === "" ||
                            props.taskDetails.companyUEN === "" ||
                            props.taskDetails.firstName === "" || props.taskDetails.lastName === "" ||
                            props.taskDetails.email === "") ? true : false || props.disablePaymentButton}
                          className={
                            props.disablePaymentButton
                              ? `col-end-9 col-span-2 text-xl font-bold text-white bg-[#d4d4d8] text-red w-[250px] p-3`
                              : `col-start-1 col-end-3 text-xl font-bold text-[#313984] btn-red-border w-[250px] text-red p-3 ${(props.taskDetails.companyName === "" ||
                                props.taskDetails.companyUEN === "" ||
                                props.taskDetails.firstName === "" || props.taskDetails.lastName === "" ||
                                props.taskDetails.email === "") ? "cursor-not-allowed" : null}`
                          }
                          onClick={() => {
                            props.addEditMembershipTaskAndSendPaymentLink();
                          }}
                        >
                          Send Payment Link
                        </button> :
                        <button className="col-start-1 col-end-3 text-xl font-bold text-[#313984] btn-red-border w-[250px] text-red p-3 cursor-not-allowed opacity-50">
                          Send Payment Link
                        </button>}
                    </>
                    )}
                  </div>

                  {/* <button
                    disabled={props.disablePaymentButton}
                    className="col-end-9 col-span-2 text-xl font-bold text-white bg-[#d4d4d8] text-red w-[250px] p-3"
                    // disabled
                    // onClick={() => props.addEditMembershipTask()}
                    onClick={(e) => {
                      // props.addEditMembershipTask();
                      e.stopPropagation();
                      setPaymentLink(true);
                      props.addEditMembershipTaskAndSendPaymentLink();
                    }}
                  >
                    Send Payment Link
                  </button> */}
                </div>
              </div>
            </div>
          </SectionWithBorder>

        </div>

        {/*Footer Button End*/}
        <div className="flex items-center justify-between pb-6">
          <button
            className="text-lg py-2 font-bold btn btn-gray w-auto text-white"
            onClick={() => props.closeModalOnBack()}
          >
            Back
          </button>
          {props.isEscalateLodaing ? (
            <div className="w-full flex justify-center quatation-send-btn pl-[40%] mb-4">
              <ButtonLoader />
            </div>
          ) : (
            <button
              className=" px-7 py-2 btn-sm btn btn-lighblue text-lg text-white font-bold"
              onClick={(e) => { props.sendEscalateMailToMember(); }}
            >
              Escalate this application
            </button>
          )
          }
        </div>
        {/*Footer Button End*/}

        {/* Modal footer Button*/}
        {/* <div className="flex items-center justify-between">
            <div className="w-full mb-7 flex justify-center">
              <button
                className=" px-7 mr-16 py-2 btn-sm btn-lighblue text-lg text-white font-bold"
                onClick={()=>props.closeModalOnBack()}
              >
                Back
              </button>
            </div>
            {props.isEscalateLodaing ? (
              <div className="w-full flex justify-center quatation-send-btn pl-[40%] mb-4">
                <ButtonLoader />
              </div>
            ) : (
              <div className="w-full mb-7 flex justify-center">
                <button
                  className=" px-7 mr-16 py-2 btn-sm btn-lighblue text-lg text-white font-bold"
                  onClick={(e) => {
                    props.sendEscalateMailToMember();
                  }}
                >
                  Escalate this application
                </button>
              </div>
            )}
          </div> */}
      </div>
    </ModalBasic>
  );
};
export default MembershipTaskListProcess;