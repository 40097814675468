import React from "react";
import ModalBasic from "../../component/ModalBasic";

const SendForApprove = (props) => {
    return (
        <ModalBasic
            id="send-for-approve-modal"
            modalOpen={props.setSendApproveModal}
            setModalOpen={props.setOpenModal}
            title=""
        >
            <div className="text-center pb-2">
                <h1 className="text-xl text-[#757575] font-normal leading-loose p-8 py-4">
               {`${props.companyName} got your request , We will share the update on ${props.email}.`}
                </h1>
                <button
                    className="bg-[#313984] text-base text-[#fff] font-bold px-12 py-2 my-4"
                    onClick={props.onClose}
                >
                    Ok
                </button>
            </div >
        </ModalBasic>
    );
}
export default SendForApprove;