import React, { useState } from 'react'
import Header from '../shared/header/Header'
import Sidebar from '../shared/sidebar/Sidebar';
import { Outlet } from 'react-router';
import Breadcome from './breadcomeTitle/Breadcome';
import Footer from './footer/Footer';
import { useLocation } from 'react-router-dom';
import { InactivityDetector } from '../utils/AuthenticationLibrary';

const Layout = (props) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation()
  //const newStr = location.pathname.replace('/', '');
  //const breadcrums = props.componentRoutes.filter(x => x.path === newStr);
  const tempURLMobile = location.pathname.includes("MobileBannerPreview")
  const tempURLBanner = location.pathname.includes("BannerPreview")

  const inactivityTimeout =  15 * 60 * 1000;
  InactivityDetector(inactivityTimeout)

  return (

    <div>
      {(tempURLMobile || tempURLBanner) ?
        <Outlet />
        :
        <>
          <div className="flex h-screen overflow-hidden">
            {/* Sidebar */}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            {/* Content area */}
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
              {/*  Site header */}
              <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} componentRoutes={props.componentRoutes} />
              <Breadcome componentRoutes={props.componentRoutes} />

              <Outlet />
            </div>
          </div>
          <Footer />
        </>
      }


    </div>
  )
}

export default Layout;
