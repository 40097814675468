import React, { useEffect, useState } from 'react'
import SectionWithBorder from '../membership/membershipTaskList/SectionWithBorder';
import ModalBasic from '../../pages/component/FormModal'
import DropdownSelect from '../dropdown/Dropdown';

const fieldTypeArray = [{id : 1, name : 'Text'}, {id : 2, name : 'Image'}]

export default function ObjectModal(props) {

    let [fieldArr, setFieldArr] = useState([{fieldName : '', fieldType : 'Text'}]);

    const addNewField = () => {
        const fieldObj = {fieldName : '', fieldType : 'Text'};
        let arr = [...fieldArr];
        arr.push(fieldObj);
        setFieldArr(arr);
    }

    const removeField = (index) => {
        // let arr = [...fieldArr];
        // arr.splice(index, 1);
        // setFieldArr(arr);
        props.removeFieldInObject(index);
    }

    const setDropdownOption = (optionId, drpIdentity, index) => {
        // let arr = [...fieldArr];
        // var identity = drpIdentity.split("_");
        // arr[index].fieldType = fieldTypeArray.find(x => x.id === optionId).name;
        props.handleChangeInObject('fieldType', optionId, index);
        // setFieldArr(arr);
    }

    const setFieldName = (e, index) => {
        // let arr = [...fieldArr];
        // var identity = drpIdentity.split("_");
        const value = e.target.value;
        // arr[index].fieldName = value;
        props.handleChangeInObject('fieldName', value, index);
    }

    useEffect(() => {
        // if(props) {
        //     setFieldArr([{fieldName : '', fieldType : 'Text'}]);
        // }
        setFieldArr([{fieldName : '', fieldType : 'Text'}]);
    }, []);

    return (
        <>
            <ModalBasic
                id="add-edit-modal"
                className="membership-add-edit-modal"
                modalOpen={props.setAddeditModalOpen}
                setModalOpen={props.setModalOpen}
                title="Object Component"
            >
                {/* Modal content */}
                <div className="2xl:px-14 lg:px-3 pt-4" key={`ObjectComponent` + props.keyId}>
                    <div className="">
                        <SectionWithBorder title="Add Fields">
                            {(props?.componentContent.map((value, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <div className="grid grid-cols-12 gap-6 w-full items-center pt-3 px-7 pb-7" >
                                            <div className="xl:col-span-6 md:col-span-6 col-span-12">
                                                <div className="grid grid-cols-12 gap-6">
                                                    <div className="xl:col-span-10 md:col-span-10 col-span-12">
                                                        {value.fieldType === "Text" ?
                                                            <label
                                                                className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                                htmlFor="default">
                                                                Field Name <span className="text-[#c00000]">*</span>
                                                            </label>
                                                            :
                                                            <label
                                                                className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                                htmlFor="default">
                                                                Image Name <span className="text-[#c00000]">*</span>
                                                            </label>
                                                        }
                                                        <input
                                                            id="default"
                                                            className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                                            type="text"
                                                            name="companyName"
                                                            value={value.fieldName}
                                                            onChange={(event) => setFieldName(event, index)}
                                                            // onBlur={() => props.validateField("companyName")}
                                                        />
                                                        <div>
                                                            {/* {" "}
                                                            <ValidationText
                                                                error={props.validState.error.companyName}
                                                            />{" "} */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="xl:col-span-3 md:col-span-3 col-span-12">
                                                <div className="grid grid-cols-12 gap-6">
                                                    <div className="xl:col-span-10 md:col-span-10 col-span-12">
                                                        <label
                                                            className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                            htmlFor="default"
                                                        >
                                                            Field Type <span className="text-[#c00000]">*</span>
                                                        </label>
                                                        <DropdownSelect
                                                            drpIdentity={`FieldType_${index}`}
                                                            optionArray={fieldTypeArray}
                                                            setFilterParameters={(id, drpIdentity) => setDropdownOption(fieldTypeArray.find(x => x.id === id).name, drpIdentity, index)}
                                                            value={fieldTypeArray.find(x => x.name === value.fieldType).id}
                                                            disabled={false}
                                                        />
                                                        <div>
                                                            {/* {" "}
                                                            <ValidationText
                                                                error={props.validState.error.companyUEN}
                                                            />{" "} */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {index === (props.componentContent.length - 1) ?
                                                <div className="xl:col-span-3 md:col-span-3 col-span-12">
                                                    <div className="grid grid-cols-12 gap-6">
                                                    <div className="col-span-12 pt-14">
                                                        <div className="flex items-center justify-end pb-6">
                                                            <button onClick={() => props.addNewFieldInObject()}
                                                                className=" px-7 py-2 btn-sm btn-red text-lg text-white font-bold">
                                                                Add Field
                                                            </button>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="xl:col-span-3 md:col-span-3 col-span-12">
                                                    <div className="grid grid-cols-12 gap-6">
                                                    <div className="col-span-12 pt-14">
                                                        <div className="flex items-center justify-end pb-6">
                                                            <button onClick={() => removeField(index)}
                                                                className=" px-7 py-2 btn-sm btn-red text-lg text-white font-bold">
                                                                remove Field
                                                            </button>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </React.Fragment>
                                )
                            }))}
                            <div className="flex items-center justify-between px-7 pb-6">
                                <button onClick={() => props.getObjectFields(fieldArr)}
                                    className=" px-7 py-2 btn-sm btn-red text-lg text-white font-bold">
                                    Save
                                </button>
                            </div>
                        </SectionWithBorder>
                    </div>
                </div>
            </ModalBasic>
        </>
    )
}
