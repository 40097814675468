import React from 'react'
import ModalBasic from '../../../pages/component/ModalBasic'
import ValidationText from '../../../utils/validation/ValidationText'
import DropdownSelect from '../../dropdown/Dropdown'
import TextInput from '../../UIComponent/TextInput'
import ButtonLoader from '../../common/buttonLoader/ButtonLoader'
import Selector from '../../dropdown/commonDropdown/Selector'

function AddEditNewProfile(props) {
  return (
    <ModalBasic
      id="show-individual-profile-modal"
      modalOpen={props.addEditNewProfile}
      setModalOpen={props.setOpenModal}
    >
      <div className="bg-white mb-10">
        <div className="">
          <div className="grid grid-cols-12 gap-6 w-full 2xl:px-10 lg:px-8 pr-0">
            <div className="xl:col-span-12 lg:col-span-12 col-span-12">
              <h1 className="text-4xl text-[#313984] font-bold leading-loose py-4">
              Organisation Role
              </h1>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-6 w-full 2xl:px-10 lg:px-8 pr-0 mb-8">
            {
              props.isCreateNewOrgRole === true ?
                <div className="col-span-12">
                  <div className='grid grid-cols-12 gap-6'>
                    <div className='xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts'>
                      <h2 className="text-xl mb-1">Email Address<span className='text-[#313984]'>*</span></h2>
                      <TextInput
                        placeholder=""
                        type="text"
                        name="searchByEmail"
                        value={props.organisationDetails.searchByEmail}
                        handleChange={props.handleChange}
                        onBlur={(e) => props.validate("searchByEmail", props.organisationDetails)}
                        identity="Organisation"
                      />
                      <ValidationText error={props.validState.error.searchByEmail} />
                    </div>
                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                      <div className='flex justify-end'>
                        <div>
                          <h2 className="text-xl mb-1">&nbsp;</h2>
                          <button className="w-32 px-7 py-2 btn-sm btn-red text-lg text-white font-bold"
                            onClick={() => props.searchIndividualMemberByEmailForOrgRole("Organisarion Key Roles")}
                          >Search</button>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
                :
                null
            }
          </div>
          <div className="grid grid-cols-12 gap-6 w-full 2xl:px-10 lg:px-8 pr-0">
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <h2 className="2xl:text-xl lg:text-md text-[#757575] font-bold 2xl:mb-1">
                First Name <span className="text-[#c00000]">*</span>
              </h2>
              <div className="flex items-center">
                <div className="w-[130px] individual-f-name">
                  <DropdownSelect
                    drpIdentity={"Org_MrMrs"}
                    optionArray={props.mrMrs}
                    setFilterParameters={props.setFilterParameters}
                    value={props.organisationDetails.mrMrs}
                    disabled={props.isOrganisationModalEditable !== true}
                  />
                </div>
                <div className="w-full">
                  <TextInput
                    placeholder=""
                    type="text"
                    name="firstName"
                    value={props.organisationDetails.firstName}
                    identity="Organisation"
                    handleChange={props.handleChange}
                    onBlur={(e) => props.validate("firstName", props.organisationDetails)}
                    disabled={props.isOrganisationModalEditable !== true}
                    isCurserAllowed={props.isOrganisationModalEditable !== true}
                  />
                </div>
              </div>
              <ValidationText error={props.validState.error.mrMrs} />
              <ValidationText error={props.validState.error.firstName} />
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <h2 className="2xl:text-xl lg:text-md text-[#757575] font-bold 2xl:mb-1">
                Last Name <span className="text-[#c00000]">*</span>
              </h2>
              <TextInput
                placeholder=""
                type="text"
                name="lastName"
                value={props.organisationDetails.lastName}
                identity="Organisation"
                handleChange={props.handleChange}
                onBlur={(e) => props.validate("lastName", props.organisationDetails)}
                disabled={props.isOrganisationModalEditable !== true}
                isCurserAllowed={props.isOrganisationModalEditable !== true}
              />
              <ValidationText error={props.validState.error.lastName} />
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <h2 className="2xl:text-xl lg:text-md text-[#757575] font-bold 2xl:mb-1">
                Business Email{" "}
                <span className="text-[#c00000]">*</span>
              </h2>
              <div className="flex items-center">
                <div className="w-full right-border-none">
                  <TextInput
                    placeholder=""
                    type="text"
                    name="emailText"
                    value={props.organisationDetails.emailText}
                    identity="Organisation"
                    handleChange={props.handleChange}
                    onBlur={(e) => props.validate("emailText", props.organisationDetails)}
                    autoComplete={"off"}
                    disabled={props.isOrganisationModalEditable !== true}
                    isCurserAllowed={props.isOrganisationModalEditable !== true}
                  />
                </div>
                {/* <div className="w-[55%] individual-email">
                      <DropdownSelect
                        // disabled={true}
                        drpIdentity={"Org_EmailDomain"}
                        optionArray={props.domainArr}
                        setFilterParameters={props.setFilterParameters}
                        value={props.organisationDetails.emailDomainId}
                      />
                    </div> */}
              </div>
              <ValidationText error={props.validState.error.emailText} />
              <ValidationText error={props.validState.error.emailString} />
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <h2 className="2xl:text-xl lg:text-md text-[#757575] font-bold 2xl:mb-1">
                Contact Number
              </h2>
              <TextInput
                placeholder=""
                type="text"
                name="contactNo"
                value={props.organisationDetails.contactNo}
                identity="Organisation"
                handleChange={props.handleChange}
                // onBlur={(e) => props.validate("contactNo", props.organisationDetails)}
                disabled={props.isOrganisationModalEditable !== true}
                isCurserAllowed={props.isOrganisationModalEditable !== true}
              />
              <ValidationText error={props.validState.error.contactNo} />
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <h2 className="2xl:text-xl lg:text-md text-[#757575] font-bold 2xl:mb-1">
                {/* Designation */}
                Job Function
                <span className="text-[#c00000]">*</span>
              </h2>
              
              <div className="dropdwn-input-email flex items-center">
                {/* <TextInput
                      placeholder=""
                      type="text"
                      name="designation"
                      value={props.organisationDetails.designation}
                      identity="Organisation"
                      handleChange={props.handleChange}
                      onBlur={(e) => props.validate("designation", props.organisationDetails)}
                    /> */}
              </div>
              <Selector
                drpIdentity="Designation_KeyRoles"
                options={props.designationList}
                isMulti
                //   options={props.countriesWithPresence}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                // components={{ Option }}
                onChange={props.handleChangeMultiDropdown}
                allowSelectAll={true}
                value={
                  props.organisationDetails.selectedDesignation
                }
                disabled={props.isOrganisationModalEditable !== true}
              />
              <ValidationText error={props.validState.error.selectedDesignation} />
            </div>
            {/* <div className="input-sec-texts 2xl:col-span-6 lg:col-span-6 col-span-12">
              <div className="flield-sec">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Job Level <span className="text-[#c00000]">*</span>
                </h2>
                <DropdownSelect
                  drpIdentity={"JobLevel_org"}
                  optionArray={props.jobLevel}
                  setFilterParameters={props.setFilterParameters}
                  value={props.organisationDetails.jobLevelId}
                  // disabled={props.isOrganisationModalEditable !== true}
                />
              </div>
              <ValidationText error={props.validState.error.jobLevelId} />
            </div>
            {props.organisationDetails.jobLevelId === 11 ?
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <h2 className="2xl:text-xl lg:text-md text-[#757575] font-bold 2xl:mb-1">
              Others{" "}
              <span className="text-[#c00000]">*</span>
            </h2>
            <div className="flex items-center">
              <div className="w-full right-border-none">
             <TextInput
             placeholder=""
             type="text"
             name="otherJobLevel"
             value={props.organisationDetails.otherJobLevel}
             identity="Organisation"
             handleChange={props.handleChange}
            //  onBlur={(e) => props.validate("others", props.organisationDetails)}
            // validState={props.validState}
             autoComplete={"off"}
            //  disabled={true}
            //  isCurserAllowed={props.isOrganisationModalEditable !== true}
           />
           </div>
           </div>
           <ValidationText error={props.validState.error.otherJobLevel} />
           </div>
           :null }  */}
            <div className="input-sec-texts 2xl:col-span-6 lg:col-span-6 col-span-12">
              <div className="lg:flex sm:flex md:flex block items-end gap-3 w-full">
                <div className={`flield-sec ${props.organisationDetails.jobLevelId === 11 ? "2xl:w-[35%] w-[100%]" : "2xl:w-full w-full"} `}>
                  <label className="2xl:text-xl lg:text-md text-[#757575] font-bold 2xl:mb-1">
                    Job Level <span className="text-[#c00000]">*</span>
                  </label>
                  <DropdownSelect
                    drpIdentity={"JobLevel_org"}
                    optionArray={props.jobLevel}
                    setFilterParameters={props.setFilterParameters}
                    value={props.organisationDetails.jobLevelId}
                    disabled={props.isOrganisationModalEditable !== true}
                  />
                </div>
                {props.organisationDetails.jobLevelId === 11 ?
                  <div className="flield-sec 2xl:w-[65%] w-[100%]">
                    <TextInput
                      placeholder=""
                      type="text"
                      name="otherJobLevel"
                      value={props.organisationDetails.otherJobLevel}
                      identity="Organisation"
                      handleChange={props.handleChange}
                      //  onBlur={(e) => props.validate("others", props.organisationDetails)}
                      // validState={props.validState}
                      autoComplete={"off"}
                      disabled={props.isOrganisationModalEditable !== true}
                     isCurserAllowed={props.isOrganisationModalEditable !== true}
                    />
                  </div>
                  : null}
              </div>
              <ValidationText error={props.validState.error.jobLevelId} />
              <ValidationText error={props.validState.error.otherJobLevel} />
            </div>
            
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <h2 className="2xl:text-xl lg:text-md text-[#757575] font-bold 2xl:mb-1">
                Organisation Role
                <span className="text-[#c00000]">*</span>
              </h2>
              {/* <div className="dropdwn-input-email flex items-center"> */}
                {/* <TextInput
                      placeholder=""
                      type="text"
                      name="designation"
                      value={props.organisationDetails.designation}
                      identity="Organisation"
                      handleChange={props.handleChange}
                      onBlur={(e) => props.validate("designation", props.organisationDetails)}
                    /> */}
              {/* </div> */}
              <Selector
                drpIdentity="Org_OrganisationRoles"
                options={props.organizationRoleArray}
                isMulti
                //   options={props.countriesWithPresence}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                // components={{ Option }}
                onChange={props.handleChangeMultiDropdown}
                allowSelectAll={true}
                value={
                  props.organisationDetails.selectedOrganisationRole
                }
                disabled={props.isOrganisationModalEditable !== true}
              />
              <ValidationText error={props.validState.error.selectedOrganisationRole} />
            </div>

            {/* <div className="xl:col-span-6 lg:col-span-6 col-span-12 ">
              <div className="grid grid-cols-12 gap-6">
                <div className="2xl:col-span-11 lg:col-span-11 col-span-12">
                  <h2 className="2xl:text-xl lg:text-md text-[#757575] font-bold 2xl:mb-1">
                    Organisation Role
                    <span className="text-[#c00000]">*</span>
                  </h2>                  
                  <DropdownSelect
                    disabled={false}
                    drpIdentity={"Org_OrganisationRoles"}
                    optionArray={props.organizationRoleArray}
                    setFilterParameters={props.setFilterParameters}
                    value={props.organisationDetails.organisationRole}
                  />
                  <ValidationText error={props.validState.error.organisationRole} />
                </div>
              </div>
            </div> */}

          </div>
        </div>
        {/* Delete and Save Button */}
        <div className="grid grid-cols-12 gap-6 w-full pt-16 px-10">
          <div className="xl:col-span-12 lg:col-span-12 col-span-12">
            <div className="flex items-center justify-between">
              <div className="">
                {
                  props.organisationDetails && props.organisationDetails.individualMemberId > 0 ?
                    <button
                      className="btn btn-gray text-lg text-white font-bold"
                      onClick={() => { props.deleteOrganisationRole(props.organisationDetails.individualMemberId) }}
                    >
                      Delete Profile
                    </button>
                    :
                    null
                }
              </div>

              {
                props.isAddEditAOrganisation && props.isAddEditAOrganisation === true ?
                  (
                    <div className="savebtn">
                      <ButtonLoader />
                    </div>
                  )
                  :
                  (
                    <div className="savebtn">
                      <button
                        className="btn btn-red text-xl text-white font-semibold px-10"
                        onClick={() => props.addEditOraganisation("Save")}
                      >
                        Save
                      </button>
                    </div>
                  )

              }
              {/* <div className="savebtn">
                <button
                  className=" px-14 py-3 bg-[#313984] text-xl text-white font-semibold"
                  onClick={() => props.addEditOraganisation("Save")}
                >
                  Save
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </ModalBasic>
  )
}
export default AddEditNewProfile