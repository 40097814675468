import React from 'react'
import PageLoader from '../common/pageLoader/PageLoader';
import ActionDeleteButton from '../UIComponent/ActionTableComponent/ActionDeleteButton';

export default function ProjectTable(props) {
    return (
        <div className='mb-2 overflow-x-auto'>
            {props.isLoading ? <PageLoader /> :
                <table className="project-tbl w-full mx-auto my-5 fixed_header  ">

                    <thead >
                        <tr className="text-base font-bold flex">
                            <th className="px-2 first:pl-5 last:pr-5 tbl-th 2xl:whitespace-nowrap lg:whitespace-normal text-white w-[30%]">
                                <div className="font-bold tble-th-txt text-left">Project Name</div>
                            </th>
                            <th className="px-2 first:pl-5 last:pr-5 tbl-th 2xl:whitespace-nowrap lg:whitespace-normal text-white w-[20%]">
                                <div className="font-bold tble-th-txt text-center">SMF OIC</div>
                            </th>
                            <th className="px-2 first:pl-5 last:pr-5 tbl-th 2xl:whitespace-nowrap lg:whitespace-normal text-white w-[20%]">
                                <div className="font-bold tble-th-txt text-center">Start Date</div>
                            </th>
                            <th className="px-2 first:pl-5 last:pr-5 tbl-th 2xl:whitespace-nowrap lg:whitespace-normal text-white w-[20%]">
                                <div className="font-bold tble-th-txt text-center">End Date</div>
                            </th>
                            <th className="px-2 first:pl-5 last:pr-5 tbl-th 2xl:whitespace-nowrap lg:whitespace-normal text-white w-[10%]">
                                <div className="font-bold tble-th-txt text-center">Action</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="scrollit text-sm custom-tbody ">
                        {
                            props?.projectPartnerList && props.projectPartnerList.length > 0 ?
                                (
                                    props.projectPartnerList.map((data, key) => {
                                        return (
                                            <tr className='custom-tbl-td flex' key={key}>
                                                <td className=" px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[30%]">
                                                    {data.projectName}
                                                </td>
                                                <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[20%]">
                                                    <div className="dollar-sign-icon inline-block">
                                                        {data.sgTechSecretariat}
                                                    </div>
                                                </td>
                                                <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[20%]">
                                                    <div className="dollar-sign-icon inline-block">
                                                        {data.projectStartDate}
                                                    </div>
                                                </td>
                                                <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[20%]">
                                                    {data.projectEndDate}
                                                </td>
                                                <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[10%]">
                                                    <ActionDeleteButton
                                                        onActionClick={() => props.onDeleteProjectPartner(data.projectId)}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })
                                )
                                :
                                (
                                    <tr className='grid justify-items-center'>
                                        <td className="text-lg text-[#757575]	">
                                            No Records Found
                                        </td>
                                    </tr>
                                )
                        }
                    </tbody>
                </table>
            }
        </div>
    );
}