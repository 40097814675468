import { GenerateCreditNoteNetSuitReport, GenerateInvoiceNetSuitReport, GenerateNewCustomerNetSuitReport, GenerateReceiptNetSuitReport } from "../ApiEndPoints";
import { axiosPostFileAuthorizeblob } from "../AxiosRequests";


export default class NetsuiteServices {
    async generateNewCustomerNetSuitReport(request) {
        return axiosPostFileAuthorizeblob(GenerateNewCustomerNetSuitReport, request);
    }
    async generateInvoiceNetSuitReport(request) {
        return axiosPostFileAuthorizeblob(GenerateInvoiceNetSuitReport, request);
    }
    async generateReceiptNetSuitReport(request) {
        return axiosPostFileAuthorizeblob(GenerateReceiptNetSuitReport, request);
    }
    async generateCreditNoteNetSuitReport(request) {
        return axiosPostFileAuthorizeblob(GenerateCreditNoteNetSuitReport, request);
    }
}