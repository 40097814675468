import React, { Component } from "react";
import Table from "../../components/table/Table";
import ActionContainer from "../../components/UIComponent/ActionTableComponent/actionContainer";
import DropdownAction from "../../components/dropdown/DropdownAction";
import FinanceServices from "../../services/axiosServices/apiServices/FinanceServices";
import SwalServices from "../../services/swalServices/SwalServices";
import moment from "moment/moment";
import Checkbox from "../../components/UIComponent/Checkbox";
import { saveAs } from 'file-saver';
import EventRegistrationApprovalDetailsModel from "../../components/entrymanagement/EventRegistrationApprovalDetailsModel";

export default class EventRegistrationApproval extends Component {
  constructor(props) {
    super(props);
    this.financeService = new FinanceServices();
    this.swalServices = new SwalServices();
    this.state = {
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
        status: 0,
        eventName: "",
      },
      actionDropdownApproved: ["View Details"],
      pendingEventApprovalList: [],
      totalResults: 0,
      selectedIdList: [],
      checkedRow: false,
      checkedHeader: false,
      isRejected: true,
      isApprove: true,
      setRegistrationApprovalModel: false,
      singleEventApprovalDetails: {
        bookingId: 0,
        bookingStatus: "",
        bookingStatusId: 0,
        companyName: "",
        contactPerson: "",
        email: "",
        eventName: "",
        isSelected: false,
        registrationDate: "",
        timeSlotDate: "",
        title: "",
      },
      isLoading: false
    };
  }


  financeGetAllPendingEventApprovalList = () => {
    this.setState({ isLoading: true });
    this.financeService.financeGetAllPendingEventApprovalList(this.state.pagination).then((response) => {
      if (response.statusCode === 200 && response.responseItem.responseContent != null) {
        let data = response.responseItem.responseContent.itemList;
        data.map((x) => {
          x.registrationDate = moment(x.registrationDate).format("DD MMM YYYY");
          x.timeSlotDate = moment(x.timeSlotDate).format("DD MMM YYYY");
          x.isSelected = false;
        });
        this.setState({ pendingEventApprovalList: data, totalResults: response.responseItem.responseContent.totalCount, isApprove: true, isRejected: true })
      } else {
        this.swalServices.Error("Something went wrong.");
      }
      this.setState({ isLoading: false });
    })
  }

  financeApproveRejectEventApproval = (identity) => {
    let request = {
      updatedBy: 1,
      updatedAt: moment().format("yyyy-MM-DD"),
      updatedAppId: 114,
      bookingId: []
    };
    if (identity === 'Reject') {
      request.bookingStatusId = 9;

    } else if (identity === 'Approve') {
      request.bookingStatusId = 7;
    }
    let selectedIdList = this.state.pendingEventApprovalList.filter(
      (get) => get.isSelected === true
    );
    selectedIdList = selectedIdList.map((p) => (p.bookingId));
    request.bookingId = [...selectedIdList];
    this.financeService.financeApproveRejectEventApproval(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.financeGetAllPendingEventApprovalList();
      } else {
        this.swalServices.Error("Something went wrong.");
      }
    })
  }


  componentDidMount() {
    this.financeGetAllPendingEventApprovalList();
  }

  handleChange = (event) => {
    let { name, value } = event.target;
    let detail = { ...this.state.pagination };
    detail[name] = value;
    detail.pageNo = 1;
    this.setState({ pagination: detail });
  }

  setPagination = (newPagination) => {
    this.setState({ pagination: newPagination }, () => { this.financeGetAllPendingEventApprovalList() });
  };

  actionClick = (index, value, option, event) => {
    if (option === "View Details") {
      let found = this.state.pendingEventApprovalList.find(x => x.bookingId === value);
      if (found) {
        this.setState({ singleEventApprovalDetails: found }, () => {
          event.stopPropagation();
          this.setState({ setRegistrationApprovalModel: true });
        });
      }
    }

  };

  actions = (element, index, value) => {
    return element !== "bookingId" ? null : (
      <td>
      <div className="relative w-full credit-note-action-dropdown">
        <ActionContainer>
          <div className="mr-8">
            <DropdownAction
              key={index}
              value={value}
              id={index}
              index={index}
              options={this.state.actionDropdownApproved}
              align="right"
              onOptionClick={this.actionClick.bind(this)}
            />
          </div>
        </ActionContainer>
      </div>
      </td>
    );
  };

  select = (element, index, value) => {
    return element !== "selectRow" ? null : (
      <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575]">
        <input
          name="isRequireQuotation"
          type="checkbox"
          className="cursor-pointer h-6 w-6 rounded check-box-custom"
          onChange={() => this.selectSingleRows(value)}
          checked={this.state.pendingEventApprovalList[index].isSelected === true}
        />
      </td>
    );
  };

  selectSingleRows = (value) => {
    let getAllRows = [...this.state.pendingEventApprovalList];
    let check = getAllRows.find(x => x.bookingId === value)
    let checkedHeader = true;
    if (check.isSelected) {
      getAllRows.map((x) => {
        if (x.bookingId === value) {
          x.isSelected = false;
        }
      })
      this.setState({ isRejected: true, isApprove: true });
    } else {
      getAllRows.map((x) => {
        if (x.bookingId === value) {
          x.isSelected = true;
        }
      })
    }
    let isCheck = false;
    getAllRows.map((x) => {
      if (x.isSelected === true) {
        if (x.bookingStatus !== 'Pending Approval') {
          isCheck = true;
        } else {
          this.setState({ isRejected: false, isApprove: false });
        }
      } else {
        checkedHeader = false;
      }
    })

    if (isCheck) {
      this.setState({ isRejected: isCheck, isApprove: isCheck });
    }
    this.setState({ pendingEventApprovalList: getAllRows, checkedHeader: checkedHeader });
  }

  selectAllRows = () => {
    let getAllRows = [...this.state.pendingEventApprovalList];
    let isAnyApproveReject = 0;
    if (this.state.checkedHeader) {
      getAllRows.map((item) => {
        item.isSelected = false;
      });
      this.setState({ pendingEventApprovalList: getAllRows, checkedHeader: false, isRejected: true, isApprove: true });
    } else {
      getAllRows.map((item) => {
        item.isSelected = true;
        if (item.bookingStatus !== 'Pending Approval') {
          isAnyApproveReject += 1;
        }
      });

      getAllRows.map((item) => {
        item.isSelected = true;

      });
      if (isAnyApproveReject > 0) {
        this.setState({ isRejected: true, isApprove: true });
      } else {
        this.setState({ isRejected: false, isApprove: false });
      }
      this.setState({ pendingEventApprovalList: getAllRows, checkedHeader: true });
    }
  }

  render() {
    return (
      <div className="main-body-section finance-body-sec">
        <div className="bg-white pb-2 mt-10">
          <div className="grid grid-cols-12 gap-6 w-full items-center px-8 pt-10 pb-12">
            <div className=" 2xl:col-span-4 lg:col-span-4 col-span-12 w-full">
              <h2 className="text-xl theme-color mb-1">Event Name / Contact Person</h2>
              <input
                id="default"
                name="eventName"
                className="form-input rounded-none w-full shadow-red py-3 text-lg"
                type="text"
                value={this.state.pagination.eventName}
                onChange={(e) => this.handleChange(e)}
              />
            </div>
            <div className="2xl:col-span-2 lg:col-span-2 col-span-12 w-full">
              <div className="mt-8 flex items-center justify-end w-full">
                <button className="btn btn-red text-xl border text-white "
                  onClick={() => { this.financeGetAllPendingEventApprovalList() }}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Table Section Start */}
        <div className="mt-10 event-registration-approval">
          <Table
            columns={[
              { name: "selectRow", title: <Checkbox handleCheckbox={() => this.selectAllRows()} checkedHeader={this.state.checkedHeader} /> },
              { name: "registrationDate", title: "Registration Date" },
              { name: "eventName", title: "Event Name" },
              { name: "timeSlotDate", title: "Event Date" },
              { name: "contactPerson", title: "Contact Person" },
              // { name: "email", title: "Email" },
              // { name: "companyName", title: "Company Name" },
              // { name: "title", title: "Job Title" },
              { name: "bookingStatus", title: "Status" },
              { name: "bookingId", title: "Actions" },
            ]}
            rows={this.state.pendingEventApprovalList}
            isLoading={this.state.isLoading}
            sortingColumns={["registrationDate", "timeSlotDate", "eventName"]}
            pagination={this.state.pagination}
            totalResultes={this.state.totalResults}
            totalCount={this.state.totalResults}
            setPagination={this.setPagination.bind(this)}
            customScope={[
              {
                column: "bookingId",
                renderTableData: this.actions.bind(this),
              },
              {
                column: "selectRow",
                renderTableData: this.select.bind(this),
                valueColumnName: "bookingId",
              },
            ]}
            isRequiredButton1={true}
            buttonText1="Reject"
            button1Click={() => { this.financeApproveRejectEventApproval("Reject") }}
            button1Disable={this.state.isRejected}
            isRequiredButton2={true}
            buttonText2="Approve"
            button2Click={() => { this.financeApproveRejectEventApproval("Approve") }}
            button2Disable={this.state.isApprove}
          />
        </div>
        {/*Table Section End */}


        <EventRegistrationApprovalDetailsModel

          setRegistrationApprovalModel={
            this.state.setRegistrationApprovalModel
          }
          setOpenModal={() =>
            this.setState({ setRegistrationApprovalModel: false })
          }
          onClose={(e) => {
            e.stopPropagation();
            this.setState({ setRegistrationApprovalModel: false });
          }}
          singleEventApprovalDetails={this.state.singleEventApprovalDetails}

        />

      </div>
    );
  }
}  